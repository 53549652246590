import React, { useState, useEffect, Fragment } from "react";
import config from "devextreme/core/config";
import DataGrid, { Column, Editing, Lookup } from "devextreme-react/data-grid";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import Axios from "axios";

config({
  floatingActionButtonConfig: {
    icon: "rowfield",
    position: {
      of: "#grid",
      my: "right bottom",
      at: "right bottom",
      offset: "-16 -16"
    }
  }
});

const MasrafMerkeziBaslik = props => {
  const { url } = useApiURL();
  const [masrafMerkeziBaslikListe] = useState(props.masrafMerkeziBaslikListe);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  var grid = null;

  function selectionChanged(e) {
    setSelectedRowIndex(e.component.getRowIndexByKey(e.selectedRowKeys[0]));
  }
  function editRow() {
    grid.instance.editRow(selectedRowIndex);
    grid.instance.deselectAll();
  }
  function deleteRow() {
    grid.instance.deleteRow(selectedRowIndex);
    grid.instance.deselectAll();
  }

  function addRow() {
    grid.instance.addRow();
  }
  return (
    <Fragment>
      <div>
        <button onClick={addRow}> &#x271C;</button>

        {selectedRowIndex !== undefined && selectedRowIndex !== -1 && (
          <Fragment>
            <button onClick={editRow}> &#x2710;</button>
            <button onClick={deleteRow}> &#x2716;</button>
          </Fragment>
        )}
      </div>

      <div id="divMasrafMerkeziBaslikListe" className={"form"}>
        <DataGrid
          id={"grid"}
          dataSource={masrafMerkeziBaslikListe}
          keyExpr={"masrafMerkeziBaslikId_Guid"}
          ref={ref => (grid = ref)}
          selection={{ mode: "single" }}
          showBorders={true}
          onSelectionChanged={selectionChanged}
        >
          <Column dataField={"sira"} dataType={"number"} />
          <Column dataField={"sabit"} dataType={"boolean"} />
          <Column dataField={"baslik"} />
          <Column dataField={"sutunAdet"} dataType={"number"} />
          <Column dataField={"tabloAd"} />
          <Column dataField={"alan"} />
          <Column dataField={"formul"} />
          <Editing mode={"popup"}></Editing>
        </DataGrid>
      </div>
    </Fragment>
  );
};

export default MasrafMerkeziBaslik;
