import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { AuthProvider } from "./Custom-Hooks/AuthContext";

import App from "./App";
ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,

  document.getElementById("root")
);

//import AppDenemeFocus from "./Denemeler/AppDenemeFocus";
//ReactDOM.render(<AppDenemeFocus />, document.getElementById("root"));
