import Baslik from "../img/Ayarlar/Baslik.png";
import HesapPlan from "../img/Ayarlar/HesapPlan.png";
import HesapPlan_Yesil from "../img/Ayarlar/HesapPlan_Yesil.png";
import Kullanici from "../img/Ayarlar/Kullanici.png";
import Kullanici_Yesil from "../img/Ayarlar/Kullanici_Yesil.png";
import MasrafKalemPng from "../img/Ayarlar/MasrafKalem.png";
import MasrafKalem_Yesil from "../img/Ayarlar/MasrafKalem_Yesil.png";
import Sozlesme from "../img/Ayarlar/Sozlesme.png";
import Sozlesme_Yesil from "../img/Ayarlar/Sozlesmeler_Yesil.png";
import Tedarikci from "../img/Ayarlar/Tedarikci.png";
import Tedarikci_Yesil from "../img/Ayarlar/Tedarikciler_Yesil.png";

import AyarlarImg from "../img/Ayarlar/Ayarlar.png";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import React, { useState, useEffect, Fragment } from "react";
import "../App.css";
import axios from "axios";
import HesapPlani from "./HesapPlani.js";
import GenelAyarlar from "./GenelAyarlar.js";
import TedarikciListe from "./TedarikciListe";
import KullaniciAyarlari from "./KullaniciAyarlari.js";
import MasrafKalemListe from "./MasrafKalemListe";
import MasrafKalem from "./MasrafKalem";
import MenuWrapper from "../CircleMenu/FullScreenMenu";

const Ayarlar = () => {
  const { url } = useApiURL();
  const [ayarAlanId, setAyarAlanId] = useState(3);
  /***************METHODS***************/
  const handleClick = alanId => {
    setAyarAlanId(alanId);
  };
  /***************METHODS***************/

  /**************************************/
  const [screenwidth, setWidth] = useState(window.innerWidth);
  const [screenheight, setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1704.0;
    var oh = 959.0;
    var oo = ow / oh;
    console.log(o);
    console.log(oo);
    if (oo > o) {
      console.log(h);
      console.log(o / oo);
      h = h * (o / oo);
      o = w / h;
      console.log("oo < o");
      console.log(o);
    } else if (oo < o) {
    }
    setWidth(w);
    setHeight(h);
    setBoyutOran(h / oh);
    setSolBosluk(10);
  };

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  /**************************************/

  return (
    <Fragment>
      <div style={{ borderBottomStyle: "solid", height: 120 * boyutOran }}>
        <div
          className="boxLeft"
          style={{
            width: 500 * boyutOran,
            height: 120 * boyutOran
          }}
        >
          <img style={{ width: 400 * boyutOran }} src={Baslik} />
        </div>
      </div>
      <div
        id="divMenuler"
        className="boxLeft"
        style={{
          width: 300 * boyutOran,
          height: 800 * boyutOran,
          borderRightStyle: "solid"
        }}
      >
        <img
          src={ayarAlanId === 1 ? Kullanici_Yesil : Kullanici}
          style={{ width: 300 * boyutOran }}
          onClick={() => handleClick(1)}
        />
        <img
          style={{ width: 300 * boyutOran }}
          src={ayarAlanId === 2 ? HesapPlan_Yesil : HesapPlan}
          onClick={() => handleClick(2)}
        />
        <img
          style={{ width: 300 * boyutOran }}
          src={ayarAlanId === 3 ? MasrafKalem_Yesil : MasrafKalemPng}
          onClick={() => handleClick(3)}
        />
        <img
          style={{ width: 300 * boyutOran }}
          src={ayarAlanId === 4 ? Tedarikci_Yesil : Tedarikci}
          onClick={() => handleClick(4)}
        />
        <img
          style={{ width: 300 * boyutOran }}
          src={ayarAlanId === 5 ? Sozlesme_Yesil : Sozlesme}
          onClick={() => handleClick(5)}
        />
        <img
          style={{
            width: 40 * boyutOran,
            backgroundColor: ayarAlanId === 6 ? "#AAF5B3" : "white",
            float: "right"
          }}
          src={AyarlarImg}
          onClick={() => handleClick(6)}
        />
        <div
          style={{
            position: "absolute",
            left: -33,
            top: 50
          }}
        >
          <MenuWrapper></MenuWrapper>
        </div>
      </div>
      <div
        id="divMenuIcerik"
        className="boxLeft"
        style={{
          width: 1600 * boyutOran,
          height: 800 * boyutOran,
          minHeight: 1200 * boyutOran,
          position: "absolute",
          top: 134 * boyutOran,
          left: 320 * boyutOran
        }}
      >
        {ayarAlanId === 1 && (
          <div>
            <KullaniciAyarlari></KullaniciAyarlari>
          </div>
        )}
        {ayarAlanId === 2 && <HesapPlani></HesapPlani>}
        {ayarAlanId === 3 && (
          <div>
            <MasrafKalemListe></MasrafKalemListe>
          </div>
        )}
        {ayarAlanId === 4 && (
          <div>
            <TedarikciListe></TedarikciListe>
          </div>
        )}
        {ayarAlanId === 5 && <div>Sözleşme Tanımları Henüz Yapılmadı</div>}
        {ayarAlanId === 6 && (
          <div>
            <MasrafKalem></MasrafKalem>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Ayarlar;
