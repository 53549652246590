import React, { Fragment, memo } from "react";
import clsx from "clsx";

const SeasonList = memo(({ sezonData, selectedSezonId, onSezonClick }) => {
  return (
    <Fragment>
      {sezonData &&
        sezonData.map(sezon => {
          const sezonClassNames = clsx(
            "boxLeft",
            "boxCircle",
            "boxCircle100",
            {
              boxCircleLime: sezon.sezonId === selectedSezonId
            },
            {
              boxCircleSilver: !(sezon.sezonId === selectedSezonId)
            }
          );
          return (
            <div
              className={sezonClassNames}
              key={sezon.sezonId}
              style={{ margin: 10 }}
              onClick={() =>
                onSezonClick(sezon.sezonId, sezon.sezonKod, sezon.sezonAd)
              }
            >
              <div className="boxCircleInner50">
                {sezon.sezonKod}
                <br />
                {sezon.sezonAd}
              </div>
            </div>
          );
        })}
    </Fragment>
  );
});

export default SeasonList;
