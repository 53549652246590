import React, { useState, useEffect, Fragment } from "react";
import "../App.css";
import axios from "axios";
import SenaryoBG from "../img/SenaryoBG.jpg";
import SenaryoBGPng from "../img/SenaryoBG.png";
import Baslik from "../img/Senaryo/Baslik.png";
import Ekle from "../img/Senaryo/Ekle.png";
import EskiSenaryo from "../img/Senaryo/EskiSenaryo.png";
import GuncelSenaryo from "../img/Senaryo/GuncelSenaryo.png";
import GuncelSenaryoIndir from "../img/Senaryo/GuncelSenaryoIndir.png";
import GuncelSenaryoNotlar from "../img/Senaryo/GuncelSenaryoNotlar.png";
import Liste from "../img/Senaryo/Liste.png";
import MenuAc from "../img/Senaryo/MenuAc.png";
import RevizyonSayi from "../img/Senaryo/RevizyonSayi.png";
import RevizyonSayisi from "../img/Senaryo/RevizyonSayisi.png";
import Sil from "../img/Senaryo/Sil.png";
import Yenile from "../img/Senaryo/Yenile.png";
import Popover from "@material-ui/core/Popover";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import SimpleReactFileUpload from "./SimpleReactFileUpload";
import MenuWrapper from "../CircleMenu/FullScreenMenu";
import { Button } from "devextreme-react/button";

const Senaryo = () => {
  const { url } = useApiURL();
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [sezonKod] = useState(localStorage.getItem("SezonKod"));
  const [bolumId] = useState(localStorage.getItem("BolumId"));
  const [senaryoListe, setSenaryoListe] = useState([{}]);
  const [bolumKod] = useState(localStorage.getItem("BolumKod"));
  const [selectedDate, setSelectedDate] = React.useState(new Date(Date.now()));
  /**********Popover*************/
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  function handleEkleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleEkleClose(event) {
    window.location.reload();
  }
  function handleYenile(event) {
    window.location.reload();
  }
  /**********Popover*************/

  useEffect(() => {
    loadSenaryoListe(bolumId, token);
  }, [bolumId, token]);

  function logOut() {
    setToken("");
    localStorage.removeItem("BolumId");
    localStorage.removeItem("MenuId");
    localStorage.removeItem("Token");
    window.location.reload();
  }

  const uploadSenaryo = () => {
    var formData = new FormData();
    var imagefile = document.querySelector("#file");
    var fileUploadDescription = document.querySelector(
      "#fileUploadDescription"
    );
    formData.append("image", imagefile.files[0]);
    formData.append("fileUploadDescription", fileUploadDescription.value);
    formData.append("token", token);
    formData.append("bolumId", bolumId);

    axios
      .post(url + "/api/SahneListe/FileUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);
              window.location.reload();
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  const loadSenaryoListe = (bolumId, token) => {
    var requestModel = {
      bolumId: bolumId,
      Token: token
    };
    axios
      .post(url + "/api/SahneListe/SenaryoListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);
              setSenaryoListe(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  /**************************************/
  const [screenwidth, setWidth] = useState(window.innerWidth);
  const [screenheight, setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1704.0;
    var oh = 959.0;
    var oo = ow / oh;
    console.log(o);
    console.log(oo);
    if (oo > o) {
      console.log(h);
      console.log(o / oo);
      h = h * (o / oo);
      o = w / h;
      console.log("oo < o");
      console.log(o);
    } else if (oo < o) {
    }
    setWidth(w);
    setHeight(h);
    setBoyutOran(h / oh);
    //setSolBosluk((screenwidth - 1700 * (h / oh)) / 2);
    setSolBosluk(0);
  };

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  /**************************************/

  return (
    <div
      id="divEkran"
      style={{
        height: window.innerHeight - 2,
        width: window.innerWidth,
        overflow: "none",
        backgroundColor: "whitesmoke"
      }}
    >
      <img
        src={Baslik}
        alt="Baslik"
        style={{
          position: "absolute",
          left: solBosluk + 10 * boyutOran,
          top: 10 * boyutOran,
          height: 160 * boyutOran
        }}
      ></img>
      <img
        src={Ekle}
        alt="Ekle"
        style={{
          position: "absolute",
          left: solBosluk + 870 * boyutOran,
          top: 10 * boyutOran,
          height: 110 * boyutOran
        }}
        onClick={handleEkleClick}
      ></img>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleEkleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div style={{ height: 150 }}>
          <table>
            <tr>
              <td>Açıklama</td>
              <td>
                <textarea
                  type="input"
                  id="fileUploadDescription"
                  name="fileUploadDescription"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Dosya</td>
              <td>
                <input type="file" id="file" name="file"></input>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <Button id="btnUpload" icon="Save" onClick={uploadSenaryo}>
                  {" "}
                  Kaydet{" "}
                </Button>
              </td>
            </tr>
          </table>
        </div>
      </Popover>
      <img
        src={Yenile}
        alt="Yenile"
        style={{
          position: "absolute",
          left: solBosluk + 1010 * boyutOran,
          top: 24 * boyutOran,
          height: 95 * boyutOran
        }}
        onClick={handleYenile}
      ></img>
      <img
        src={Sil}
        alt="Sil"
        style={{
          position: "absolute",
          left: solBosluk + 1130 * boyutOran,
          top: 24 * boyutOran,
          height: 95 * boyutOran
        }}
      ></img>

      {/* <img
        src={MenuAc}
        alt="MenuAc"
        style={{
          position: "absolute",
          left: solBosluk + 0 * boyutOran,
          top: 185 * boyutOran,
          height: 135 * boyutOran
        }}
      ></img> */}
      <div
        style={{
          position: "absolute",
          left: -5 * boyutOran,
          top: 80 * boyutOran
        }}
      >
        <MenuWrapper></MenuWrapper>
      </div>
      {senaryoListe[0] && (
        <Fragment>
          <img
            src={GuncelSenaryo}
            alt="GuncelSenaryo"
            style={{
              position: "absolute",
              left: solBosluk + 760 * boyutOran,
              top: 161 * boyutOran,
              height: 125 * boyutOran
            }}
          ></img>
          <a
            href={
              url +
              "/api/SahneListe/GetDocument?tokenId=" +
              token +
              "%7C" +
              senaryoListe[0].senaryoId
            }
          >
            <img
              src={GuncelSenaryoIndir}
              alt="GuncelSenaryoIndir"
              style={{
                position: "absolute",
                left: solBosluk + 1500 * boyutOran,
                top: 300 * boyutOran,
                height: 120 * boyutOran
              }}
            ></img>
          </a>
        </Fragment>
      )}
      <img
        src={Liste}
        alt="Liste"
        style={{
          position: "absolute",
          left: solBosluk + 200 * boyutOran,
          top: 250 * boyutOran,
          height: 650 * boyutOran
        }}
      ></img>
      <div
        id="divCizgi"
        style={{
          position: "absolute",
          left: solBosluk + 0 * boyutOran,
          top: 130 * boyutOran,
          backgroundColor: "#707070",
          width: 1700 * boyutOran,
          height: 5
        }}
      >
        &nbsp;
      </div>
      <div
        id="divTarihArkasi"
        style={{
          position: "absolute",
          left: solBosluk + 1375 * boyutOran,
          top: 25 * boyutOran,
          color: "white",
          backgroundColor: "black",
          fontSize: 70 * boyutOran,
          width: 320 * boyutOran
        }}
      >
        &nbsp;
      </div>

      <img
        id="imgToplamRevizyon"
        src={RevizyonSayi}
        style={{
          position: "absolute",
          left: solBosluk + 145 * boyutOran,
          top: 835 * boyutOran,
          color: "white",
          fontSize: 80 * boyutOran,
          width: 90 * boyutOran,
          textAlign: "center"
        }}
      />
      <img
        id="imgToplamRevizyon"
        src={RevizyonSayisi}
        style={{
          position: "absolute",
          left: solBosluk + 40 * boyutOran,
          top: 750 * boyutOran,

          fontSize: 80 * boyutOran,
          width: 300 * boyutOran,
          textAlign: "center"
        }}
      ></img>
      <div
        id="divToplamRevizyon"
        style={{
          position: "absolute",
          left: solBosluk + 40 * boyutOran,
          top: 760 * boyutOran,
          color: "#707070",
          fontSize: 40 * boyutOran,
          width: 300 * boyutOran,
          textAlign: "center"
        }}
      >
        Revizyon Sayısı
      </div>
      <div
        id="divToplamRevizyon"
        style={{
          position: "absolute",
          left: solBosluk + 140 * boyutOran,
          top: 830 * boyutOran,
          color: "white",
          fontSize: 80 * boyutOran,
          width: 90 * boyutOran,
          textAlign: "center"
        }}
      >
        {senaryoListe.length}
      </div>
      {senaryoListe[0] && (
        <Fragment>
          <div
            style={{
              position: "absolute",
              left: solBosluk + 890 * boyutOran,
              top: 200 * boyutOran,
              color: "white",
              fontSize: 40 * boyutOran
            }}
          >
            {senaryoListe[0].sezonKod}.Sezon>>>{senaryoListe[0].bolumKod}.Bölüm
          </div>
          <div
            style={{
              position: "absolute",
              left: solBosluk + 1380 * boyutOran,
              top: 25 * boyutOran,
              color: "white",

              fontSize: 70 * boyutOran
            }}
          >
            {senaryoListe[0].GunNo}
          </div>
          <div
            style={{
              position: "absolute",
              left: solBosluk + 1450 * boyutOran,
              top: 28 * boyutOran,
              color: "white",
              fontSize: 40 * boyutOran,
              width: 200 * boyutOran,
              textAlign: "center"
            }}
          >
            {senaryoListe[0].Gun}
          </div>
          <div
            style={{
              position: "absolute",
              left: solBosluk + 1450 * boyutOran,
              top: 72 * boyutOran,
              color: "white",
              fontSize: 20 * boyutOran,
              width: 200 * boyutOran,
              textAlign: "center"
            }}
          >
            {senaryoListe[0].Ay} - {senaryoListe[0].Yil}
          </div>
          <div
            style={{
              position: "absolute",
              left: solBosluk + 850 * boyutOran,
              top: 320 * boyutOran,
              color: "#479736"
            }}
          >
            <span
              style={{
                color: "#479736",
                fontSize: 50 * boyutOran,
                fontWeight: "bold"
              }}
            >
              {senaryoListe[0].EklemeTarih}
              &nbsp; &nbsp;
            </span>
            <span
              style={{
                color: "#479736",
                fontSize: 24 * boyutOran,
                fontWeight: "bold"
              }}
            >
              {senaryoListe[0].EklemeSaat}
            </span>
          </div>
          <img
            src={GuncelSenaryoNotlar}
            style={{
              position: "absolute",
              left: solBosluk + 790 * boyutOran,
              top: 420 * boyutOran,
              color: "black",
              fontSize: 16 * boyutOran,

              height: 180 * boyutOran
            }}
          />
          <div
            style={{
              position: "absolute",
              left: solBosluk + 806 * boyutOran,
              top: 517 * boyutOran,
              color: "#863A3C",
              fontSize: 18 * boyutOran,
              fontWeight: "bold"
            }}
          >
            Senaryo&nbsp;
            {senaryoListe[0].KisiAd} {senaryoListe[0].KisiSoyad} Tarafından
            Sisteme&nbsp;
            {senaryoListe[0].EklemeTarih}&nbsp;tarihinde&nbsp;
            {senaryoListe[0].EklemeSaat}&nbsp;saatinde yüklenmiştir.
          </div>

          <div
            style={{
              position: "absolute",
              left: solBosluk + 806 * boyutOran,
              top: 550 * boyutOran,
              color: "black",
              fontSize: 16 * boyutOran,
              width: 800 * boyutOran,
              height: 80 * boyutOran
            }}
          >
            {senaryoListe[0].Notlar}
          </div>
        </Fragment>
      )}

      <div
        id="divCizgi2"
        style={{
          position: "absolute",
          left: solBosluk + 615 * boyutOran,
          top: 640 * boyutOran,
          backgroundColor: "#707070",
          width: 1085 * boyutOran,
          height: 5 * boyutOran
        }}
      >
        &nbsp;
      </div>
      <div
        id="divRevizyonlar"
        style={{
          position: "absolute",
          left: solBosluk + 615 * boyutOran,
          top: 645 * boyutOran,
          color: "red",
          fontSize: 30 * boyutOran
        }}
      >
        Revizyonlar
      </div>
      {senaryoListe[1] && (
        <a
          href={
            url +
            "/api/SahneListe/GetDocument?tokenId=" +
            token +
            "%7C" +
            senaryoListe[1].SenaryoId
          }
        >
          <img
            src={EskiSenaryo}
            style={{
              position: "absolute",
              left: solBosluk + 630 * boyutOran,
              top: 685 * boyutOran,
              height: 65 * boyutOran
            }}
          />
          <div
            style={{
              position: "absolute",
              left: solBosluk + 700 * boyutOran,
              top: 700 * boyutOran,
              color: "white",
              fontSize: 24 * boyutOran
            }}
          >
            {senaryoListe[1].sezonKod}.Sezon >>> {senaryoListe[1].bolumKod}
            .Bölüm
          </div>
          <div
            style={{
              position: "absolute",
              left: solBosluk + 1040 * boyutOran,
              top: 700 * boyutOran,
              color: "white"
            }}
          >
            <span style={{ fontSize: 24 * boyutOran }}>
              {senaryoListe[1].EklemeTarih}
            </span>
            &nbsp;
            <span style={{ fontSize: 16 * boyutOran }}>
              {senaryoListe[1].EklemeSaat}
              &nbsp;Ekleyen:{senaryoListe[1].KisiAd} &nbsp;{" "}
              {senaryoListe[1].KisiSoyad}
            </span>
          </div>
          <div
            style={{
              position: "absolute",
              left: solBosluk + 1410 * boyutOran,
              top: 700 * boyutOran,
              color: "white",
              fontSize: 24 * boyutOran,
              width: 180 * boyutOran,
              textAlign: "center"
            }}
          >
            Revizyon - {senaryoListe.length - 1}
          </div>
        </a>
      )}
      {senaryoListe[2] && (
        <a
          href={
            url +
            "/api/SahneListe/GetDocument?tokenId=" +
            token +
            "%7C" +
            senaryoListe[2].SenaryoId
          }
        >
          <img
            src={EskiSenaryo}
            style={{
              position: "absolute",
              left: solBosluk + 630 * boyutOran,
              top: 765 * boyutOran,
              height: 65 * boyutOran
            }}
          />
          <div
            style={{
              position: "absolute",
              left: solBosluk + 700 * boyutOran,
              top: 780 * boyutOran,
              color: "white",
              fontSize: 24 * boyutOran
            }}
          >
            {senaryoListe[2].sezonKod}.Sezon >>> {senaryoListe[2].bolumKod}
            .Bölüm
          </div>
          <div
            style={{
              position: "absolute",
              left: solBosluk + 1040 * boyutOran,
              top: 780 * boyutOran,
              color: "white"
            }}
          >
            <span style={{ fontSize: 24 * boyutOran }}>
              {senaryoListe[2].EklemeTarih}
            </span>
            &nbsp;
            <span style={{ fontSize: 16 * boyutOran }}>
              {senaryoListe[2].EklemeSaat}
              &nbsp;Ekleyen:{senaryoListe[2].KisiAd} &nbsp;{" "}
              {senaryoListe[2].KisiSoyad}
            </span>
          </div>
          <div
            style={{
              position: "absolute",
              left: solBosluk + 1410 * boyutOran,
              top: 780 * boyutOran,
              color: "white",
              fontSize: 24 * boyutOran,
              width: 180 * boyutOran,
              textAlign: "center"
            }}
          >
            Revizyon - {senaryoListe.length - 2}
          </div>
        </a>
      )}

      {senaryoListe[3] && (
        <a
          href={
            url +
            "/api/SahneListe/GetDocument?tokenId=" +
            token +
            "%7C" +
            senaryoListe[3].SenaryoId
          }
        >
          <img
            src={EskiSenaryo}
            style={{
              position: "absolute",
              left: solBosluk + 630 * boyutOran,
              top: 845 * boyutOran,
              height: 65 * boyutOran
            }}
          />
          <div
            style={{
              position: "absolute",
              left: solBosluk + 700 * boyutOran,
              top: 860 * boyutOran,
              color: "white",
              fontSize: 24 * boyutOran
            }}
          >
            {senaryoListe[3].sezonKod}.Sezon >>> {senaryoListe[3].bolumKod}
            .Bölüm
          </div>
          <div
            style={{
              position: "absolute",
              left: solBosluk + 1040 * boyutOran,
              top: 860 * boyutOran,
              color: "white"
            }}
          >
            <span style={{ fontSize: 24 * boyutOran }}>
              {senaryoListe[3].EklemeTarih}
            </span>
            &nbsp;
            <span style={{ fontSize: 16 * boyutOran }}>
              {senaryoListe[3].EklemeSaat}
              &nbsp;Ekleyen:{senaryoListe[3].KisiAd} &nbsp;{" "}
              {senaryoListe[3].KisiSoyad}
            </span>
          </div>
          <div
            style={{
              position: "absolute",
              left: solBosluk + 1410 * boyutOran,
              top: 860 * boyutOran,
              color: "white",
              fontSize: 24 * boyutOran,
              width: 180 * boyutOran,
              textAlign: "center"
            }}
          >
            Revizyon - {senaryoListe.length - 3}
          </div>
        </a>
      )}
    </div>
  );
};

export default Senaryo;
