import React from "react";
import Popup from "reactjs-popup";
import BurgerIcon from "./BurgerIcon";
import Menu from "./Menus";
import "./index.css";

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center",
  marginTop: "40px"
};
const contentStyle = {
  background: "rgba(255,255,255,0)",
  width: "80%",
  border: "none"
};

const FullScreenMenu = () => {
 

return(
    <div style={styles}>
    <Popup
      modal
      overlayStyle={{ background: "rgba(255,255,255,1" }}
      contentStyle={contentStyle}
      closeOnDocumentClick={true}
      trigger={open => <BurgerIcon open={open} />}
    >
      {close => <Menu close={close} />}
    </Popup>
  </div>
);

};
export default FullScreenMenu;
