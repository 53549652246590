import React, { Fragment, useRef, createRef, useState, useEffect } from "react";
import "../App.css";
import { useWindowResize } from "../Custom-Hooks/useWindowResize";
import axios from "axios";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import { DotLoader } from "react-spinners";

const BolumMasrafKalem = props => {
  const { url } = useApiURL();
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [projeId] = useState(localStorage.getItem("ProjeId"));
  const [yeniEkle, setYeniEkle] = useState(0);
  const handleGrupOgeEkleClick = grup => {
    grup.yeniEkle = true;
    setYeniEkle(true);
    console.log(grup);
  };
  const handleGrupOgeKaydetClick = grup => {
    grup.yeniEkle = false;
    setYeniEkle(false);
    console.log(grup);
  };
  const handleGrupOgeVazgecClick = grup => {
    grup.yeniEkle = false;
    setYeniEkle(false);
    console.log(grup);
  };

  const [innerwidth] = useState(900);

  let inputEl = useRef([createRef()]);
  let inputElTotals = useRef([createRef()]);

  const [bolumDetayListe, setBolumDetayListe] = useState([]);
  const [masrafKalemLoading, setMasrafKalemLoading] = useState(false);

  function logOut() {
    setToken("");
    localStorage.removeItem("BolumId");
    localStorage.removeItem("MenuId");
    localStorage.removeItem("Token");
    localStorage.removeItem("IsProgramiBaslikId");
    window.location.reload();
  }

  function handleOnKeyDown(event, inputIndex) {
    try {
      if (
        !(
          event.key === "ArrowRight" ||
          event.key === "ArrowLeft" ||
          event.key === "ArrowDown" ||
          event.key === "Enter" ||
          event.key === "PageDown" ||
          event.key === "PageUp" ||
          event.key === "ArrowUp"
        )
      )
        return;
      event.preventDefault();
      var elementToFocus;
      var elementToFocusIndex = inputIndex;
      var findIndex = 0;
      if (event.key === "ArrowRight") {
        findIndex = 1;
      }
      if (event.key === "ArrowLeft") {
        findIndex = -1;
      }
      if (event.key === "ArrowDown") {
        findIndex = 12;
      }
      if (event.key === "Enter") {
        findIndex = 1;
      }
      if (event.key === "ArrowUp") {
        findIndex = -12;
      }
      if (event.key === "PageDown") {
        findIndex = 120;
      }
      if (event.key === "PageUp") {
        findIndex = -120;
      }

      for (let i = 1; i < 12; i++) {
        elementToFocusIndex = inputIndex + findIndex * i;
        elementToFocus = inputEl.current[elementToFocusIndex];
        if (elementToFocus != null) break;
      }

      elementToFocus.focus();
      try {
        elementToFocus.select();
      } catch {}
    } catch (err) {
      console.log(elementToFocus);
      console.log(err.message);
    }
  }

  useEffect(() => {}, [bolumDetayListe]);
  useEffect(() => {
    document.title = `CineCost Bölüm Masraf Kalemleri`;
    loadBolumDetay(projeId, props.bolumId, token);
  }, [token]);

  const loadBolumDetay = (projeId, bolumId, token) => {
    var requestModel = {
      ProjeId: projeId,
      BolumId: bolumId,
      Token: token
    };

    setMasrafKalemLoading(true);

    axios
      .post(url + "/api/Ongoru/BolumMasrafKalemListeGrup", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              var res = response.data.responseObject;
              setBolumDetayListe(res);
              setMasrafKalemLoading(false);
            } else if (response.data.responseCode === 401) {
              console.log(response);
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const saveBolumDetay = (token, masrafKalemRawDataId, field, value) => {
    var requestModel = {
      Token: token,
      Id: masrafKalemRawDataId,
      Field: field,
      value: value
    };

    axios
      .post(url + "/api/Ongoru/BolumMasrafKaydet", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              props.loadBolumMasrafOngoruOzet();
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  function handleOnBlur(evt, bmk, alan, tablo_Alan) {
    if (bmk[tablo_Alan] === null) bmk[tablo_Alan] = "";
    if (bmk[tablo_Alan] == evt.target.value) return;
    bmk[tablo_Alan] = evt.target.value;
    saveBolumDetay(token, bmk.masrafKalemRawDataId, alan, evt.target.value);
  }

  function handleOnChange(evt, bmk, elementToFocusIndex, sira) {
    for (let i = 1; i < 5; i++) {
      // yalnızca kendinden sonraki sütunları etkileyecek
      var elementToChange = inputEl.current[elementToFocusIndex + i];
      if (elementToChange != null) {
        if (elementToChange.alt != null) {
          var formul = elementToChange.alt;
          for (let j = 1; j < 9; j++) {
            var deger = 0.0;
            if (inputEl.current[elementToFocusIndex + j - sira] != null) {
              deger = inputEl.current[elementToFocusIndex + j - sira].value;
            }
            formul = formul.replace("<" + j + ">", deger);
          }
          try {
            var r = Math.round(eval(formul) * 100.0) / 100.0;
            elementToChange.value = r;
            handleOnBlur(
              { target: { value: r } },
              bmk,
              elementToChange.formTarget,
              elementToChange.formTarget
            );
          } catch {}
        }
      }
    }
  }

  /**************************************/
  const [boyutOran, setBoyutOran] = useState(1.0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1366;
    var oh = 635;
    var oo = ow / oh;
    if (oo > o) {
      setBoyutOran(w / ow);
    } else if (oo < o) {
      setBoyutOran(h / oh);
    }
  };

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  /**************************************/

  var lineIndex = 0;
  return (
    <Fragment>
      {/* <div
        id="divToPrint"
        style={{
          width: 1000 * boyutOran,
          height: 550 * boyutOran,
          overflowX: "auto",
          overflowY: "auto",
          padding: 0,
          margin: 0
        }}
      > */}
      <table
        id="divBolumMasrafKalemTablo"
        style={{
          width: innerwidth * boyutOran
        }}
      >
        <thead />
        <tbody>
          {bolumDetayListe &&
            bolumDetayListe.map &&
            bolumDetayListe.map((parent1, mapIndex1) => {
              return (
                <Fragment key={"fragment" + mapIndex1}>
                  <tr>
                    <th
                      colSpan="29"
                      style={{
                        backgroundColor: "#305496",
                        color: "white",
                        fontSize: 24 * boyutOran,
                        fontStyle: "italic",
                        fontWeight: "bold"
                      }}
                    >
                      {parent1.grup.masrafKalemAd}
                    </th>
                  </tr>
                  {parent1 &&
                    parent1.gruplar.map((parent2, mapIndex2) => {
                      return (
                        <Fragment key={"fragment" + parent2.grup.masrafKalemId}>
                          <tr>
                            <th
                              colSpan="29"
                              style={{
                                fontSize: 20 * boyutOran,
                                textAlign: "left",
                                fontStyle: "italic",
                                fontWeight: "bold",
                                height: 30 * boyutOran,
                                borderRightWidth: 0
                              }}
                            >
                              &nbsp;&nbsp;
                              {parent2.grup["masrafKalemAd"]}
                            </th>
                          </tr>
                          <tr>
                            {parent2.basliklar &&
                              parent2.basliklar.map &&
                              parent2.basliklar.map(
                                (baslik, mapIndexBaslik) => {
                                  baslik["Toplam" + baslik.tablo_Alan] = 0.0;
                                  return (
                                    <th
                                      id={
                                        "th" +
                                        parent2.grup.masrafKalemId +
                                        "-" +
                                        baslik.masrafMerkeziBaslikId
                                      }
                                      key={
                                        "th" +
                                        parent2.grup.masrafKalemId +
                                        "-" +
                                        baslik.masrafMerkeziBaslikId
                                      }
                                      colSpan={baslik.sutunAdet}
                                      style={
                                        baslik.sabit
                                          ? {
                                              padding: 0,
                                              width:
                                                (innerwidth / 29) *
                                                baslik.sutunAdet *
                                                boyutOran,
                                              fontSize: 16 * boyutOran,
                                              fontStyle: "italic",
                                              fontWeight: "bold",
                                              backgroundColor: "white",
                                              color: "black"
                                            }
                                          : {
                                              padding: 0,
                                              width:
                                                (innerwidth / 29) *
                                                baslik.sutunAdet *
                                                boyutOran,
                                              backgroundColor: "#ED7D31",
                                              color: "white",
                                              fontSize: 16 * boyutOran,
                                              fontStyle: "italic",
                                              fontWeight: "bold"
                                            }
                                      }
                                    >
                                      {baslik.baslik}
                                    </th>
                                  );
                                }
                              )}
                          </tr>

                          {parent2 &&
                            parent2.gruplar.map((bmk, mapIndex3) => {
                              var sahne = bmk.grup;

                              sahne.lineIndex = lineIndex;
                              lineIndex = lineIndex + 12;
                              return (
                                <tr
                                  id={"tr" + sahne.vw_MasrafKalemRawDataId}
                                  key={"tr" + sahne.vw_MasrafKalemRawDataId}
                                  style={{
                                    padding: 0
                                  }}
                                >
                                  {parent2.basliklar &&
                                    parent2.basliklar.map &&
                                    parent2.basliklar.map(
                                      (baslik, mapIndexBaslik) => {
                                        var toplam = parseFloat(
                                          baslik["Toplam" + baslik.tablo_Alan]
                                        );
                                        if (Number.isNaN(toplam)) toplam = 0.0;
                                        var simdi = parseFloat(
                                          sahne[baslik.tablo_Alan]
                                        );
                                        if (Number.isNaN(simdi)) simdi = 0.0;

                                        baslik["Toplam" + baslik.tablo_Alan] =
                                          toplam + simdi;

                                        return (
                                          <td
                                            id={
                                              "th" +
                                              parent2.grup.masrafKalemId +
                                              "-" +
                                              baslik.masrafMerkeziBaslikId
                                            }
                                            key={
                                              "th" +
                                              parent2.grup.masrafKalemId +
                                              "-" +
                                              baslik.masrafMerkeziBaslikId
                                            }
                                            colSpan={baslik.sutunAdet}
                                            style={
                                              baslik.sabit
                                                ? {
                                                    padding: 0,
                                                    width:
                                                      (innerwidth / 29) *
                                                      baslik.sutunAdet *
                                                      boyutOran,
                                                    fontSize: 14 * boyutOran,
                                                    paddingLeft: 5 * boyutOran,

                                                    fontWeight: "bold",
                                                    backgroundColor: "white",
                                                    color: "black"
                                                  }
                                                : {
                                                    padding: 0,
                                                    width:
                                                      (innerwidth / 29) *
                                                      baslik.sutunAdet *
                                                      boyutOran,
                                                    backgroundColor: "#ED7D31",
                                                    color: "white",
                                                    fontSize: 14 * boyutOran,

                                                    fontWeight: "bold"
                                                  }
                                            }
                                          >
                                            {baslik.sabit == 1 &&
                                              sahne[baslik.tablo_Alan]}
                                            {baslik.sabit == 0 && (
                                              <input
                                                ref={input => {
                                                  inputEl.current[
                                                    sahne.lineIndex +
                                                      baslik.sira
                                                  ] = input;
                                                }}
                                                id={
                                                  baslik.tablo_Alan +
                                                  sahne.vw_MasrafKalemRawDataId
                                                }
                                                key={
                                                  baslik.tablo_Alan +
                                                  sahne.vw_MasrafKalemRawDataId
                                                }
                                                type="text"
                                                defaultValue={
                                                  sahne[baslik.tablo_Alan]
                                                }
                                                alt={baslik.formul}
                                                formTarget={baslik.tablo_Alan}
                                                onBlur={ev =>
                                                  handleOnBlur(
                                                    ev,
                                                    sahne,
                                                    baslik.alan,
                                                    baslik.tablo_Alan
                                                  )
                                                }
                                                onChange={ev =>
                                                  handleOnChange(
                                                    ev,
                                                    sahne,
                                                    sahne.lineIndex +
                                                      baslik.sira,
                                                    baslik.sira
                                                  )
                                                }
                                                onKeyDown={ev =>
                                                  handleOnKeyDown(
                                                    ev,
                                                    sahne.lineIndex +
                                                      baslik.sira
                                                  )
                                                }
                                                style={{
                                                  width: "100%",
                                                  backgroundColor: "#ED7D31",
                                                  color: "white"
                                                }}
                                              />
                                            )}
                                          </td>
                                        );
                                      }
                                    )}
                                </tr>
                              );
                            })}

                          {/* ----------------------Yeni Ekle--------------------- */}
                          {parent2.grup.yeniEkle && (
                            <tr
                              id={"trEkle" + parent2.grup.bolumMasrafKalemId}
                              key={"trEkle" + parent2.grup.bolumMasrafKalemId}
                              style={{
                                padding: 0,
                                height: 30 * boyutOran,
                                borderBottomWidth: 3 * boyutOran,
                                borderBottomStyle: "solid",
                                borderColor: "black"
                              }}
                            >
                              {parent2.basliklar &&
                                parent2.basliklar.map &&
                                parent2.basliklar.map(
                                  (baslik, mapIndexBaslik) => {
                                    return (
                                      <td
                                        id={
                                          "th" +
                                          parent2.grup.masrafKalemId +
                                          "-" +
                                          baslik.masrafMerkeziBaslikId
                                        }
                                        key={
                                          "th" +
                                          parent2.grup.masrafKalemId +
                                          "-" +
                                          baslik.masrafMerkeziBaslikId
                                        }
                                        colSpan={baslik.sutunAdet}
                                        style={{
                                          padding: 0,
                                          width:
                                            (innerwidth / 40) *
                                            baslik.sutunAdet *
                                            boyutOran,
                                          fontSize: 16 * boyutOran,
                                          fontStyle: "italic",
                                          fontWeight: "bold",
                                          backgroundColor: "white",
                                          color: "black"
                                        }}
                                      >
                                        {baslik.sabit == 1 && (
                                          <input
                                            id={
                                              "Ekle" +
                                              baslik.tablo_Alan +
                                              parent2.grup.masrafKalemId *
                                                1000 +
                                              baslik.sira
                                            }
                                            key={
                                              "Ekle" +
                                              baslik.tablo_Alan +
                                              parent2.grup.masrafKalemId *
                                                10000 +
                                              baslik.sira
                                            }
                                            type="text"
                                            style={{
                                              width: "100%"
                                            }}
                                            defaultValue={
                                              parent2.grup[
                                                "Yeni_" + baslik.tablo_Alan
                                              ]
                                            }
                                            onChange={evt => {
                                              parent2.grup[
                                                "Yeni_" + baslik.tablo_Alan
                                              ] = evt.target.value;
                                            }}
                                          />
                                        )}
                                        {baslik.sira == 4 && (
                                          <button
                                            onClick={() =>
                                              handleGrupOgeKaydetClick(
                                                parent2.grup
                                              )
                                            }
                                          >
                                            &#x271C;
                                          </button>
                                        )}
                                        {baslik.sira == 5 && (
                                          <button
                                            onClick={() =>
                                              handleGrupOgeVazgecClick(
                                                parent2.grup
                                              )
                                            }
                                          >
                                            &#x2716;
                                          </button>
                                        )}
                                      </td>
                                    );
                                  }
                                )}
                            </tr>
                          )}
                          {/* ----------------------Yeni Ekle--------------------- */}

                          {/* ----------------------Toplam-------------------------- */}

                          <tr
                            key={"trT" + parent2.grup.bolumMasrafKalemId}
                            style={{
                              padding: 0,
                              height: 30 * boyutOran,
                              borderBottomWidth: 3 * boyutOran,
                              borderBottomStyle: "solid",
                              borderColor: "black"
                            }}
                          >
                            {parent2.basliklar &&
                              parent2.basliklar.map &&
                              parent2.basliklar.map(
                                (baslik, mapIndexBaslik) => {
                                  return (
                                    <td
                                      id={
                                        "th" +
                                        parent2.grup.masrafKalemId +
                                        "-" +
                                        baslik.masrafMerkeziBaslikId
                                      }
                                      key={
                                        "th" +
                                        parent2.grup.masrafKalemId +
                                        "-" +
                                        baslik.masrafMerkeziBaslikId
                                      }
                                      colSpan={baslik.sutunAdet}
                                      style={
                                        baslik.sabit
                                          ? {
                                              padding: 0,
                                              width:
                                                (innerwidth / 40) *
                                                baslik.sutunAdet *
                                                boyutOran,
                                              fontSize: 16 * boyutOran,
                                              fontStyle: "italic",
                                              fontWeight: "bold",
                                              backgroundColor: "white",
                                              color: "black"
                                            }
                                          : {
                                              padding: 0,
                                              width:
                                                (innerwidth / 40) *
                                                baslik.sutunAdet *
                                                boyutOran,
                                              backgroundColor: "#ED7D31",
                                              color: "white",
                                              fontSize: 16 * boyutOran,
                                              fontStyle: "italic",
                                              fontWeight: "bold"
                                            }
                                      }
                                    >
                                      {baslik.sabit == 0 && (
                                        <input
                                          ref={input => {
                                            inputElTotals.current[
                                              parent2.grup.masrafKalemId *
                                                10000 +
                                                baslik.sira
                                            ] = input;
                                          }}
                                          id={
                                            "Toplam" +
                                            baslik.tablo_Alan +
                                            parent2.grup.masrafKalemId * 1000 +
                                            baslik.sira
                                          }
                                          key={
                                            "Toplam" +
                                            baslik.tablo_Alan +
                                            parent2.grup.masrafKalemId * 10000 +
                                            baslik.sira
                                          }
                                          type="text"
                                          readOnly={true}
                                          style={{
                                            width: "100%",
                                            backgroundColor: "inherit",
                                            color: "white"
                                          }}
                                          value={
                                            Math.round(
                                              baslik[
                                                "Toplam" + baslik.tablo_Alan
                                              ] * 100
                                            ) / 100
                                          }
                                        />
                                      )}
                                      {baslik.sira == 1 &&
                                        !parent2.grup.yeniEkle && (
                                          <button
                                            onClick={() =>
                                              handleGrupOgeEkleClick(
                                                parent2.grup
                                              )
                                            }
                                          >
                                            &#x271C;
                                          </button>
                                        )}
                                    </td>
                                  );
                                }
                              )}
                          </tr>

                          {/* ---------------------Toplam Bitti--------------------- */}
                          <tr>
                            <td
                              colSpan="29"
                              style={{
                                borderLeftWidth: 0,
                                borderRightWidth: 0
                              }}
                            >
                              <br></br>
                              <br></br>
                              <br></br>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}

                  <tr>
                    <td colSpan="29">&nbsp;</td>
                  </tr>
                </Fragment>
              );
            })}
        </tbody>
      </table>

      {masrafKalemLoading && (
        <DotLoader
          style={{
            display: "block",
            margin: "0 auto",
            borderColor: "red"
          }}
          sizeUnit={"px"}
          size={100 * boyutOran}
          color={"#123abc"}
          loading={masrafKalemLoading}
        />
      )}
    </Fragment>
  );
};

export default BolumMasrafKalem;
