import React, { Fragment, useRef, createRef, useState, useEffect } from "react";
import "../App.css";
import { useWindowResize } from "../Custom-Hooks/useWindowResize";
import axios from "axios";
import SenaryoSahneDokumu from "../img/SahneDokumu/Baslik.png";
import Ekip1 from "../img/IsProgrami/1.Ekip.png";
import Ekip2 from "../img/IsProgrami/2.Ekip.png";
import Klaket from "../img/SahneDokumu/klaket.png";
import ProgramOlustur from "../img/ProgramOlustur.png";
import ImgNo from "../img/Kare_No.png";
import Ekle from "../img/SahneDokumu/Ekle.png";
import Sil from "../img/SahneDokumu/Sil.png";
import Yenile from "../img/SahneDokumu/Yenile.png";
import Popover from "@material-ui/core/Popover";
import TarihSecim from "./TarihSecim";
//import CekimGunler from "./CekimGunler";
import CekimGunBuyuk from "./CekimGunBuyuk";
import Select from "react-select";
import Checkbox from "@material-ui/core/Checkbox";
import uuid from "uuid";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import { DotLoader } from "react-spinners";
import MenuWrapper from "../CircleMenu/FullScreenMenu";

const SahneDokumu = () => {
  const { url } = useApiURL();

  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [projeId] = useState(localStorage.getItem("ProjeId"));
  const [sezonId] = useState(localStorage.getItem("SezonId"));
  const [bolumId, setBolumId] = useState(localStorage.getItem("BolumId"));
  const [selectedDate, setSelectedDate] = React.useState(new Date(Date.now()));
  const [ekipNo, setEkipNo] = useState(0);
  let inputEl = useRef([createRef()]);
  const [sahneInitial, setSahneInitial] = useState({});
  const [sahneDokumu, setSahneListe] = useState([]);
  const [sahneDokumuLoading, setSahneDokumuLoading] = useState(false);
  const listeInitial = [].map(suggestion => ({ value: 1, label: "" }));
  const [ortamListe, setOrtamListe] = useState(listeInitial);
  const [mekanListe, setMekanListe] = useState(listeInitial);
  const [oyuncularListe, setOyuncularListe] = useState(listeInitial);
  const [yOyuncularListe, setYOyuncularListe] = useState(listeInitial);
  const [aksesuarListe, setAksesuarListe] = useState(listeInitial);
  const [teknikMalzemeListe, setTeknikMalzeme] = useState(listeInitial);
  const [setWidth] = useState(window.innerWidth);
  const [setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1704.0;
    var oh = 959.0;
    var oo = ow / oh;
    console.log(o);
    console.log(oo);
    if (oo > o) {
      console.log(h);
      console.log(o / oo);
      h = h * (o / oo);
      o = w / h;
      console.log("oo < o");
      console.log(o);
    } else if (oo < o) {
    }
    setWidth(w);
    setHeight(h);
    setBoyutOran(h / oh);
    setSolBosluk(10);
  };
  const monthNames = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık"
  ];
  const dayNames = [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi"
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  function logOut() {
    setToken("");
    localStorage.removeItem("BolumId");
    localStorage.removeItem("MenuId");
    localStorage.removeItem("Token");
    window.location.reload();
  }
  function handleEkipSecClick(event, ekp) {
    setEkipNo(ekp);
  }
  function handleEkipAtaClick(event, ekp) {
    let dateString =
      selectedDate.getUTCDate() +
      " " +
      monthNames[selectedDate.getMonth()] +
      " " +
      selectedDate.getFullYear() +
      " " +
      dayNames[selectedDate.getDay()];

    if (
      window.confirm(
        "Aşağıda seçilmiş olan sahneleri \n" +
          ekipNo +
          " nolu ekibe \n" +
          dateString +
          "\n" +
          "tarihine atamak istediğinize emin misiniz?"
      )
    ) {
      alert("İş Programı Sayfasına Yönlendirileceksiniz.");
      sahneListeIsProgrami();
    } else {
      alert("Sahneler Atanmadı");
    }
  }
  function siralaSahneNo(sahneDokumu) {
    var sd = sahneDokumu;
    sd = sd.sort(function(a, b) {
      var x = a.sahneNo;
      var y = b.sahneNo;
      try {
        x = x.match(/\d+/)[0].padStart(6, "0");
      } catch {}
      try {
        y = y.match(/\d+/)[0].padStart(6, "0");
      } catch {}

      if (a.sahneId === "00000000-0000-0000-0000-000000000000") return 1;

      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    var i = 0;
    for (let index = 0; index < sd.length; index++) {
      sd[index].index = index;
      sd[index].lineIndex = index * 12;
    }
    return sd;
  }
  function handleSahneNoSiralaClick(event) {
    var sd = siralaSahneNo(sahneDokumu);
    setSahneListe([...sd]);
  }
  function handleGunSiralaClick(event) {
    var sd = sahneDokumu;
    sd = sd.sort(function(a, b) {
      var x = a.gun;
      var y = b.gun;
      var x1 = a.sahneNo;
      var y1 = b.sahneNo;
      try {
        x1 = x1.match(/\d+/)[0].padStart(6, "0");
      } catch {}
      try {
        y1 = y1.match(/\d+/)[0].padStart(6, "0");
      } catch {}
      console.log(a.sahneId);
      if (a.sahneId === "00000000-0000-0000-0000-000000000000") return 1;
      if (!(x === "FB" || y === "FB" || x === "X" || y === "X")) {
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
      }
      if (x1 < y1) {
        return -1;
      }
      if (x1 > y1) {
        return 1;
      }
      return 0;
    });

    var i = 0;
    sd.forEach(element => {
      element.index = i;
      element.lineIndex = i * 12;
      i++;
    });
    setSahneListe([...sd]);
  }
  function handleOrtamSiralaClick(event) {
    var sd = sahneDokumu;
    sd = sd.sort(function(a, b) {
      var x = a.ortamId;
      var y = b.ortamId;
      console.log(a.sahneId);
      if (a.sahneId === "00000000-0000-0000-0000-000000000000") return 1;
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    var i = 0;
    sd.forEach(element => {
      element.index = i;
      element.lineIndex = i * 12;
      i++;
    });
    setSahneListe([...sd]);
  }
  function handleSayfaSiralaClick(event) {
    try {
      var sd = sahneDokumu;
      sd = sd.sort(function(a, b) {
        var x = a.sayfa;
        var y = b.sayfa;
        console.log(a.sahneId);
        if (a.sahneId === "00000000-0000-0000-0000-000000000000") return 1;
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      var i = 0;
      sd.forEach(element => {
        element.index = i;
        element.lineIndex = i * 12;
        i++;
      });
      setSahneListe([...sd]);
    } catch (error) {
      console.log(error);
    }
  }
  function handleMekanSiralaClick(event) {
    var sd = sahneDokumu;
    sd = sd.sort(function(a, b) {
      var x = a.mekanId;
      var y = b.mekanId;
      console.log(a.sahneId);
      if (a.sahneId === "00000000-0000-0000-0000-000000000000") return 1;
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    var i = 0;
    sd.forEach(element => {
      element.index = i;
      element.lineIndex = i * 12;
      i++;
    });
    setSahneListe([...sd]);
  }
  function handleOyuncuSiralaClick(event) {
    var sd = sahneDokumu;
    sd = sd.sort(function(a, b) {
      var x = 0;

      var y = 0;
      console.log(a.sahneId);
      if (a.sahneId === "00000000-0000-0000-0000-000000000000") return 1;

      a.oyuncularListesi &&
        a.oyuncularListesi.forEach(element => {
          x = x + element.value;
        });
      b.oyuncularListesi &&
        b.oyuncularListesi.forEach(element => {
          y = y + element.value;
        });
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    var i = 0;
    sd.forEach(element => {
      element.index = i;
      element.lineIndex = i * 12;
      i++;
    });
    setSahneListe([...sd]);
  }
  function handleKaydetClick(event) {
    saveSahne(token, bolumId, sahneDokumu);
  }
  function handleDurumDegistirClick(event, durum) {
    if (sahneDokumu == null) return;
    var fl = sahneDokumu.filter(item => item.checked);
    if (fl.length === 0) {
      alert("Bir Sahne Seçiniz. Durum");
    }
    fl.forEach(element => {
      loadsahneDokumuDurum(element.sahneId, durum);
    });
    sahneDokumu.forEach(sahne => {
      sahne.checked = false;
      //checkEl.current[sahne.lineIndex].checked = false;
    });
  }
  const loadsahneDokumuDurum = (sahneId, durum) => {
    console.log(sahneId);
    console.log(durum);
    if (sahneId === 0) {
      return;
    }
    var requestModel = {
      Field: sahneId,
      Value: durum,
      Token: token
    };
    axios
      .post(url + "/api/SahneListe/SahneDokumDurum", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response);
              var i = 0;
              var res = response.data.responseObject;

              res.forEach(element => {
                element.mekanListesi = JSON.parse(element.mekanListe);
                element.teknikMalzemeListesi = JSON.parse(
                  element.teknikMalzemeListe
                );
                element.aksesuarListesi = JSON.parse(element.aksesuarListe);
                element.oyuncularListesi = JSON.parse(element.oyuncularListe);
                element.yardimciOyuncularListesi = JSON.parse(
                  element.yardimciOyuncularListe
                );

                element.index = i;
                element.lineIndex = i * 12;
                i++;
              });
              setSahneListe(res);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  const sahneSil = sahneId => {
    if (sahneId === "111") {
      return;
    }
    var requestModel = {
      Field: sahneId,
      Token: token
    };
    axios
      .post(url + "/api/SahneListe/SahneSil", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              let sahneInitialInstance = Object.assign({}, sahneInitial);
              loadSahne(bolumId, token, sahneInitialInstance);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  function handleSilClick(event) {
    if (sahneDokumu == null) return;
    var fl = sahneDokumu.filter(item => item.checked);
    if (fl.length === 0) {
      alert("Bir Sahne Seçiniz. Sil");
    }
    fl.forEach(element => {
      sahneSil(element.sahneId);
    });
    sahneDokumu.forEach(sahne => {
      sahne.checked = false;
    });
  }
  function handleBolumSecClick(event) {
    localStorage.removeItem("BolumId");
    localStorage.removeItem("MenuId");
    window.location.reload();
  }
  function handleMenuSecClick(event) {
    localStorage.removeItem("MenuId");
    window.location.reload();
  }
  function handleYenileClick(event) {
    let sahneInitialInstance = Object.assign({}, sahneInitial);
    loadSahne(bolumId, token, sahneInitialInstance);
    // setBolumId(0);
    // setTimeout(() => {
    //   setBolumId(bolumId);
    // }, 500);
  }
  function handleDateClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleDateClose() {
    setAnchorEl(null);
  }
  function handleDateChange(date) {
    setAnchorEl(null);
    setSelectedDate(date);
  }

  const loadSahneInitial = (bolumId, token) => {
    var requestModel = {
      bolumId: bolumId,
      Token: token
    };
    axios
      .post(url + "/api/SahneListe/SahneYeni", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              var i = 0;
              var res = response.data.data.sahneListe;
              res.forEach(element => {
                element.mekanListesi = JSON.parse(element.mekanListe);
                element.teknikMalzemeListesi = JSON.parse(
                  element.teknikMalzemeListe
                );
                element.aksesuarListesi = JSON.parse(element.aksesuarListe);
                element.oyuncularListesi = JSON.parse(element.oyuncularListe);
                element.yardimciOyuncularListesi = JSON.parse(
                  element.yardimciOyuncularListe
                );

                element.index = i;
                element.lineIndex = i * 12;
                element.keyId = i * 12;

                i++;
              });

              setSahneInitial(res[0]);
              console.log("setSahneInitial(res[0]);");
              let sahneInitialInstance = Object.assign({}, res[0]);
              loadSahne(bolumId, token, sahneInitialInstance);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadSahne = (bolumId, token, sahneInitialParam) => {
    if (sahneInitialParam === []) logOut();

    if (bolumId === 0) {
      setSahneListe([sahneInitialParam]);
      return;
    }
    setSahneDokumuLoading(true);
    var requestModel = {
      bolumId: bolumId,
      Token: token
    };
    axios
      .post(url + "/api/SahneListe/SahneListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              var i = 0;
              var res = response.data.data.sahneListe;

              var t0 = performance.now();
              res.forEach(element => {
                element.mekanListesi = JSON.parse(element.mekanListe);
                element.teknikMalzemeListesi = JSON.parse(
                  element.teknikMalzemeListe
                );
                element.aksesuarListesi = JSON.parse(element.aksesuarListe);
                element.oyuncularListesi = JSON.parse(element.oyuncularListe);
                element.yardimciOyuncularListesi = JSON.parse(
                  element.yardimciOyuncularListe
                );

                element.index = i;
                element.lineIndex = i * 12;
                element.keyId = i * 12;

                i++;
              });

              const sahneInitialInstance = sahneInitialParam;

              sahneInitialInstance.index = i;
              sahneInitialInstance.lineIndex = i * 12;
              sahneInitialInstance.keyId = i * 12;

              console.log(res);
              //var t1 = performance.now();
              //console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.");
              res = siralaSahneNo(res);
              setSahneListe([...res, sahneInitialInstance]);
              setSahneDokumuLoading(false);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  const saveSahne = (token, bolumId, sahneDokumu) => {
    let sahneDokumuTextList = sahneDokumu.map(line => JSON.stringify(line));
    console.log(sahneDokumuTextList);
    var requestModel = {
      sahneDokumu: sahneDokumuTextList,
      bolumId,
      token
    };
    console.log(requestModel);
    axios
      .post(url + "/api/SahneListe/SahneListeKaydet", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              var i = 0;
              var res = response.data.data.sahneListe;
              res.forEach(element => {
                element.mekanListesi = JSON.parse(element.mekanListe);
                element.teknikMalzemeListesi = JSON.parse(
                  element.teknikMalzemeListe
                );
                element.aksesuarListesi = JSON.parse(element.aksesuarListe);
                element.oyuncularListesi = JSON.parse(element.oyuncularListe);
                element.yardimciOyuncularListesi = JSON.parse(
                  element.yardimciOyuncularListe
                );

                element.index = i;
                element.lineIndex = i * 12;
                element.keyId = i * 12;

                i++;
              });

              loadSahneInitial(bolumId, token);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  const sahneListeIsProgrami = () => {
    let sahneDokumuTextList = sahneDokumu.map(line => JSON.stringify(line));

    var requestModel = {
      sezonId: sezonId,
      ekipNo,
      tarih: selectedDate,
      sahneDokumu: sahneDokumuTextList,
      token
    };
    console.log(requestModel);
    axios
      .post(url + "/api/SahneListe/SahneListeIsProgrami", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              var i = 0;
              var res = response.data.data.sahneListe;
              res.forEach(element => {
                element.mekanListesi = JSON.parse(element.mekanListe);
                element.teknikMalzemeListesi = JSON.parse(
                  element.teknikMalzemeListe
                );
                element.aksesuarListesi = JSON.parse(element.aksesuarListe);
                element.oyuncularListesi = JSON.parse(element.oyuncularListe);
                element.yardimciOyuncularListesi = JSON.parse(
                  element.yardimciOyuncularListe
                );

                element.index = i;
                element.lineIndex = i * 12;
                element.keyId = i * 12;

                i++;
              });
              console.log(res);
              //setSahneListe(res);
              //loadSahneInitial(bolumId, token);
              //window.location.reload();
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  const loadOrtam = token => {
    var requestModel = {
      Token: token
    };
    axios
      .post(url + "/api/OrtamListe/OrtamListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              // console.log(response);
              setOrtamListe(
                response.data.data.ortamListe.map(suggestion => ({
                  value: suggestion.ortamId,
                  label: suggestion.ortamAd
                }))
              );
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadMekan = (token, projeId) => {
    var requestModel = {
      Token: token,
      ProjeId: projeId,
      AlanId: 1
    };
    axios
      .post(url + "/api/MasrafKalemListe/MasrafKalemListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              // console.log(response);
              setMekanListe(
                response.data.data.masrafKalemListe.map(suggestion => ({
                  value: suggestion.masrafKalemId,
                  label: suggestion.masrafKalemAd
                }))
              );
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadOyuncular = (token, projeId) => {
    var requestModel = {
      Token: token,
      ProjeId: projeId,
      AlanId: 2
    };
    axios
      .post(url + "/api/MasrafKalemListe/MasrafKalemListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              // console.log(response);
              setOyuncularListe(
                response.data.data.masrafKalemListe.map(suggestion => ({
                  value: suggestion.masrafKalemId,
                  label: suggestion.masrafKalemAd
                }))
              );
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  const loadYOyuncular = (token, projeId) => {
    var requestModel = {
      Token: token,
      ProjeId: projeId,
      AlanId: 3
    };
    axios
      .post(url + "/api/MasrafKalemListe/MasrafKalemListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              // console.log(response);
              setYOyuncularListe(
                response.data.data.masrafKalemListe.map(suggestion => ({
                  value: suggestion.masrafKalemId,
                  label: suggestion.masrafKalemAd
                }))
              );
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  const loadAksesuar = (token, projeId) => {
    var requestModel = {
      Token: token,
      ProjeId: projeId,
      AlanId: 4
    };
    axios
      .post(url + "/api/MasrafKalemListe/MasrafKalemListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              // console.log(response);
              setAksesuarListe(
                response.data.data.masrafKalemListe.map(suggestion => ({
                  value: suggestion.masrafKalemId,
                  label: suggestion.masrafKalemAd
                }))
              );
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  const loadTeknikMalzeme = (token, projeId) => {
    var requestModel = {
      Token: token,
      ProjeId: projeId,
      AlanId: 5
    };
    axios
      .post(url + "/api/MasrafKalemListe/MasrafKalemListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setTeknikMalzeme(
                response.data.data.masrafKalemListe.map(suggestion => ({
                  value: suggestion.masrafKalemId,
                  label: suggestion.masrafKalemAd
                }))
              );
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  // useEffect(
  //   () => {
  //     document.title = `CineCost Sahne Dökümü`;
  //     loadSahne();
  //     loadOrtam();
  //     loadMekan();
  //     loadOyuncular();
  //     loadYOyuncular();
  //     loadAksesuar();
  //     loadTeknikMalzeme();
  //     loadProduksiyon();
  //   },
  //   { token }
  // );

  useEffect(() => {
    //console.log("sahnedokumu");
  }, [sahneDokumu]);
  useEffect(() => {
    document.title = `CineCost Sahne Dökümü`;
    loadOrtam();
  }, [token]);
  useEffect(() => {
    loadSahneInitial(bolumId, token);
  }, [bolumId, token]);
  useEffect(() => {
    loadMekan(token, projeId);
    loadOyuncular(token, projeId);
    loadYOyuncular(token, projeId);
    loadAksesuar(token, projeId);
    loadTeknikMalzeme(token, projeId);
  }, [projeId]);
  const { screenwidth, screenheight } = useWindowResize();
  var totalwidth = Math.max(screenwidth, 1366);
  const [innerwidth] = useState(totalwidth - 86);
  function handleChange(sahne) {
    console.log(sahne.sahneId);
    if (
      sahne.sahneId === undefined ||
      sahne.sahneId === null ||
      sahne.sahneId === "" ||
      sahne.sahneId === "111" ||
      sahne.sahneId === "00000000-0000-0000-0000-000000000000"
    ) {
      sahne.sahneId = uuid.v4();
      sahne.bolumId = bolumId;

      setTimeout(() => {
        let sahneInitialInstance = Object.assign({}, sahneInitial);
        sahneInitialInstance.sahneId = "111";
        sahneInitialInstance.index = sahneDokumu.length;
        sahneInitialInstance.lineIndex = sahneDokumu.length * 12;
        sahneInitialInstance.keyId = sahneDokumu.length * 12;

        setSahneListe([...sahneDokumu, sahneInitialInstance]);
      }, 10);
    }
    console.log(JSON.stringify(sahne));
  }
  function handleCHKChange(evt, sahne) {
    handleChange(sahne);
    sahne.checked = evt.target.checked;
  }
  function handleBLMChange(evt, sahne) {
    handleChange(sahne);
    sahne.bolumNo = evt.target.value;
  }
  function handleSHNChange(evt, sahne) {
    handleChange(sahne);
    sahne.sahneNo = evt.target.value;
  }
  function handleGUNChange(evt, sahne) {
    handleChange(sahne);
    sahne.gun = evt.target.value;
  }
  function handleZAMANChange(evt, sahne) {
    handleChange(sahne);
    sahne.ortamListesi = evt;
    sahne.ortamId = evt.value;
  }
  function handleSayfaChange(evt, sahne) {
    handleChange(sahne);
    sahne.sayfa = evt.target.value;
  }
  function handleMEKANChange(evt, sahne) {
    handleChange(sahne);
    sahne.mekanListesi = evt;
    sahne.mekanId = evt.value;
  }
  function handleOYUNCULARChange(evt, sahne) {
    handleChange(sahne);
    sahne.oyuncularListesi = evt;
  }
  function handleYARDIMCIOYUNCULARChange(evt, sahne) {
    handleChange(sahne);
    sahne.yardimciOyuncularListesi = evt;
  }
  function handleAKSESUARChange(evt, sahne) {
    handleChange(sahne);
    sahne.aksesuarListesi = evt;
  }
  function handleAksesuarChange(evt, sahne) {
    handleChange(sahne);
    sahne.aksesuar = evt.target.value;
  }
  function handleTEKNIKMALZEMEChange(evt, sahne) {
    handleChange(sahne);
    sahne.teknikMalzemeListesi = evt;
  }
  function handleTeknikMalzemeChange(evt, sahne) {
    handleChange(sahne);
    sahne.teknikMalzeme = evt.target.value;
  }
  function handleOZETChange(evt, sahne) {
    handleChange(sahne);
    sahne.ozet = evt.target.value;
  }
  function handlePNOTChange(evt, sahne) {
    handleChange(sahne);
    sahne.produksiyonNot = evt.target.value;
  }
  function handleOnKeyDown(event, inputIndex) {
    try {
      if (event.key === "ArrowRight") {
        event.preventDefault();
        inputEl.current[inputIndex + 1].focus();
        inputEl.current[inputIndex + 1].select();
      }
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        inputEl.current[inputIndex - 1].focus();
        inputEl.current[inputIndex - 1].select();
      }
      if (event.key === "ArrowDown") {
        event.preventDefault();
        inputEl.current[inputIndex + 12].focus();
        inputEl.current[inputIndex + 12].select();
        event.preventDefault();
      }
      if (event.key === "ArrowUp") {
        event.preventDefault();
        inputEl.current[inputIndex - 12].focus();
        inputEl.current[inputIndex - 12].select();
        event.preventDefault();
      }
    } catch {}
  }
  // console.log((innerwidth / 40) * 1);
  return (
    <Fragment>
      <div
        style={{
          width: totalwidth,
          height: 75 * boyutOran,
          padding: 0,
          margin: 0
        }}
      >
        <div
          style={{
            height: 75 * boyutOran,
            padding: 0,
            margin: 0,
            width: 250 * boyutOran
          }}
          className="boxLeft"
        >
          <img src={SenaryoSahneDokumu} alt="Senaryo Sahne Dökümü" />
        </div>
        <div
          className="boxLeft"
          onClick={ev => handleKaydetClick(ev)}
          style={{ position: "absolute", left: 390 * boyutOran }}
        >
          <img
            src={Ekle}
            alt="Ekle"
            style={{
              height: 55 * boyutOran,
              marginTop: 5 * boyutOran
            }}
          ></img>
        </div>
        <div
          className="boxLeft"
          style={{ position: "absolute", left: 500 * boyutOran }}
        >
          <img
            src={Yenile}
            alt="Yenile"
            style={{
              height: 55 * boyutOran,
              marginTop: 5 * boyutOran
            }}
            onClick={handleYenileClick}
          ></img>
        </div>
        <div className="boxLeft" style={{ position: "absolute", left: 600 }}>
          <img
            src={Sil}
            alt="Sil"
            style={{
              height: 55 * boyutOran,
              marginTop: 5 * boyutOran
            }}
            onClick={ev => handleSilClick(ev)}
          ></img>
        </div>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 84
          }}
        >
          <MenuWrapper></MenuWrapper>
        </div>
        <div
          className="boxLeft"
          style={{
            position: "absolute",
            left: 700 * boyutOran,
            fontSize: 30 * boyutOran,
            top: 15 * boyutOran,
            color: "#419a18",
            width: 800 * boyutOran
          }}
        >
          {localStorage.getItem("ProjeAd")} >>>
          {localStorage.getItem("SezonKod")}.Sezon >>>
          {localStorage.getItem("BolumKod")}.Bölüm
        </div>
        {ekipNo !== 0 && (
          <div
            style={{
              height: 75,
              padding: 0,
              margin: 0,
              width: 78
            }}
            className="boxLeft"
          ></div>
        )}
      </div>
      <div
        id="divSahneDokumuBaslik"
        style={{ width: totalwidth, margin: 0, padding: 0 }}
      >
        <div style={{ width: 41 }}> &nbsp; </div>
        <div style={{ width: (innerwidth / 40) * 1 - 2 }}>BLM</div>
        <div
          style={{ width: (innerwidth / 40) * 1 - 2 }}
          onClick={ev => handleSahneNoSiralaClick(ev)}
        >
          SHN
        </div>
        <div
          style={{ width: (innerwidth / 40) * 1 - 2 }}
          onClick={ev => handleGunSiralaClick(ev)}
        >
          GÜN
        </div>
        <div
          style={{ width: (innerwidth / 40) * 3 - 2 }}
          onClick={ev => handleOrtamSiralaClick(ev)}
        >
          ZAMAN
        </div>
        <div
          style={{ width: (innerwidth / 40) * 1 - 2 }}
          onClick={ev => handleSayfaSiralaClick(ev)}
        >
          SYF
        </div>
        <div
          style={{ width: (innerwidth / 40) * 5 - 2 }}
          onClick={ev => handleMekanSiralaClick(ev)}
        >
          MEKAN
        </div>
        <div
          style={{ width: (innerwidth / 40) * 5 - 2 }}
          onClick={ev => handleOyuncuSiralaClick(ev)}
        >
          OYUNCULAR
        </div>
        <div style={{ width: (innerwidth / 40) * 4 - 2 }}>YRD.OYUNCULAR</div>
        <div style={{ width: (innerwidth / 40) * 4 - 2 }}>AKSESUAR</div>
        <div style={{ width: (innerwidth / 40) * 3 - 2 }}>TEK.MALZEME</div>
        <div style={{ width: (innerwidth / 40) * 3 - 2 }}>PROD.NOTU</div>
        <div style={{ width: (innerwidth / 40) * 9 - 2 }}>ÖZET</div>
        <div style={{ width: 41 }}> &nbsp; </div>
      </div>
      <div
        id="divSahneDokumu"
        style={{
          width: totalwidth,
          height: screenheight - 200,
          overflowX: "hidden",
          overflowY: "auto",
          padding: 0,
          margin: 0
        }}
      >
        {sahneDokumuLoading && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                width: screenwidth / 2 - (screenheight - 240) / 2,
                float: "left"
              }}
            >
              &nbsp;
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                width: screenheight - 240,
                float: "left"
              }}
            >
              <DotLoader
                style={{
                  display: "block",
                  margin: "0 auto",
                  borderColor: "red"
                }}
                sizeUnit={"px"}
                size={screenheight - 240}
                color={"#123abc"}
                loading={sahneDokumuLoading}
              />
            </div>
          </div>
        )}
        <table
          id="sahneDokumuTable"
          style={{ width: totalwidth, margin: 0, padding: 0 }}
        >
          {/*  <thead>
            <tr>
              <th style={{ padding: 0, width: 43 }}> &nbsp; </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 1 }}>BLM</th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 1 }}>SHN</th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 1 }}>GÜN</th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 3 }}>
                ZAMAN
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 1 }}>SYF</th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 5 }}>
                MEKAN
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 5 }}>
                OYUNCULAR
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 4 }}>
                YRD.OYUNCULAR
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 4 }}>
                AKSESUAR
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 3 }}>
                TEK.MALZEME
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 3 }}>
                PROD.NOTU
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 9 }}>ÖZET</th>
            </tr>
          </thead> */}
          <tbody>
            {!sahneDokumuLoading &&
              sahneDokumu &&
              sahneDokumu.map((sahne, index) => {
                let bgColor = sahne.sahneDurumRenkKod;
                let fontColor = sahne.sahneDurumYaziRenkKod;
                var t0 = performance.now();
                const customStyles = {
                  control: (base, state) => ({
                    ...base,
                    backgroundColor: bgColor,
                    color: fontColor,
                    borderRadius: state.isFocused ? "0px 0px 0 0" : 3,
                    border: 0,
                    borderColor: state.isFocused ? "yellow" : "green",
                    boxShadow: state.isFocused ? null : null,
                    "&:hover": {
                      borderColor: state.isFocused ? "red" : "blue"
                    }
                  }),
                  menu: base => ({
                    ...base,
                    borderRadius: 0,
                    marginTop: 0,
                    backgroundColor: bgColor,
                    color: fontColor
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    padding: 0,
                    display: "none",
                    backgroundColor: bgColor,
                    color: fontColor
                  }),
                  menuList: base => ({
                    ...base,
                    padding: 0,
                    backgroundColor: bgColor,
                    color: fontColor
                  })
                };

                let lineTR = (
                  <tr
                    key={"tr" + sahne.keyId}
                    style={{
                      padding: 0,
                      backgroundColor: bgColor,
                      color: fontColor
                    }}
                  >
                    <td style={{ width: 41 }}>
                      <Checkbox
                        id={"cb" + sahne.keyId}
                        key={"cb" + sahne.keyId}
                        checked={sahne.checked}
                        onChange={ev => handleCHKChange(ev, sahne)}
                        value="checkedA"
                        inputProps={{
                          "aria-label": "primary checkbox"
                        }}
                        style={{ padding: 0 }}
                      />
                    </td>
                    <td style={{ width: (innerwidth / 40) * 1 - 2 }}>
                      <input
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 0] = input;
                        }}
                        id={"blm" + sahne.keyId}
                        key={"blm" + sahne.keyId}
                        type="text"
                        defaultValue={sahne.bolumKod}
                        onChange={ev => handleBLMChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 0)
                        }
                        style={{
                          width: "100%",
                          backgroundColor: bgColor,
                          color: fontColor
                        }}
                      />
                    </td>
                    <td
                      style={{ padding: 0, width: (innerwidth / 40) * 1 - 2 }}
                    >
                      <input
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 1] = input;
                        }}
                        id={"shn" + sahne.keyId}
                        key={"shn" + sahne.keyId}
                        type="text"
                        defaultValue={sahne.sahneNo}
                        onChange={ev => handleSHNChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 1)
                        }
                        style={{
                          width: "100%",
                          backgroundColor: bgColor,
                          color: fontColor
                        }}
                      />
                    </td>
                    <td style={{ width: (innerwidth / 40) * 1 - 2 }}>
                      <input
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 2] = input;
                        }}
                        id={"gun" + sahne.keyId}
                        key={"gun" + sahne.keyId}
                        type="text"
                        defaultValue={sahne.gun}
                        onChange={ev => handleGUNChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 2)
                        }
                        style={{
                          width: "100%",
                          backgroundColor: bgColor,
                          color: fontColor
                        }}
                      />
                    </td>
                    <td style={{ width: (innerwidth / 40) * 3 - 2 }}>
                      <Select
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 3] = input;
                        }}
                        id={"ort" + sahne.keyId}
                        key={"ort" + sahne.keyId}
                        defaultValue={{
                          value: sahne.ortamId,
                          label: sahne.ortamAd
                        }}
                        onChange={ev => handleZAMANChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 3)
                        }
                        options={ortamListe}
                        styles={customStyles}
                        width={(innerwidth / 40) * 3 - 4}
                      />
                    </td>
                    <td style={{ width: (innerwidth / 40) * 1 - 2 }}>
                      <input
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 4] = input;
                        }}
                        id={"syf" + sahne.keyId}
                        key={"syf" + sahne.keyId}
                        type="text"
                        defaultValue={sahne.sayfa}
                        onChange={ev => handleSayfaChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 4)
                        }
                        style={{
                          width: "100%",
                          backgroundColor: bgColor,
                          color: fontColor
                        }}
                      />
                    </td>
                    <td style={{ width: (innerwidth / 40) * 5 - 2 }}>
                      <Select
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 5] = input;
                        }}
                        id={"mkn" + sahne.keyId}
                        key={"mkn" + sahne.keyId}
                        defaultValue={{
                          value: sahne.mekanId,
                          label: sahne.mekanAd
                        }}
                        onChange={ev => handleMEKANChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 5)
                        }
                        options={mekanListe}
                        styles={customStyles}
                      />
                    </td>
                    <td style={{ width: (innerwidth / 40) * 5 - 2 }}>
                      <Select
                        placeholder="..."
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 6] = input;
                        }}
                        id={"oyn" + sahne.keyId}
                        key={"oyn" + sahne.keyId}
                        defaultValue={sahne.oyuncularListesi}
                        onChange={ev => handleOYUNCULARChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 6)
                        }
                        options={oyuncularListe}
                        isMulti
                        isClearable={false}
                        closeMenuOnSelect={false}
                        styles={customStyles}
                      />
                    </td>
                    <td style={{ width: (innerwidth / 40) * 4 - 2 }}>
                      <Select
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 7] = input;
                        }}
                        placeholder="..."
                        id={"yoy" + sahne.keyId}
                        key={"yoy" + sahne.keyId}
                        defaultValue={sahne.yardimciOyuncularListesi}
                        onChange={ev =>
                          handleYARDIMCIOYUNCULARChange(ev, sahne)
                        }
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 7)
                        }
                        options={yOyuncularListe}
                        isMulti={true}
                        isClearable={false}
                        isOptionDisabled={false}
                        styles={customStyles}
                        width={(innerwidth / 40) * 4 - 4}
                      />
                    </td>
                    <td style={{ width: (innerwidth / 40) * 4 - 2 }}>
                      <textarea
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 8] = input;
                        }}
                        id={"aks" + sahne.keyId}
                        key={"aks" + sahne.keyId}
                        defaultValue={sahne.aksesuar}
                        onChange={ev => handleAksesuarChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 8)
                        }
                        style={{
                          width: "98%",
                          height: "98%",
                          backgroundColor: bgColor,
                          color: fontColor
                        }}
                      />

                      {/* <Select
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 8] = input;
                        }}
                        placeholder="..."
                        id={"aks" + sahne.keyId}
                        key={"aks" + sahne.keyId}
                        defaultValue={sahne.aksesuarListesi}
                        onChange={ev => handleAKSESUARChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 8)
                        }
                        options={aksesuarListe}
                        isMulti={true}
                        isClearable={false}
                        styles={customStyles}
                      /> */}
                    </td>
                    <td style={{ width: (innerwidth / 40) * 3 - 2 }}>
                      <textarea
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 9] = input;
                        }}
                        id={"aks" + sahne.keyId}
                        key={"aks" + sahne.keyId}
                        defaultValue={sahne.teknikMalzeme}
                        onChange={ev => handleTeknikMalzemeChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 9)
                        }
                        style={{
                          width: "98%",
                          height: "98%",
                          backgroundColor: bgColor,
                          color: fontColor
                        }}
                      />

                      {/* <Select
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 9] = input;
                        }}
                        placeholder="..."
                        id={"tml" + sahne.keyId}
                        key={"tml" + sahne.keyId}
                        defaultValue={sahne.teknikMalzemeListesi}
                        onChange={ev => handleTEKNIKMALZEMEChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 9)
                        }
                        options={teknikMalzemeListe}
                        isMulti={true}
                        isClearable={false}
                        styles={customStyles}
                      /> */}
                    </td>
                    <td style={{ width: (innerwidth / 40) * 3 - 2 }}>
                      <textarea
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 10] = input;
                        }}
                        id={"prd" + sahne.keyId}
                        key={"prd" + sahne.keyId}
                        defaultValue={sahne.produksiyonNot}
                        onChange={ev => handlePNOTChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 10)
                        }
                        style={{
                          width: "98%",
                          backgroundColor: bgColor,
                          color: fontColor
                        }}
                      />
                    </td>
                    <td style={{ width: (innerwidth / 40) * 9 - 2 }}>
                      <textarea
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 11] = input;
                        }}
                        id={"ozt" + sahne.keyId}
                        key={"ozt" + sahne.keyId}
                        defaultValue={sahne.ozet}
                        onChange={ev => handleOZETChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 11)
                        }
                        style={{
                          width: "98%",
                          backgroundColor: bgColor,
                          color: fontColor
                        }}
                      />
                    </td>
                    <td style={{ width: 43 }} />
                  </tr>
                );
                //var t1 = performance.now();
                //console.log(                  "Call to doSomething took " + (t1 - t0) + " milliseconds.");
                return lineTR;
              })}
          </tbody>
        </table>
      </div>
      <div
        style={{
          width: "100%",
          height: 75,
          padding: 0,
          margin: 0
        }}
      >
        <div className="boxLeft boxWidth10">
          <div
            id="TarihSaatDiv"
            style={{
              height: 75,
              width: 300,
              padding: 0,
              margin: 0,
              backgroundColor: "black",
              color: "white",
              marginLeft: 10
            }}
            onClick={handleDateClick}
            className="boxLeft"
          >
            <div
              className="boxLeft boxWidth20"
              style={{ fontSize: 40, margin: 10 }}
            >
              {selectedDate.getUTCDate()}
            </div>
            <div style={{ fontSize: 40 }}>
              {dayNames[selectedDate.getDay()]}
            </div>
            <div className="boxLeft boxWidth70" style={{ fontSize: 10 }}>
              <span style={{ fontSize: 20 }}>
                {monthNames[selectedDate.getMonth()]}{" "}
              </span>{" "}
              {selectedDate.getFullYear()}
            </div>
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleDateClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <TarihSecim tarih={selectedDate} onSelectDate={handleDateChange} />
          </Popover>
        </div>
        <div
          style={{
            height: 75,
            padding: 0,
            margin: 0,
            width: 78,
            position: "absolute",
            left: 350
          }}
          className={"boxLeft" + (ekipNo === 1 ? " boxDarkRed" : "")}
        >
          <img
            src={Ekip1}
            alt="Birinci Ekip"
            onClick={ev => handleEkipSecClick(ev, 1)}
            style={{
              padding: 0,
              margin: 7
            }}
          />
        </div>
        <div
          style={{
            height: 75,
            padding: 0,
            margin: 0,
            width: 78,
            position: "absolute",
            left: 420
          }}
          className={"boxLeft" + (ekipNo === 2 ? " boxDarkRed" : "")}
        >
          <img
            src={Ekip2}
            alt="İkinci Ekip"
            title="İkinci Ekip"
            onClick={ev => handleEkipSecClick(ev, 2)}
            style={{
              padding: 0,
              margin: 7
            }}
          />
        </div>
        <div
          style={{
            height: 75,
            padding: 0,
            margin: 0,
            width: 75,
            position: "absolute",
            left: 494,
            marginTop: -1
          }}
          onClick={ev => handleEkipAtaClick(ev)}
        >
          <img
            src={ProgramOlustur}
            alt="ProgramOlustur"
            title="Program Oluştur"
            style={{
              padding: 0,
              margin: 7
            }}
          />
        </div>
        <div
          style={{
            height: 75,
            padding: 0,
            margin: 0,
            width: totalwidth - 250 - 300 - 75 - 75 - 75 - 10,
            position: "absolute",
            left: 720
          }}
          className="boxRight"
        >
          <CekimGunBuyuk
            key="CekimGun1"
            id="CekimGun1"
            cekimGunWidth={558}
            ekip={1}
            bolumId={bolumId}
          />
          <CekimGunBuyuk
            key="CekimGun2"
            id="CekimGun2"
            cekimGunWidth={558}
            ekip={2}
            bolumId={bolumId}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default SahneDokumu;
