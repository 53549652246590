import Ara from "../img/Demirbas/Ara.png";
import Baslik from "../img/Demirbas/Baslik.png";
import Ekle from "../img/Demirbas/Ekle.png";
import Zimmet from "../img/Demirbas/Zimmet.png";
import Iade from "../img/Demirbas/Iade.png";
import Hurda from "../img/Demirbas/Hurda.png";
import Kayip from "../img/Demirbas/Kayip.png";
import Sil from "../img/Demirbas/Sil.png";
import MenuAc from "../img/Demirbas/MenuAc.png";
import Cikar from "../img/Demirbas/Cikar.png";
import Yeni from "../img/Demirbas/Yeni.png";
import Grid4 from "../img/Demirbas/Grid4.png";
import MenuWrapper from "../CircleMenu/FullScreenMenu";
import { TextBox, Lookup, TextArea } from "devextreme-react";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import React, { useState, useEffect, Fragment } from "react";
import "../App.css";
import axios from "axios";
import { Center } from "devextreme-react/map";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel
} from 'devextreme-react/data-grid';

const DemirbasTutanakFormu = () => {
  const { url } = useApiURL();
  const [ayarAlanId, setAyarAlanId] = useState(1);
  /***************METHODS***************/
  const handleClick = alanId => {
    setAyarAlanId(alanId);
  };
  const yenileClick = () => {
    window.location.reload();
  };
  /***************METHODS***************/

  /**************************************/
  const [screenwidth, setWidth] = useState(window.innerWidth);
  const [screenheight, setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1704.0;
    var oh = 959.0;
    var oo = ow / oh;
    console.log(o);
    console.log(oo);
    if (oo > o) {
      console.log(h);
      console.log(o / oo);
      h = h * (o / oo);
      o = w / h;
      console.log("oo < o");
      console.log(o);
    } else if (oo < o) {
    }
    setWidth(w);
    setHeight(h);
    setBoyutOran(h / oh);
    setSolBosluk(10);
  };

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  const pageSizes = [10, 25, 50, 100];
  
  useEffect(() => {
    listener();
    loadDemirbasTutanak();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  const [demirbasKategoriListe, setDemirbasKategoriListe] = useState([
    { id: 0, text: "---" }
  ]);

  const [demirbasDataId,setDemirbasDataId]=useState({});
  const [demirbasData,setDemirbasData]=useState({});



  const loadDemirbasTutanak = () => {
    var requestModel = {
      Id: 0,
      Field: "5435",
      Token: token
    };
    console.log(requestModel);
    if (demirbasData !== 0)
      axios
        .post(
          url + "/api/Demirbas/DemirbasTutanakListe",
          requestModel
        )
        .then(function(response) {
          try {
            if (response.status === 200) {
              if (response.data.responseCode === 200) {
                console.log(response.data.responseObject);
                setDemirbasData(null);
                setTimeout(() => {
                  setDemirbasData(response.data.responseObject);                  
                }, 500);
              } else if (response.data.responseCode === 401) {

              } else {
                alert(response.data.responseMessage);
                console.log(response.data.responseMessage);
              }
            } else {
              alert(response);
              console.log(response);
            }
          } catch (error) {
            alert(error);
            console.log(error);
          }
        })
        .catch(function(error) {
          alert(error);
          console.log(error);
        });
  };
 

  /**************************************/

  return (
    <Fragment>
      <div style={{ borderBottomStyle: "solid", height: 140 * boyutOran }}>
        <div
          className="boxLeft"
          style={{
            width: 640 * boyutOran,
            height: 120 * boyutOran
          }}
        >
          <img style={{ width: 640 * boyutOran }} src={Baslik} />
        </div>
        <div
          className="boxLeft"
          style={{
            width: 500 * boyutOran,
            height: 100 * boyutOran,
            padding: 10 * boyutOran
          }}
        >         <img
        src={Ekle}
        alt="Ekle"
        style={{
          position: "absolute",
          left: solBosluk + 820 * boyutOran,
          top: 10 * boyutOran,
          height: 105 * boyutOran
        }}
      ></img>
       
      <img
        src={Sil}
        alt="Sil"
        style={{
          position: "absolute",
          left: solBosluk + 960 * boyutOran,
          top: 20 * boyutOran,
          height: 95 * boyutOran
        }}
      ></img>
       <img
        src={Ara}
        alt="Ara"
        style={{
          position: "absolute",
          left: solBosluk + 1090 * boyutOran,
          top: 20 * boyutOran,
          height: 95 * boyutOran
        }}
      ></img>
       <div  style={{
          position: "absolute",
          left: -20 * boyutOran,
          top: 80 * boyutOran,
        }}>
    <MenuWrapper>

    </MenuWrapper>
      </div>
       
        </div>
        <div
        id="divTarihArkasi"
        style={{
          position: "absolute",
          left: solBosluk + 1375 * boyutOran,
          top: 25 * boyutOran,
          color: "white",
          backgroundColor: "black",
          fontSize: 70 * boyutOran,
          width: 320 * boyutOran
        }}
      >
        &nbsp;
      </div>    
    
      </div>
      <div
        className="boxLeft"
        style={{
          width: 400 * boyutOran,
          height: 800 * boyutOran,
          borderRightStyle: "solid"
        }}
      >
          <div style={{width:400 * boyutOran,marginTop:35*boyutOran,
           }}> 
          <span style={{marginTop:45*boyutOran, position: 'absolute',fontWeight:"bolder",marginLeft:35*boyutOran,fontSize:40*boyutOran}}>Zimmet</span>
        <img
          src={Zimmet}
          style={{ width: 150 * boyutOran,float: "right",marginRight:15*boyutOran }}
          
        /> 
      
        </div>  <div style={{clear:"right"}}></div>
          <div style={{width:400 * boyutOran,marginTop:35*boyutOran}}> 
          <span style={{marginTop:45*boyutOran, position: 'absolute',fontWeight:"bolder",marginLeft:35*boyutOran,fontSize:40*boyutOran}}>İade</span>
        <img
          src={Iade}
          style={{ width: 150 * boyutOran,float: "right",marginRight:15*boyutOran }}
          
        /> 
        </div>  <div style={{clear:"right"}}></div>
          <div style={{width:400 * boyutOran,marginTop:35*boyutOran}}>
               <span style={{marginTop:45*boyutOran, position: 'absolute',fontWeight:"bolder",marginLeft:35*boyutOran,fontSize:40*boyutOran}}>Kayıp</span>
        <img
          src={Kayip}
          style={{ width: 150 * boyutOran,float: "right" ,marginRight:15*boyutOran}}
          
        /> 
        </div>  <div style={{clear:"right"}}></div>
          <div style={{width:400 * boyutOran,marginTop:35*boyutOran}}>   
         <span style={{marginTop:45*boyutOran, position: 'absolute',fontWeight:"bolder",marginLeft:35*boyutOran,fontSize:40*boyutOran}}>Hurda</span>
        <img
          src={Hurda}
          style={{ width: 150 * boyutOran,float: "right" ,marginRight:15*boyutOran}}
          
        /> 
        </div>
   
        
     
        
        
      </div>
      <div
        className="boxLeft"
        style={{
          width: 1300 * boyutOran,
          height: 800 * boyutOran,
          textAlign:"Center"
        }}
      >
          <span style={{position:"absolute",fontSize:44*boyutOran,fontWeight:"bolder",left:890*boyutOran}}>TUTANAK FORMU</span>
          <div style={{position:"absolute",textAlign:"left",top:224*boyutOran,fontWeight:"bolder",left:490*boyutOran,fontSize:35*boyutOran}}> 
          <div style={{width:550*boyutOran}}> Tarih: 
          <TextBox
                        placeholder={"Tarih Giriniz"}                       
                        showClearButton={true}
                        style={{width:300*boyutOran,height:30*boyutOran,float:"right"}}
                      />
          </div>
          <div style={{width:550*boyutOran,marginTop:20*boyutOran}}> Zimmete Veren: 
          <TextBox
                        placeholder={"Zimmete Veren Giriniz"}                       
                        showClearButton={true}
                        style={{width:300*boyutOran,height:30*boyutOran,float:"right"}}
                      /></div>
          <div style={{width:550*boyutOran,marginTop:20*boyutOran}}> Zimmeti Alan: <TextBox
                        placeholder={"Zimmeti Alan Giriniz"}                       
                        showClearButton={true}
                        style={{width:300*boyutOran,height:30*boyutOran,float:"right"}}
                      /></div>
          <div style={{width:550*boyutOran,marginTop:20*boyutOran}}> Lokasyon: <TextBox
                        placeholder={"Lokasyon Giriniz"}                       
                        showClearButton={true}
                        style={{width:300*boyutOran,height:30*boyutOran,float:"right"}}
                      /></div>
          <div style={{width:550*boyutOran,marginTop:20*boyutOran}}> Belge No: <TextBox
                        placeholder={"Belge No Giriniz"}                       
                        showClearButton={true}
                        style={{width:300*boyutOran,height:30*boyutOran,float:"right"}}
                      /></div>
       </div>
       <div style={{position:"absolute",textAlign:"left",top:224*boyutOran,fontWeight:"bolder",left:1290*boyutOran,fontSize:35*boyutOran}}> 
       <div style={{width:550*boyutOran,height:220*boyutOran}}> Notlar: <TextBox
                        placeholder={"Notlar Giriniz"}                       
                        showClearButton={true}
                        style={{width:300*boyutOran,height:30*boyutOran,float:"right"}}
                      /></div>
       <div style={{width:550*boyutOran}}> Düzenleyen: <TextBox
                        placeholder={"Düzenleyen Giriniz"}                       
                        showClearButton={true}
                        style={{width:300*boyutOran,height:30*boyutOran,float:"right"}}
                      /></div>
       </div>
        <div style={{width:1440*boyutOran,height:4*boyutOran,backgroundColor:"#000",position:"absolute",top:550*boyutOran}}></div>
        <img
        src={Yeni}
        alt="Yeni"
        style={{
          position: "absolute",
          left: solBosluk + 410 * boyutOran,
          top: 560 * boyutOran,
          height: 35 * boyutOran
        }}
      ></img>
      <img
        src={Cikar}
        alt="Cikar"
        style={{
          position: "absolute",
          left: solBosluk + 465 * boyutOran,
          top: 560 * boyutOran,
          height: 35 * boyutOran
        }}
      ></img>
          
 <DataGrid
          dataSource={loadDemirbasTutanak}
          allowColumnReordering={true}
          showBorders={true}  
          style={{
            position: "absolute",
            left: solBosluk + 400 * boyutOran,
            top: 620 * boyutOran,
            height: 255 * boyutOran
          }}       
        >
      
          <Column dataField={'Tarih'} dataType={'datetime'} caption="Tarih" />  
          <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
          <Paging defaultPageSize={12} />
        </DataGrid> 

      </div>
     
    </Fragment>
  );
};

export default DemirbasTutanakFormu;
