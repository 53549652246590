import React, { useState, useEffect, Fragment } from "react";
import AnaMenu from "../img/AcilirMenu/AnaMenu.png";
import Menut from "../img/AcilirMenu/menut.png";
import MailAt from "../img/AcilirMenu/MailAt.png";
import PDF from "../img/AcilirMenu/PDF.png";
import Senaryo from "../img/AcilirMenu/Senaryo.png";
import WhatsApp from "../img/AcilirMenu/WhatsApp.png";
import Cikis from "../img/AcilirMenu/Cikis.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./style.css";
import "./styles.css";

const Menus = () => {
  const handleAnaMenuClick = () => {
    localStorage.removeItem("MenuId");
    window.location.reload();
  };
  const handleMailAtClick = () => {
    alert("Mail Atma Fonksiyonu Henüz Geliştirilmedi");
  };
  function printDocument() {
    const input = document.getElementById("root");
    html2canvas(input).then(canvas => {
      //const imgData = canvas.toDataURL("image/png");
      //const pdf = new jsPDF();
      //pdf.addImage(imgData, "JPEG", 0, 0);
      //// pdf.output('dataurlnewwindow');
      //pdf.save("BolumMasraf.pdf");

      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("Download.pdf");
    });
  }
  const [auth, setAuth] = useState(true);
  function handleLogout(event) {
    setAuth(false);
    localStorage.removeItem("Token");
    localStorage.removeItem("BolumId");
    localStorage.removeItem("MenuId");
    window.location.reload();
  }
  const handlePDFClick = () => {
    printDocument();
  };
  const handleSenaryoClick = () => {
    localStorage.setItem("MenuId", 1);
    window.location.reload();
  };
  const handleWhatsAppClick = () => {
    alert("WhatsApp'tan link Gönderme Fonksiyonu Henüz Geliştirilmedi");
  };
  /**************************************/
  const [screenwidth, setWidth] = useState(window.innerWidth);
  const [screenheight, setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1704.0;
    var oh = 959.0;
    var oo = ow / oh;
    console.log(o);
    console.log(oo);
    if (oo > o) {
      console.log(h);
      console.log(o / oo);
      h = h * (o / oo);
      o = w / h;
      console.log("oo < o");
      console.log(o);
    } else if (oo < o) {
    }
    setWidth(w);
    setHeight(h);
    setBoyutOran(h / oh);
    setSolBosluk(10);
  };

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  const items = [
    {
      handleClick: { handleWhatsAppClick },
      image: `url(${WhatsApp})`
    },
    {
      handleClick: { handleSenaryoClick },
      image: `url(${Senaryo})`
    },
    {
      handleClick: { handlePDFClick },
      image: `url(${PDF})`
    },
    {
      handleClick: { handleMailAtClick },
      image: `url(${MailAt})`
    },
    {
      handleClick: { handleAnaMenuClick },
      image: `url(${AnaMenu})`
    },
    {
      handleClick: { handleLogout },
      image: `url(${Cikis})`
    }
  ];

  const center = {
    image: `url(${Menut})`
  };

  return (
    <div>
      <div class="nav">
        <ul class="radial-nav">
          <li>
            <img
              src={Senaryo}
              style={{ width: 76 * boyutOran }}
              onClick={handleSenaryoClick}
            ></img>
          </li>
          <li>
            <img
              src={PDF}
              style={{ width: 76 * boyutOran }}
              onClick={handlePDFClick}
            ></img>
          </li>
          <li>
            <img
              src={MailAt}
              style={{ width: 76 * boyutOran }}
              onClick={handleMailAtClick}
            ></img>
          </li>
          <li>
            <img
              src={WhatsApp}
              style={{ width: 76 * boyutOran }}
              onClick={handleWhatsAppClick}
            ></img>
          </li>
          <li>
            <img
              src={Cikis}
              style={{ width: 76 * boyutOran }}
              onClick={handleLogout}
            ></img>
          </li>

          <li class="menu">
            <img
              src={AnaMenu}
              style={{ width: 76 * boyutOran }}
              onClick={handleAnaMenuClick}
            ></img>
          </li>
          <li class="menu">
            <img
              src={AnaMenu}
              style={{ width: 76 * boyutOran }}
              onClick={handleAnaMenuClick}
            ></img>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Menus;
