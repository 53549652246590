import React, { useState, useEffect, Fragment } from "react";
import "../App.css";
import axios from "axios";
import Baslik from "../img/IsGuvenligi/Baslik.png";
import img1 from "../img/IsGuvenligi/1img.png";
import img2 from "../img/IsGuvenligi/2img.png";
import AcilirMenuAsagi from "../AcilirMenu/AcilirMenuAsagi";
import Popover from "@material-ui/core/Popover";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import { BackgroundImage } from "devextreme-react/range-selector";
import MenuWrapper from "../CircleMenu/FullScreenMenu";
const IsGuvenligiDokumanlar = () => {
  const { url } = useApiURL();
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [sezonKod] = useState(localStorage.getItem("SezonKod"));
  const [bolumId] = useState(localStorage.getItem("BolumId"));
  const [senaryoListe, setSenaryoListe] = useState([{}]);
  const [bolumKod] = useState(localStorage.getItem("BolumKod"));
  const [selectedDate, setSelectedDate] = React.useState(new Date(Date.now()));
  /**********Popover*************/
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  function handleEkleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleEkleClose(event) {
    window.location.reload();
  }
  /**********Popover*************/

  useEffect(() => {
    loadSenaryoListe(bolumId, token);
  }, [bolumId, token]);

  function logOut() {
    setToken("");
    localStorage.removeItem("BolumId");
    localStorage.removeItem("MenuId");
    localStorage.removeItem("Token");
    window.location.reload();
  }
  const loadSenaryoListe = (bolumId, token) => {
    var requestModel = {
      bolumId: bolumId,
      Token: token
    };
    axios
      .post(url + "/api/SahneListe/SenaryoListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);
              setSenaryoListe(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  /**************************************/
  const [screenwidth, setWidth] = useState(window.innerWidth);
  const [screenheight, setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1704.0;
    var oh = 959.0;
    var oo = ow / oh;
    console.log(o);
    console.log(oo);
    if (oo > o) {
      console.log(h);
      console.log(o / oo);
      h = h * (o / oo);
      o = w / h;
      console.log("oo < o");
      console.log(o);
    } else if (oo < o) {
    }
    setWidth(w);
    setHeight(h);
    setBoyutOran(h / oh);
    //setSolBosluk((screenwidth - 1700 * (h / oh)) / 2);
    setSolBosluk(0);
  };

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  /**************************************/

  return (
    <div
      id="divEkran"
      style={{
        height: window.innerHeight - 2,
        width: window.innerWidth,
        overflow: "none",
        backgroundColor: "whitesmoke"
      }}
    >
      <img
        src={Baslik}
        alt="Baslik"
        style={{
          position: "absolute",
          left: solBosluk + 0 * boyutOran,
          top: 10 * boyutOran,
          height: 118 * boyutOran
        }}
      ></img>


<div  style={{
          position: "absolute",
          left: 0 * boyutOran,
          top: 80 * boyutOran,
        }}>
    <MenuWrapper>

    </MenuWrapper>
      </div>
      <img
        src={img1}
        alt="img1"
        style={{
          position: "absolute",
          left: solBosluk + 930 * boyutOran,
          top: 10 * boyutOran,
          height: 110 * boyutOran
        }}
        onClick={handleEkleClick}
      ></img>
        <img
        src={img2}
        alt="img2"
        style={{
          position: "absolute",
          left: solBosluk + 1250 * boyutOran,
          top: 10 * boyutOran,
          height: 115 * boyutOran
        }}
      ></img>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleEkleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
      
      </Popover>
  

  
      <div
        id="divCizgi"
        style={{
          position: "absolute",
          left: solBosluk + 0 * boyutOran,
          top: 130 * boyutOran,
          backgroundColor: "#707070",
          width: 1700 * boyutOran,
          height: 5
        }}
      >
        &nbsp;
      </div>
      <div
        id="divTarihArkasi"
        style={{
          position: "absolute",
          left: solBosluk + 1375 * boyutOran,
          top: 25 * boyutOran,
          color: "white",
          backgroundColor: "black",
          fontSize: 70 * boyutOran,
          width: 320 * boyutOran
        }}
      >
        &nbsp;
      </div>

      


    
   
      <div
        style={{
          position: "absolute",
          left: solBosluk + 1380 * boyutOran,
          top: 25 * boyutOran,
          color: "white",

          fontSize: 70 * boyutOran
        }}
      >
        {senaryoListe[0].GunNo}
      </div>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 1450 * boyutOran,
          top: 28 * boyutOran,
          color: "white",
          fontSize: 40 * boyutOran,
          width: 200 * boyutOran,
          textAlign: "center"
        }}
      >
        {senaryoListe[0].Gun}
      </div>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 1450 * boyutOran,
          top: 72 * boyutOran,
          color: "white",
          fontSize: 20 * boyutOran,
          width: 200 * boyutOran,
          textAlign: "center"
        }}
      >
        {senaryoListe[0].Ay} - {senaryoListe[0].Yil}
      </div>
      <Fragment>
   
      <div
        id="divMenuler"
        className="boxLeft"
        style={{
          width: 400 * boyutOran,
          height: 800 * boyutOran,
          borderRightStyle: "solid",
          position:"absolute",top:134*boyutOran,
          fontSize:30*boyutOran,
            textAlign:"center",
        }}
      >
        DÖKÜMANLAR
       
        <div
          style={{
            width: 370 * boyutOran,
            backgroundColor: "#22b573",
            color:"#fff",
            height:70*boyutOran,
            fontSize:24*boyutOran,
            textAlign:"center",borderRadius:17,
            margin:"0 auto",
            marginTop:5*boyutOran
          }}
        
        >
            KİŞİSEL KORUYUCU MALZEME TAAHHÜT VE TESELLÜM FORMU  
        </div>
        <div
          style={{
            width: 370 * boyutOran,
            backgroundColor: "#00a99d",
            color:"#fff",
            height:70*boyutOran,
            fontSize:24*boyutOran,
            textAlign:"center",borderRadius:17,
            margin:"0 auto",      marginTop:5*boyutOran
          }}
        
        >
          (EK2) İŞE GİRİŞ PERİYODİK MUAYENE FORMU  
        </div>
      </div>
      <div
        id="divMenuIcerik"
        className="boxLeft"
        style={{
          width: 1300 * boyutOran,
          height: 800 * boyutOran,
          position:"absolute",top:134*boyutOran,left:420*boyutOran
        }}
      >
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas blandit elit ut dolor posuere finibus. Praesent vel aliquam sem. Sed in pellentesque felis. Sed vitae egestas elit. Phasellus tincidunt magna urna, sed vulputate lectus convallis nec. Maecenas a justo sit amet elit porttitor pulvinar. Suspendisse in massa varius nisl ornare convallis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nullam consectetur id elit sodales auctor. Proin condimentum massa ex, id semper felis sollicitudin vel. Praesent a mauris ac lorem consequat euismod. Aliquam pretium dictum diam, in feugiat justo interdum ut. Nulla varius ac justo ut euismod.

Phasellus mattis massa felis, eu facilisis tortor rhoncus sed. Cras id velit ut mi finibus tempor. Ut ante neque, semper sit amet arcu a, euismod interdum urna. Curabitur nec fermentum tellus. Sed tortor nunc, aliquam nec sagittis eu, pellentesque et nisl. In non lectus viverra, sagittis ex id, convallis metus. Maecenas rutrum lacus quis odio posuere, sed tempus diam ornare. Nam sit amet lorem varius, dictum sapien quis, venenatis lorem. Nam at odio vel tortor elementum venenatis. Quisque bibendum posuere posuere. Ut at arcu hendrerit, sodales arcu in, sagittis eros. Aliquam erat volutpat. Praesent venenatis massa enim, eu fermentum metus egestas id.

Proin rutrum ornare pulvinar. Duis sit amet neque vel nisl posuere posuere ut at urna. Pellentesque non purus eu mi maximus ullamcorper. Ut eu vulputate quam. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas ac libero vel odio molestie consectetur. Phasellus placerat congue elit a pharetra. Vivamus sit amet tempus sem. Cras mollis sagittis ligula quis luctus. Vestibulum accumsan ac quam eget convallis. Integer vitae mi convallis, auctor sapien id, hendrerit ligula.

Fusce neque eros, volutpat vehicula orci eget, egestas ultricies leo. Donec vitae cursus enim. Nulla nisi nulla, aliquam at arcu in, interdum dapibus augue. Nullam blandit vitae urna id blandit. Donec ornare congue nisl, at fringilla leo scelerisque sed. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Quisque luctus neque in eros bibendum, at rutrum nisi tincidunt. Aliquam tristique et lacus quis efficitur. Quisque semper nibh vel mauris ultricies, eget ultricies augue tempor. Sed imperdiet nulla libero, scelerisque gravida lectus condimentum non. Curabitur ac tincidunt lacus. Donec vitae nibh libero. Phasellus placerat, ligula ac volutpat varius, odio leo consequat velit, ut mollis tortor nulla id est.

Praesent feugiat sed arcu in pellentesque. Donec lectus urna, venenatis rutrum rutrum et, consectetur nec sem. Donec nunc lorem, ultrices sed mollis in, tempus a ipsum. Proin in porttitor tellus, ultricies sagittis ligula. Pellentesque sed nunc ante. Duis mattis risus id nibh ornare, eu malesuada tortor euismod. Nunc at ante lacinia, sagittis ligula luctus, eleifend augue. Maecenas vel auctor libero, a hendrerit nulla. Phasellus aliquam varius mauris ut venenatis. In eleifend tellus et condimentum dignissim. Ut placerat tortor eu mi accumsan, ac convallis nisi mattis. Curabitur ornare eget erat a sollicitudin. Vivamus in enim vitae orci elementum malesuada.

Donec sed tincidunt risus, ut consectetur orci. Sed aliquam posuere leo vel rutrum. Mauris quis elit auctor, vulputate arcu sit amet, pharetra leo. Maecenas accumsan rutrum elit, sit amet sollicitudin lectus. Suspendisse ac metus efficitur, mollis nunc sit amet, pretium sapien. Cras ipsum leo, molestie non diam ut, mollis vulputate tellus. Vestibulum eget massa nec odio blandit feugiat. Fusce elit magna, tempus id tortor non, luctus fringilla elit. Etiam nibh lacus, fermentum vel tellus non, congue congue lorem. Sed efficitur maximus quam at cursus. Proin arcu quam, cursus a orci in, commodo fermentum mauris. Aliquam erat volutpat.

Mauris in varius ex, vel dignissim magna. Vestibulum ut ex suscipit dolor lobortis fringilla. Nulla facilisi. In vitae convallis ligula. Integer maximus pulvinar nisl vel accumsan. Aliquam erat volutpat. Nunc tempus congue ligula eu porttitor. Praesent in vehicula libero, quis ultrices nunc. Praesent porta nec nulla vel tempus. Duis suscipit gravida est vel semper.

Cras varius varius dui, lobortis pharetra massa. Nulla facilisi. Nulla scelerisque bibendum lacus. Morbi mollis faucibus dolor, sed eleifend lectus feugiat in. Aliquam congue eros et commodo convallis. Phasellus dui diam, condimentum non quam nec, convallis molestie lorem. In id urna in metus pulvinar egestas eu non nulla. Pellentesque et pulvinar mauris. Vestibulum eu metus vitae lacus iaculis commodo. Maecenas elit tortor, tempus non magna a, varius dictum augue.
      </div>
    </Fragment>
    
    

    </div>
  );
};

export default IsGuvenligiDokumanlar;
