import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import React, { useState, useEffect, Fragment } from "react";
import "../App.css";
import axios from "axios";

import "./style.css";
import "./tab.css";
import ODataStore from "devextreme/data/odata/store";
import { TextBox } from "devextreme-react";
import { SelectBox } from "devextreme-react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel
} from "devextreme-react/data-grid";
import Axios from "axios";

const pageSizes = [10, 25, 50, 100];

const dataSourceOptions = {
  store: new ODataStore({
    url: "https://js.devexpress.com/Demos/SalesViewer/odata/DaySaleDtoes",
    beforeSend: function(request) {
      request.params.startDate = "2018-05-10";
      request.params.endDate = "2018-05-15";
    }
  })
};
const openTab = (evt, tabName) => {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(tabName).style.display = "block";
  evt.currentTarget.className += " active";
};
const KullaniciAyarlari = props => {
  const { url } = useApiURL();
  const [ayarAlanId, setAyarAlanId] = useState(1);
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [projeId] = useState(localStorage.getItem("ProjeId"));
  const [currentHesapPlan, setCurrentHesapPlan] = useState(null);
  const [hesapPlanData, setHesapPlanData] = useState(null);
  const [hesapPlanDataId, setHesapPlanDataId] = useState(null);
  const [hesapPlanDataKurumId, setHesapPlanDataKurumId] = useState(null);
  const [hesapPlanDataProjeId, setHesapPlanDataProjeId] = useState(null);
  const [hesapPlanDataParentId, setHesapPlanDataParentId] = useState(null);
  const [agac, setAgac] = useState([]);

  /***********METODLAR************/

  const loadAgac = () => {
    var requestModel = {
      Id: projeId,
      Token: token
    };
    axios
      .post(url + "/api/AyarlarHesapPlan/Tree", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);

              setAgac(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              alert(response.data.responseMessage);
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  const selectItem = e => {
    setHesapPlanDataKurumId(null);
    setHesapPlanDataProjeId(null);
    setHesapPlanDataParentId(null);
    setHesapPlanDataId(null);
    setHesapPlanData(null);
    setCurrentHesapPlan(null);
    setTimeout(() => {
      setHesapPlanDataKurumId(e.itemData.data.kurumId);
      setHesapPlanDataProjeId(e.itemData.data.projeId);
      setHesapPlanDataParentId(e.itemData.data.parentHesapPlanId);
      if (e.itemData.data.hesapPlanId) {
        setHesapPlanDataId(e.itemData.data.hesapPlanId);
        setHesapPlanData(e.itemData.data);
      } else {
        setHesapPlanDataId(0);
        setHesapPlanData({});
      }
      setCurrentHesapPlan(e.itemData);
    }, 100);
  };

  /***********METODLAR************/

  /**************************************/
  const [screenwidth, setWidth] = useState(window.innerWidth);
  const [screenheight, setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [kullaniciListe, setKullaniciListe] = useState([]);
  const [hesapPlanId] = useState(props.HesapPlanId);

  const loadKullanici = () => {
    var requestModel = {
      Id: projeId,
      Token: token
    };
    if (hesapPlanId !== 0)
      Axios.post(url + "/api/Kullanici/KullaniciListe", requestModel)
        .then(function(response) {
          try {
            if (response.status === 200) {
              if (response.data.responseCode === 200) {
                console.log(response.data.responseObject);
                setKullaniciListe(response.data.responseObject);
              } else if (response.data.responseCode === 401) {
                alert(response.data.responseMessage);
              } else {
                alert(response.data.responseMessage);
                console.log(response.data.responseMessage);
              }
            } else {
              alert(response);
              console.log(response);
            }
          } catch (error) {
            alert(error);
            console.log(error);
          }
        })
        .catch(function(error) {
          alert(error);
          console.log(error);
        });
  };
  var grid = null;

  function selectionChanged(e) {
    setSelectedRowIndex(e.component.getRowIndexByKey(e.selectedRowKeys[0]));
  }
  function editRow() {
    grid.instance.editRow(selectedRowIndex);
    grid.instance.deselectAll();
  }
  function deleteRow() {
    grid.instance.deleteRow(selectedRowIndex);
    grid.instance.deselectAll();
  }

  function addRow() {
    grid.instance.addRow();
  }
  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1704.0;
    var oh = 959.0;
    var oo = ow / oh;
    console.log(o);
    console.log(oo);
    if (oo > o) {
      console.log(h);
      console.log(o / oo);
      h = h * (o / oo);
      o = w / h;
      console.log("oo < o");
      console.log(o);
    } else if (oo < o) {
    }
    setWidth(w);
    setHeight(h);
    setBoyutOran(h / oh);
    setSolBosluk(10);
  };

  useEffect(() => {
    loadAgac();
    loadKullanici();
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  /**************************************/

  return (
    <Fragment>
      <div className={"form"}>
        <div
          className="boxLeft"
          style={{
            height: 1000 * boyutOran,
            width: 300 * boyutOran,
            padding: 10
          }}
        >
          <DataGrid
            id={"grid"}
            dataSource={kullaniciListe}
            ref={ref => (grid = ref)}
            selection={{ mode: "single" }}
            showBorders={true}
            onSelectionChanged={selectionChanged}
          >
            <Column dataField={"kullaniciAd"} caption={"Kullanıcı Adı"} />
          </DataGrid>
          <div
            style={{
              width: 600 * boyutOran,
              height: 300 * boyutOran,
              position: "absolute",
              top: 34 * boyutOran,
              left: 380 * boyutOran
            }}
          >
            <div className={"dx-field"}>
              <div className={"dx-field-label"}>
                Kullanıcı Adı
                <TextBox
                  placeholder={"Kullanıcı Adı Giriniz"}
                  showClearButton={true}
                />
              </div>
            </div>
            <div className={"dx-field"}>
              <div className={"dx-field-label"}>
                Şifre
                <TextBox placeholder={"Şifre Giriniz"} showClearButton={true} />
              </div>
            </div>
            <div className={"dx-field"}>
              <div className={"dx-field-label"}>
                Personel
                <SelectBox />
              </div>
            </div>
          </div>
          <div
            style={{
              width: 600 * boyutOran,
              height: 300 * boyutOran,
              position: "absolute",
              top: 34 * boyutOran,
              left: 690 * boyutOran
            }}
          >
            <div className={"dx-field"}>
              <div className={"dx-field-label"}>
                Mail
                <TextBox placeholder={"Mail Giriniz"} showClearButton={true} />
              </div>
            </div>
            <div className={"dx-field"}>
              <div className={"dx-field-label"}>
                Cep Tel
                <TextBox
                  placeholder={"Cep Tel Giriniz"}
                  showClearButton={true}
                />
              </div>
            </div>
          </div>
          <div
            id={"product-details"}
            style={{
              width: 1000 * boyutOran,
              padding: 5,
              position: "absolute",
              top: 360 * boyutOran,
              left: 340 * boyutOran
            }}
          >
            <div className="tab">
              <button
                className="tablinks"
                onClick={event => openTab(event, "tabHesapPlan")}
              >
                Kullanıcı Grupları
              </button>
              <button
                className="tablinks"
                onClick={event => openTab(event, "tabMasrafKalem")}
              >
                Yetkiler
              </button>
            </div>
            <div
              id="tabHesapPlan"
              className="tabcontent"
              style={{ display: "block" }}
            >
              1
            </div>
            <div id="tabMasrafKalem" className="tabcontent">
              2
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default KullaniciAyarlari;
