import React, { useState, useEffect, Fragment } from "react";
import config from "devextreme/core/config";
import DataGrid, { Column, Editing, Lookup } from "devextreme-react/data-grid";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import Axios from "axios";

config({
  floatingActionButtonConfig: {
    icon: "rowfield",
    position: {
      of: "#grid",
      my: "right bottom",
      at: "right bottom",
      offset: "-16 -16"
    }
  }
});

const MasrafKalemSozlesmeYemek = props => {
  const { url } = useApiURL();
  const [masrafKalemSozlesmeYemekListe] = useState(
    props.masrafKalemSozlesmeYemekListe
  );
  const [tedarikciListe] = useState(props.tedarikciListe);
  const [kdvDurumListe] = useState([
    { id: 1, text: "KDV Hariç" },
    { id: 2, text: "KDV Dahil" },
    { id: 3, text: "Muaf" }
  ]);

  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  var grid = null;

  function selectionChanged(e) {
    setSelectedRowIndex(e.component.getRowIndexByKey(e.selectedRowKeys[0]));
  }
  function editRow() {
    grid.instance.editRow(selectedRowIndex);
    grid.instance.deselectAll();
  }
  function deleteRow() {
    grid.instance.deleteRow(selectedRowIndex);
    grid.instance.deselectAll();
  }

  function addRow() {
    grid.instance.addRow();
  }
  return (
    <Fragment>
      <div>
        <button onClick={addRow}> &#x271C;</button>

        {selectedRowIndex !== undefined && selectedRowIndex !== -1 && (
          <Fragment>
            <button onClick={editRow}> &#x2710;</button>
            <button onClick={deleteRow}> &#x2716;</button>
          </Fragment>
        )}
      </div>

      <div id="divMasrafKalemSozlesmeYemekListe" className={"form"}>
        <DataGrid
          id={"grid"}
          dataSource={masrafKalemSozlesmeYemekListe}
          keyExpr={"masrafKalemSozlesmeYemekId_Guid"}
          ref={ref => (grid = ref)}
          selection={{ mode: "single" }}
          showBorders={true}
          onSelectionChanged={selectionChanged}
        >
          <Column
            dataField={"baslangicTarih"}
            caption={"Başlangıç Tarih"}
            dataType={"date"}
          />
          <Column
            dataField={"bitisTarih"}
            caption={"Bitiş Tarih"}
            dataType={"date"}
          />
          <Column dataField={"tedarikciId"} caption={"Tedarikçi"}>
            <Lookup
              dataSource={tedarikciListe}
              valueExpr={"id"}
              displayExpr={"text"}
            />
          </Column>

          <Column dataField={"tutar"} dataType={"number"} />
          <Column dataField={"kdvOran"} dataType={"number"} />

          <Column dataField={"kdvDurum"} caption={"KDV Durum"}>
            <Lookup
              dataSource={kdvDurumListe}
              valueExpr={"id"}
              displayExpr={"text"}
            />
          </Column>
          <Column dataField={"notlar"} />

          <Editing mode={"popup"}></Editing>
        </DataGrid>
      </div>
    </Fragment>
  );
};

export default MasrafKalemSozlesmeYemek;
