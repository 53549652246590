import React, { useState, useEffect, Fragment } from "react";
import config from "devextreme/core/config";
import DataGrid, { Column, Editing, Lookup } from "devextreme-react/data-grid";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import Axios from "axios";

config({
  floatingActionButtonConfig: {
    icon: "rowfield",
    position: {
      of: "#grid",
      my: "right bottom",
      at: "right bottom",
      offset: "-16 -16"
    }
  }
});

const MasrafKalemSozlesme = props => {
  const { url } = useApiURL();
  const [masrafKalemSozlesmeListe, setMasrafKalemSozlesmeListe] = useState(
    props.masrafKalemSozlesmeListe
  );
  const [kapatmaTipListe, setKapatmaTipListe] = useState([]);
  const [donemTipListe] = useState([
    { donemTipId: 1, donemTipAd: "Sezon" },
    { donemTipId: 2, donemTipAd: "Bölüm" },
    { donemTipId: 3, donemTipAd: "Gün" },
    { donemTipId: 4, donemTipAd: "Ay" },
    { donemTipId: 5, donemTipAd: "Hafta" }
  ]);

  const [token] = useState(localStorage.getItem("Token"));
  const [masrafkalemId] = useState(props.masrafkalemId);

  useEffect(() => {
    //loadKalemSozlesmeListe();
    loadKapatmaTipListe();
  }, [masrafkalemId]);

  const loadKapatmaTipListe = () => {
    var requestModel = {
      Token: token
    };
    Axios.post(url + "/api/MasrafKalemListe/KapatmaTipListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setKapatmaTipListe(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              alert(response.data.responseMessage);
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  var grid = null;

  function selectionChanged(e) {
    setSelectedRowIndex(e.component.getRowIndexByKey(e.selectedRowKeys[0]));
  }
  function editRow() {
    grid.instance.editRow(selectedRowIndex);
    grid.instance.deselectAll();
  }
  function deleteRow() {
    grid.instance.deleteRow(selectedRowIndex);
    grid.instance.deselectAll();
  }

  function addRow() {
    grid.instance.addRow();
  }
  return (
    <Fragment>
      <div>
        <button onClick={addRow}> &#x271C;</button>

        {selectedRowIndex !== undefined && selectedRowIndex !== -1 && (
          <Fragment>
            <button onClick={editRow}> &#x2710;</button>
            <button onClick={deleteRow}> &#x2716;</button>
          </Fragment>
        )}
      </div>

      <div id="divMasrafKalemSozlesmeListe" className={"form"}>
        <DataGrid
          id={"grid"}
          dataSource={masrafKalemSozlesmeListe}
          keyExpr={"masrafKalemSozlesmeId_Guid"}
          ref={ref => (grid = ref)}
          selection={{ mode: "single" }}
          showBorders={true}
          onSelectionChanged={selectionChanged}
        >
          <Column
            dataField={"baslangicTarih"}
            caption={"Başlangıç Tarih"}
            dataType={"date"}
          />
          <Column
            dataField={"bitisTarih"}
            caption={"Bitiş Tarih"}
            dataType={"date"}
          />

          <Column dataField={"donemTip"} caption={"Dönem Tip"}>
            <Lookup
              dataSource={donemTipListe}
              valueExpr={"donemTipId"}
              displayExpr={"donemTipAd"}
            />
          </Column>
          <Column dataField={"donemDeger"} caption={"Dönem"} />

          <Column
            dataField={"kapatmaTipId"}
            caption={"Kapatma Tipi"}
            width={125}
          >
            <Lookup
              dataSource={kapatmaTipListe}
              valueExpr={"id"}
              displayExpr={"text"}
            />
          </Column>
          <Column dataField={"tutar"} dataType={"number"} />
          <Column dataField={"kDVDurum"} dataType={"number"} />
          <Column dataField={"kDVOran"} dataType={"number"} />
          <Column dataField={"stopajOran"} dataType={"number"} />
          <Column dataField={"ajansKomisyonOran"} dataType={"number"} />
          <Column dataField={"ajansKomisyonTutar"} dataType={"number"} />
          <Column dataField={"notlar"} />

          <Editing mode={"popup"}></Editing>
        </DataGrid>
      </div>
    </Fragment>
  );
};

export default MasrafKalemSozlesme;
