import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import React, { useState, useEffect, Fragment } from "react";
import "../App.css";
import "./tab.css";

import axios from "axios";
import { TextBox } from "devextreme-react";
import { Switch } from "devextreme-react/switch";
import { NumberBox } from "devextreme-react/number-box";
import { Button } from "devextreme-react/button";
import MasrafKalem from "./MasrafKalem.js";

const openTab = (evt, tabName) => {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(tabName).style.display = "block";
  evt.currentTarget.className += " active";
};

const HesapPlanDuzenle = props => {
  const { url } = useApiURL();
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [hesapPlanData, setHesapPlanData] = useState(props.data);
  const [hesapPlanDataId, setHesapPlanDataID] = useState(props.dataId);
  const [kurumId] = useState(props.kurumId);
  const [projeId] = useState(props.projeId);
  const [parentId] = useState(props.parentId);

  console.log(props);
  /***********METODLAR************/

  function handleHesapPlanDataChange(evt) {}
  /*select  'function handle'+Name+'Change(data) {    hesapPlanData.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value; handleHesapPlanDataChange(hesapPlanData); }' from syscolumns where id = object_id('HesapPlan')*/
  function handleHesapPlanKodChange(data) {
    hesapPlanData.hesapPlanKod = data.value;
    handleHesapPlanDataChange(hesapPlanData);
  }
  function handleHesapPlanAdChange(data) {
    hesapPlanData.hesapPlanAd = data.value;
    handleHesapPlanDataChange(hesapPlanData);
  }
  function handleOzetteGosterChange(data) {
    hesapPlanData.ozetteGoster = data.value;
    handleHesapPlanDataChange(hesapPlanData);
  }
  function handleGosterimSirasiChange(data) {
    hesapPlanData.gosterimSirasi = data.value;
    handleHesapPlanDataChange(hesapPlanData);
  }

  const addHesapPlan = () => {
    setHesapPlanData(null);
    setTimeout(() => {
      var hp = {};
      hp.degistiren = null;
      hp.degistirme = null;
      hp.ekleme = null;
      hp.ekleyen = null;
      hp.gosterimSirasi = null;
      hp.hesapPlanAd = null;
      hp.hesapPlanId = -1;
      hp.hesapPlanKod = null;
      hp.kurumId = kurumId;
      hp.ozetteGoster = true;
      hp.parentHesapPlanId = parentId;
      hp.projeId = projeId;
      setHesapPlanData(hp);
      setHesapPlanDataID(-1);
    }, 100);
  };

  const saveHesapPlan = () => {
    var text = JSON.stringify(hesapPlanData);
    var requestModel = {
      Id: projeId,
      Value: text,
      Token: token
    };
    axios
      .post(url + "/api/AyarlarHesapPlan/HesapPlanKaydet", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);
              props.loadAgacHandle();
              alert(response.data.responseMessage);
            } else if (response.data.responseCode === 401) {
              alert(response.data.responseMessage);
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  /***********METODLAR************/

  /**************************************/
  const [screenwidth, setWidth] = useState(window.innerWidth);
  const [screenheight, setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1704.0;
    var oh = 959.0;
    var oo = ow / oh;
    console.log(o);
    console.log(oo);
    if (oo > o) {
      console.log(h);
      console.log(o / oo);
      h = h * (o / oo);
      o = w / h;
      console.log("oo < o");
      console.log(o);
    } else if (oo < o) {
    }
    setWidth(w);
    setHeight(h);
    setBoyutOran(h / oh);
    setSolBosluk(10);
  };

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);
  /**************************************/

  return (
    <div id={"product-details"} style={{ width: 1000 * boyutOran, padding: 5 }}>
      <div className="tab">
        <button
          className="tablinks"
          onClick={event => openTab(event, "tabHesapPlan")}
        >
          Hesap Planı
        </button>
        <button
          className="tablinks"
          onClick={event => openTab(event, "tabMasrafKalem")}
        >
          Masraf Kalemleri
        </button>
      </div>
      <div
        id="tabHesapPlan"
        className="tabcontent"
        style={{ display: "block" }}
      >
        <h3>Hesap Planı</h3>
        <div style={{ padding: 10 }}>
          <Button icon="add" onClick={addHesapPlan} />
          {hesapPlanData && hesapPlanDataId && (
            <Button icon="save" onClick={saveHesapPlan} />
          )}
        </div>

        <div className={"form"}>
          <div className={"dx-fieldset"}>
            <div className={"dx-field"}>
              <div className={"dx-field-label"}>Id</div>
              <div className={"dx-field-value"}>
                {hesapPlanData && (
                  <TextBox
                    defaultValue={hesapPlanData.hesapPlanId}
                    disabled={true}
                  />
                )}
              </div>
            </div>
            <div className={"dx-field"}>
              <div className={"dx-field-label"}>Hesap Kod</div>
              <div className={"dx-field-value"}>
                {hesapPlanData && (
                  <TextBox
                    placeholder={"Hesap Kodunu Giriniz"}
                    defaultValue={hesapPlanData.hesapPlanKod}
                    onValueChanged={handleHesapPlanKodChange}
                  />
                )}
              </div>
            </div>
            <div className={"dx-field"}>
              <div className={"dx-field-label"}>Hesap Ad</div>
              <div className={"dx-field-value"}>
                {hesapPlanData && (
                  <TextBox
                    placeholder={"Hesap Adını Giriniz"}
                    defaultValue={hesapPlanData.hesapPlanAd}
                    showClearButton={true}
                    onValueChanged={handleHesapPlanAdChange}
                  />
                )}
              </div>
            </div>
            <div className={"dx-field"}>
              <div className={"dx-field-label"}>Özette Göster</div>
              <div className={"dx-field-value"}>
                {hesapPlanData && (
                  <Switch
                    placeholder={"Özette Gösterilmeli Mi"}
                    value={hesapPlanData.ozetteGoster}
                    onValueChanged={handleOzetteGosterChange}
                  />
                )}
              </div>
            </div>
            <div className={"dx-field"}>
              <div className={"dx-field-label"}>Gösterim Sırası</div>
              <div className={"dx-field-value"}>
                {hesapPlanData && (
                  <NumberBox
                    defaultValue={hesapPlanData.gosterimSirasi}
                    showSpinButtons={true}
                    onValueChanged={handleGosterimSirasiChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="tabMasrafKalem" className="tabcontent">
        <h3>Masraf Kalemleri</h3>
        <MasrafKalem HesapPlanId={hesapPlanData.hesapPlanId}></MasrafKalem>
      </div>
    </div>
  );
};

export default HesapPlanDuzenle;
