import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import React, { useState, useEffect, Fragment } from "react";
import "../App.css";
import "./tab.css";

// import DataSource from "devextreme/data/data_source";
import axios from "axios";
import { TextBox, Lookup, TextArea } from "devextreme-react";
import { Switch } from "devextreme-react/switch";
import { NumberBox } from "devextreme-react/number-box";
import Ara from "../img/Ayarlar/Ara.png";
import Ekle from "../img/Ayarlar/Ekle.png";
import AltEkle from "../img/Ayarlar/AltEkle.png";
import Kaydet from "../img/Ayarlar/Kaydet.png";
import Sil from "../img/Ayarlar/Sil.png";
import Yenile from "../img/Ayarlar/Yenile.png";

// import Field from "./Field.js";
// import Item from "./Item.js";

const openTab = (evt, tabName) => {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(tabName).style.display = "block";
  evt.currentTarget.className += " active";
};

const TedarikciDuzenle = props => {
  const { url } = useApiURL();
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [parentTedarikciId, setParentTedarikciId] = useState(null);
  const [tedarikciTipListe] = useState([
    { id: 0, text: "Firma" },
    { id: 1, text: "Ajans" },
    { id: 2, text: "Menajer" }
  ]);

  const [kaydetAktif, setKaydetAktif] = useState(null);
  const [ilListe, setIlListe] = useState([{ id: 0, text: "---" }]);
  const [ilceListe, setIlceListe] = useState([{ id: 0, text: "---" }]);

  const [tedarikciData, setTedarikciData] = useState(null);
  const [tedarikciDataId] = useState(props.TedarikciId);
  const [simdikiTedarikciGrubu, setSimdikiTedarikciGrubu] = useState(null);

  const [projeId] = useState(props.projeId);
  const [kurumId] = useState(props.kurumId);

  useEffect(() => {
    loadIlListe();
    loadIlceListe();
    setTimeout(() => {
      loadTedarikci();
    }, 100);
  }, [token, tedarikciDataId]);
  function logOut() {
    // setToken("");
    // localStorage.removeItem("BolumId");
    // localStorage.removeItem("MenuId");
    // localStorage.removeItem("Token");
    // window.location.reload();
  }
  const refreshNode = () => {
    props.refreshTree();
  };
  const loadTedarikci = () => {
    var requestModel = {
      Id: tedarikciDataId,
      Field: "TedarikciId",
      Token: token
    };

    if (tedarikciDataId !== 0)
      axios
        .post(url + "/api/TedarikciListe/TedarikciListeAyrintiBy", requestModel)
        .then(function(response) {
          try {
            if (response.status === 200) {
              if (response.data.responseCode === 200) {
                setSimdikiTedarikciGrubu(null);
                setTedarikciData(null);
                setTimeout(() => {
                  if (response.data.responseObject.length > 0) {
                    setParentTedarikciId(
                      response.data.responseObject[0].parentTedarikciId
                    );
                    setTedarikciData(response.data.responseObject[0]);
                    setSimdikiTedarikciGrubu(
                      response.data.responseObject[0].tedarikciGrubu
                    );
                  }
                }, 10);
              } else if (response.data.responseCode === 401) {
                logOut();
              } else {
                alert(response.data.responseMessage);
                console.log(response.data.responseMessage);
              }
            } else {
              alert(response);
              console.log(response);
            }
          } catch (error) {
            alert(error);
            console.log(error);
          }
        })
        .catch(function(error) {
          alert(error);
          console.log(error);
        });
  };

  const loadIlListe = () => {
    var localIlListe = {};
    if (localStorage.getItem("IlListe") !== null)
      localIlListe = JSON.parse(localStorage["IlListe"]);

    if (localIlListe && localIlListe.iller) {
      setIlListe(localIlListe.iller);
      return;
    }

    var requestModel = {
      Token: token,
      Id: projeId
    };

    axios
      .post(url + "/api/TedarikciListe/IlListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              localStorage["IlListe"] = JSON.stringify({
                iller: response.data.responseObject,
                guncelleme: Date.now()
              });

              setIlListe(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadIlceListe = ilId => {
    var requestModel = {
      Token: token,
      Id: ilId
    };

    axios
      .post(url + "/api/TedarikciListe/IlceListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setIlceListe(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  /***********METODLAR************/

  function handleTedarikciDataBeforeChange(data) {
    if (tedarikciData === null)
      setTedarikciData({ projeId: projeId, kurumId: kurumId });
  }
  function handleTedarikciDataChange(data) {
    setKaydetAktif(1);
  }
  /*select  'function handleTedarikci'+Name+'Change(data) {    tedarikciData.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value; handleTedarikciDataChange(tedarikciData); }' from syscolumns where id = object_id('Tedarikci') and name not like '%Siline%'*/

  function handleTedarikciAdresChange(data) {
    tedarikciData.adres = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciAjansIdChange(data) {
    tedarikciData.ajansId = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciDegistirenChange(data) {
    tedarikciData.degistiren = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciDegistirmeChange(data) {
    tedarikciData.degistirme = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciEklemeChange(data) {
    tedarikciData.ekleme = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciEkleyenChange(data) {
    tedarikciData.ekleyen = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciEpostaChange(data) {
    tedarikciData.eposta = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciFaxChange(data) {
    tedarikciData.fax = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciGSMChange(data) {
    tedarikciData.gSM = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciIlceIdChange(data) {
    tedarikciData.ilceId = data.value.id;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciIlIdChange(data) {
    tedarikciData.ilId = data.value.id;
    handleTedarikciDataChange(tedarikciData);
    loadIlceListe(tedarikciData.ilId);
  }
  function handleTedarikciKurumIdChange(data) {
    tedarikciData.kurumId = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciMenajerChange(data) {
    tedarikciData.menajer = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciNotlarChange(data) {
    tedarikciData.notlar = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciProjeIdChange(data) {
    tedarikciData.projeId = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciSilindiChange(data) {
    tedarikciData.silindi = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciTedarikciAdChange(data) {
    tedarikciData.tedarikciAd = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciTedarikciGrubuChange(data) {
    tedarikciData.tedarikciGrubu = data.value.id;
    setSimdikiTedarikciGrubu(data.value.id);
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciTedarikciIdChange(data) {
    tedarikciData.tedarikciId = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciTedarikciKodChange(data) {
    tedarikciData.tedarikciKod = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciTedarikciUnvanChange(data) {
    tedarikciData.tedarikciUnvan = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciTelefonChange(data) {
    tedarikciData.telefon = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciVergiDairesiChange(data) {
    tedarikciData.vergiDairesi = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  function handleTedarikciVergiNoChange(data) {
    tedarikciData.vergiNo = data.value;
    handleTedarikciDataChange(tedarikciData);
  }
  /*select  'function handleTedarikci'+Name+'Change(data) {    tedarikciData.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value; handleTedarikciDataChange(tedarikciData); }' from syscolumns where id = object_id('Tedarikci') and name not like '%Siline%'*/

  function handleMenajerBeforeChange() {
    if (tedarikciData.menajer === null) tedarikciData.menajer = {};
  }

  function handleMenajerChange(data) {
    console.log(data);
  }

  /*select  'function handleMenajer'+Name+'Change(data) {  handleMenajerBeforeChange();  tedarikciData.menajer.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleMenajerChange(tedarikciData.menajer); }' from syscolumns where id = object_id('Menajer') */
  function handleMenajerDegistirenChange(data) {
    handleMenajerBeforeChange();
    tedarikciData.menajer.degistiren = data.value;
    handleMenajerChange(tedarikciData.menajer);
  }
  function handleMenajerDegistirmeChange(data) {
    handleMenajerBeforeChange();
    tedarikciData.menajer.degistirme = data.value;
    handleMenajerChange(tedarikciData.menajer);
  }
  function handleMenajerEklemeChange(data) {
    handleMenajerBeforeChange();
    tedarikciData.menajer.ekleme = data.value;
    handleMenajerChange(tedarikciData.menajer);
  }
  function handleMenajerEkleyenChange(data) {
    handleMenajerBeforeChange();
    tedarikciData.menajer.ekleyen = data.value;
    handleMenajerChange(tedarikciData.menajer);
  }
  function handleMenajerKurumIdChange(data) {
    handleMenajerBeforeChange();
    tedarikciData.menajer.kurumId = data.value.id;
    handleMenajerChange(tedarikciData.menajer);
  }
  function handleMenajerMenajerAdChange(data) {
    handleMenajerBeforeChange();
    tedarikciData.menajer.menajerAd = data.value;
    handleMenajerChange(tedarikciData.menajer);
  }
  function handleMenajerMenajerIdChange(data) {
    handleMenajerBeforeChange();
    tedarikciData.menajer.menajerId = data.value.id;
    handleMenajerChange(tedarikciData.menajer);
  }
  function handleMenajerProjeIdChange(data) {
    handleMenajerBeforeChange();
    tedarikciData.menajer.projeId = data.value.id;
    handleMenajerChange(tedarikciData.menajer);
  }
  function handleMenajerSilindiChange(data) {
    handleMenajerBeforeChange();
    tedarikciData.menajer.silindi = data.value;
    handleMenajerChange(tedarikciData.menajer);
  }
  /*select  'function handleMenajer'+Name+'Change(data) {  handleMenajerBeforeChange();  tedarikciData.menajer.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleMenajerChange(tedarikciData.menajer); }' from syscolumns where id = object_id('Menajer') */

  function handleAjansBeforeChange() {
    if (tedarikciData.ajans === null) tedarikciData.ajans = {};
  }
  function handleAjansChange(data) {
    console.log(data);
  }
  /* select  'function handleAjans'+Name+'Change(data) {  handleAjansBeforeChange();  tedarikciData.ajans.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleAjansChange(tedarikciData.ajans); }' from syscolumns where id = object_id('Ajans') */

  function handleAjansAjansAdChange(data) {
    handleAjansBeforeChange();
    tedarikciData.ajans.ajansAd = data.value;
    handleAjansChange(tedarikciData.ajans);
  }
  function handleAjansAjansIdChange(data) {
    handleAjansBeforeChange();
    tedarikciData.ajans.ajansId = data.value.id;
    handleAjansChange(tedarikciData.ajans);
  }
  function handleAjansDegistirenChange(data) {
    handleAjansBeforeChange();
    tedarikciData.ajans.degistiren = data.value;
    handleAjansChange(tedarikciData.ajans);
  }
  function handleAjansDegistirmeChange(data) {
    handleAjansBeforeChange();
    tedarikciData.ajans.degistirme = data.value;
    handleAjansChange(tedarikciData.ajans);
  }
  function handleAjansEklemeChange(data) {
    handleAjansBeforeChange();
    tedarikciData.ajans.ekleme = data.value;
    handleAjansChange(tedarikciData.ajans);
  }
  function handleAjansEkleyenChange(data) {
    handleAjansBeforeChange();
    tedarikciData.ajans.ekleyen = data.value;
    handleAjansChange(tedarikciData.ajans);
  }
  function handleAjansKurumIdChange(data) {
    handleAjansBeforeChange();
    tedarikciData.ajans.kurumId = data.value.id;
    handleAjansChange(tedarikciData.ajans);
  }
  function handleAjansProjeIdChange(data) {
    handleAjansBeforeChange();
    tedarikciData.ajans.projeId = data.value.id;
    handleAjansChange(tedarikciData.ajans);
  }
  function handleAjansSilindiChange(data) {
    handleAjansBeforeChange();
    tedarikciData.ajans.silindi = data.value;
    handleAjansChange(tedarikciData.ajans);
  }
  /* select  'function handleAjans'+Name+'Change(data) {  handleAjansBeforeChange();  tedarikciData.ajans.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleAjansChange(tedarikciData.ajans); }' from syscolumns where id = object_id('Ajans') */

  const addTedarikci = () => {
    setTedarikciData(null);
    loadTedarikciYeni(parentTedarikciId);
  };
  const addTedarikciAltSeviye = () => {
    setTedarikciData(null);
    loadTedarikciYeni(tedarikciDataId);
  };
  const loadTedarikciYeni = yeniParentTedarikciId => {
    setKaydetAktif(null);
    var requestModel = {
      Token: token,
      Id: projeId
    };

    axios
      .post(url + "/api/TedarikciListe/TedarikciYeni", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setTimeout(() => {
                response.data.responseObject.parentTedarikciId = yeniParentTedarikciId;
                console.log(response.data.responseObject);
                setTedarikciData(response.data.responseObject);
              }, 10);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const handleSilTedarikci = () => {
    tedarikciData.silindi = true;
    saveTedarikci();
  };
  const saveTedarikci = () => {
    setKaydetAktif(null);

    var text = JSON.stringify(tedarikciData);
    var requestModel = {
      Id: projeId,
      Value: text,
      Token: token
    };
    axios
      .post(url + "/api/TedarikciListe/TedarikciKaydet", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);
              //props.loadAgacHandle();
              refreshNode();
              alert(response.data.responseMessage);
            } else if (response.data.responseCode === 401) {
              alert(response.data.responseMessage);
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  /***********METODLAR************/

  /**************************************/
  const [screenwidth, setWidth] = useState(window.innerWidth);
  const [screenheight, setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1704.0;
    var oh = 959.0;
    var oo = ow / oh;
    console.log(o);
    console.log(oo);
    if (oo > o) {
      console.log(h);
      console.log(o / oo);
      h = h * (o / oo);
      o = w / h;
      console.log("oo < o");
      console.log(o);
    } else if (oo < o) {
    }
    setWidth(w);
    setHeight(h);
    setBoyutOran(h / oh);
    setSolBosluk(10);
  };

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);
  /**************************************/

  let ilItemId =
    tedarikciData === null
      ? 0
      : tedarikciData.il === null
      ? 0
      : tedarikciData.il.ilId;
  let ilItem = ilListe.find(item => item.id === ilItemId);

  let ilceItemId =
    tedarikciData === null
      ? 0
      : tedarikciData.ilce === null
      ? 0
      : tedarikciData.ilce.ilceId;
  let ilceItem = ilceListe.find(item => item.id === ilceItemId);

  let tedarikciTipItemId =
    tedarikciData === null
      ? 0
      : tedarikciData.tedarikciGrubu === null
      ? 0
      : tedarikciData.tedarikciGrubu;
  let tedarikciTipItem = tedarikciTipListe.find(
    item => item.id === tedarikciTipItemId
  );

  const yenileClick = () => {
    window.location.reload();
  };

  return (
    <div id={"product-details"} style={{ width: 1000 * boyutOran, padding: 5 }}>
      <div style={{ padding: 10 }}>
        <div
          style={{
            position: "absolute",
            top: -130 * boyutOran,
            left: 300 * boyutOran,
            width: 600 * boyutOran,
            height: 90 * boyutOran,
            padding: 10 * boyutOran
          }}
        >
          <div
            className="boxLeft"
            style={{
              width: 85 * boyutOran
            }}
          >
            <img
              style={{
                width: 75 * boyutOran
              }}
              src={Ara}
            />
            &nbsp;&nbsp;{" "}
          </div>

          <div
            className="boxLeft"
            style={{
              width: 85 * boyutOran
            }}
          >
            <img
              style={{
                width: 75 * boyutOran
              }}
              title="Aynı Seviye Olarak Ekle"
              src={AltEkle}
              onClick={addTedarikci}
            />
            &nbsp;&nbsp;{" "}
          </div>

          <div
            className="boxLeft"
            style={{
              width: 85 * boyutOran
            }}
          >
            <img
              style={{
                width: 75 * boyutOran
              }}
              title="Alt Seviye Olarak Ekle"
              src={Ekle}
              onClick={addTedarikciAltSeviye}
            />
            &nbsp;&nbsp;
          </div>
          <div
            className="boxLeft"
            style={{
              width: 85 * boyutOran
            }}
          >
            {kaydetAktif && (
              <img
                style={{
                  width: 75 * boyutOran
                }}
                onClick={saveTedarikci}
                title="Değiştirilmiş Olan Masrafa Kalamini Kaydeder"
                src={Kaydet}
              />
            )}
            &nbsp;&nbsp;
          </div>

          <div
            className="boxLeft"
            style={{
              width: 85 * boyutOran
            }}
          >
            <img
              style={{
                width: 75 * boyutOran
              }}
              src={Yenile}
              onClick={yenileClick}
            />
            &nbsp;&nbsp;
          </div>
          <div
            className="boxLeft"
            style={{
              width: 85 * boyutOran
            }}
          >
            {tedarikciData && tedarikciData.silindi === false && (
              <img
                style={{
                  width: 75 * boyutOran
                }}
                src={Sil}
                onClick={handleSilTedarikci}
              />
            )}
            &nbsp;&nbsp;
          </div>
        </div>
      </div>
      <div className={"form"}>
        <div
          className={"dx-fieldset"}
          style={{ width: 400 * boyutOran, float: "left" }}
        >
          <div id="tedarikciId" className={"dx-field"}>
            <div className={"dx-field-label"}>Id</div>
            <div className={"dx-field-value"}>
              {tedarikciData && <span>{tedarikciData.tedarikciId}</span>}
            </div>
          </div>
          <div id="tedarikciKod" className={"dx-field"}>
            <div className={"dx-field-label"}>Tedarikçi Kod</div>
            <div className={"dx-field-value"}>
              {tedarikciData && (
                <TextBox
                  placeholder={"Tedarikçi Kodunu Giriniz"}
                  defaultValue={tedarikciData.tedarikciKod}
                  onValueChanged={handleTedarikciTedarikciKodChange}
                />
              )}
            </div>
          </div>
          <div id="tedarikciAd" className={"dx-field"}>
            <div className={"dx-field-label"}>Tedarikçi Ad</div>
            <div className={"dx-field-value"}>
              {tedarikciData && (
                <TextBox
                  placeholder={"Tedarikçi Adını Giriniz"}
                  defaultValue={tedarikciData.tedarikciAd}
                  showClearButton={true}
                  onValueChanged={handleTedarikciTedarikciAdChange}
                />
              )}
            </div>
          </div>
          <div id="tedarikciUnvan" className={"dx-field"}>
            <div className={"dx-field-label"}>Tedarikçi Ünvan</div>
            <div className={"dx-field-value"}>
              {tedarikciData && (
                <TextBox
                  placeholder={"Tedarikçi Ünvan Giriniz"}
                  defaultValue={tedarikciData.tedarikciUnvan}
                  showClearButton={true}
                  onValueChanged={handleTedarikciTedarikciUnvanChange}
                />
              )}
            </div>
          </div>
          <div id="Il" className={"dx-field"}>
            <div className={"dx-field-label"}>İl</div>
            <div className={"dx-field-value"}>
              {tedarikciData && (
                <Lookup
                  defaultValue={ilItem}
                  items={ilListe}
                  dataSource={ilListe}
                  showPopupTitle={false}
                  displayExpr={"text"}
                  onValueChanged={handleTedarikciIlIdChange}
                />
              )}
            </div>
          </div>
          <div id="Ilce" className={"dx-field"}>
            <div className={"dx-field-label"}>İlçe</div>
            <div className={"dx-field-value"}>
              {tedarikciData && (
                <Lookup
                  defaultValue={ilceItem}
                  items={ilceListe}
                  dataSource={ilceListe}
                  showPopupTitle={false}
                  displayExpr={"text"}
                  onValueChanged={handleTedarikciIlceIdChange}
                />
              )}
            </div>
          </div>
          <div id="tedarikciTipItem" className={"dx-field"}>
            <div className={"dx-field-label"}>Tedarikci Grubu</div>
            <div className={"dx-field-value"}>
              {tedarikciData && (
                <Lookup
                  defaultValue={tedarikciTipItem}
                  items={tedarikciTipListe}
                  dataSource={tedarikciTipListe}
                  showPopupTitle={false}
                  displayExpr={"text"}
                  onValueChanged={handleTedarikciTedarikciGrubuChange}
                  //fieldRender={Field}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className={"dx-fieldset"}
          style={{ width: 400 * boyutOran, float: "left" }}
        >
          <div id="telefon" className={"dx-field"}>
            <div className={"dx-field-label"}>Telefon</div>
            <div className={"dx-field-value"}>
              {tedarikciData && (
                <TextBox
                  placeholder={"Telefon Giriniz"}
                  defaultValue={
                    tedarikciData.telefon === null ? "" : tedarikciData.telefon
                  }
                  showClearButton={true}
                  onValueChanged={handleTedarikciTelefonChange}
                />
              )}
            </div>
          </div>
          <div id="gsm" className={"dx-field"}>
            <div className={"dx-field-label"}>GSM</div>
            <div className={"dx-field-value"}>
              {tedarikciData && (
                <TextBox
                  placeholder={"GSM Giriniz"}
                  defaultValue={tedarikciData.gsm}
                  showClearButton={true}
                  onValueChanged={handleTedarikciGSMChange}
                />
              )}
            </div>
          </div>
          <div id="Email" className={"dx-field"}>
            <div className={"dx-field-label"}>Email</div>
            <div className={"dx-field-value"}>
              {tedarikciData && (
                <TextBox
                  placeholder={"Email Giriniz"}
                  defaultValue={tedarikciData.eposta}
                  showClearButton={true}
                  onValueChanged={handleTedarikciEpostaChange}
                />
              )}
            </div>
          </div>
          <div id="fax" className={"dx-field"}>
            <div className={"dx-field-label"}>Fax</div>
            <div className={"dx-field-value"}>
              {tedarikciData && (
                <TextBox
                  placeholder={"Fax Giriniz"}
                  defaultValue={tedarikciData.fax}
                  showClearButton={true}
                  onValueChanged={handleTedarikciFaxChange}
                />
              )}
            </div>
          </div>
          <div id="adres" className={"dx-field"}>
            <div className={"dx-field-label"}>Adres</div>
            <div className={"dx-field-value"}>
              {tedarikciData && (
                <TextArea
                  placeholder={"Adres Giriniz"}
                  defaultValue={tedarikciData.adres}
                  onValueChanged={handleTedarikciAdresChange}
                  style={{ height: 120 * boyutOran }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div style={{ clear: "left" }}></div>
      <Fragment>
        <div className="tab">
          {tedarikciData &&
            (simdikiTedarikciGrubu === 0 ? null : simdikiTedarikciGrubu) &&
            (simdikiTedarikciGrubu === 1 || simdikiTedarikciGrubu === 2) && (
              <button
                className="tablinks"
                onClick={event => openTab(event, "tabMasrafTip")}
              >
                Detaylar
              </button>
            )}
          <button
            className="tablinks"
            onClick={event => openTab(event, "Vergi")}
          >
            Vergi Bilgileri
          </button>
        </div>
        {tedarikciData &&
          (simdikiTedarikciGrubu === 0 ? null : simdikiTedarikciGrubu) &&
          (simdikiTedarikciGrubu === 1 || simdikiTedarikciGrubu === 2) && (
            <div
              id="tabMasrafTip"
              className="tabcontent"
              style={{ display: "block" }}
            >
              {simdikiTedarikciGrubu === 0 && <h3>Ayrıntı</h3>}
              {simdikiTedarikciGrubu === 1 && (
                <div id="Ajans" className={"form"}>
                  <div className={"dx-fieldset"}>
                    <div className={"dx-fieldset-header"}>Ajans</div>
                    <div id="AjansAd" className={"dx-field"}>
                      <div className={"dx-field-label"}>Ajans Ad</div>
                      <div className={"dx-field-value"}>
                        <TextBox
                          placeholder={"Ajans Adı Giriniz"}
                          defaultValue={
                            tedarikciData.ajans
                              ? tedarikciData.ajans.ajansAd
                              : ""
                          }
                          showClearButton={true}
                          onValueChanged={handleAjansAjansAdChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {simdikiTedarikciGrubu === 2 && (
                <div id="Menajer" className={"form"}>
                  <div className={"dx-fieldset"}>
                    <div className={"dx-fieldset-header"}>Menajer</div>
                    <div id="MenajerAd" className={"dx-field"}>
                      <div className={"dx-field-label"}>Menajer Ad</div>
                      <div className={"dx-field-value"}>
                        <TextBox
                          placeholder={"Menajer Adı Giriniz"}
                          defaultValue={
                            tedarikciData.menajer
                              ? tedarikciData.menajer.menajerAd
                              : ""
                          }
                          showClearButton={true}
                          onValueChanged={handleMenajerMenajerAdChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

        <div
          id="Vergi"
          className="tabcontent"
          style={
            simdikiTedarikciGrubu === 1 || simdikiTedarikciGrubu === 2
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <div id="Ajans" className={"form"}>
            <div className={"dx-fieldset"}>
              <div id="AjansAd" className={"dx-field"}>
                <div className={"dx-field-label"}>Vergi Dairesi</div>
                <div className={"dx-field-value"}>
                  {tedarikciData && (
                    <TextBox
                      placeholder={"Vergi Dairesi Giriniz"}
                      defaultValue={tedarikciData.vergiDairesi}
                      showClearButton={true}
                      onValueChanged={handleTedarikciVergiDairesiChange}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={"dx-fieldset"}>
              <div id="AjansAd" className={"dx-field"}>
                <div className={"dx-field-label"}>Vergi No</div>
                <div className={"dx-field-value"}>
                  {tedarikciData && (
                    <TextBox
                      placeholder={"Vergi No Giriniz"}
                      defaultValue={tedarikciData.vergiNo}
                      showClearButton={true}
                      onValueChanged={handleTedarikciVergiNoChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>

      <div className={"form"}>
        <div className={"dx-fieldset"}>
          <div id="notlar">
            <div className={"dx-field-label"}>Notlar</div>
            <div className={"dx-field-value"}>
              {tedarikciData && (
                <TextArea
                  placeholder={"Not Giriniz"}
                  defaultValue={tedarikciData.notlar}
                  onValueChanged={handleTedarikciNotlarChange}
                  style={{ height: 120 * boyutOran, width: 680 * boyutOran }}
                />
              )}
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default TedarikciDuzenle;
