import React, { useState, createContext } from "react";

const AuthContext = createContext(false);

function AuthProvider(props) {
  const isCredentialsExist =
    localStorage.getItem("Token") !== null ? true : false;
  const [isAuth, setIsAuth] = useState(isCredentialsExist);

  return (
    <AuthContext.Provider
      value={{ isAuth, updateIsAuth: value => setIsAuth(value) }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
