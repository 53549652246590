import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import React, { useState, useEffect, Fragment } from "react";
import "../App.css";
import axios from "axios";
import { TreeView } from "devextreme-react";
import "./style.css";
import "./tab.css";
import HesapPlanDuzenle from "./HesapPlanDuzenle.js";

const HesapPlani = () => {
  const { url } = useApiURL();
  const [ayarAlanId, setAyarAlanId] = useState(1);
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [projeId] = useState(localStorage.getItem("ProjeId"));
  const [currentHesapPlan, setCurrentHesapPlan] = useState(null);
  const [hesapPlanData, setHesapPlanData] = useState(null);
  const [hesapPlanDataId, setHesapPlanDataId] = useState(null);
  const [hesapPlanDataKurumId, setHesapPlanDataKurumId] = useState(null);
  const [hesapPlanDataProjeId, setHesapPlanDataProjeId] = useState(null);
  const [hesapPlanDataParentId, setHesapPlanDataParentId] = useState(null);
  const [agac, setAgac] = useState([]);

  /***********METODLAR************/

  const loadAgac = () => {
    var requestModel = {
      Id: projeId,
      Token: token
    };
    axios
      .post(url + "/api/AyarlarHesapPlan/Tree", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);

              setAgac(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              alert(response.data.responseMessage);
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  const selectItem = e => {
    setHesapPlanDataKurumId(null);
    setHesapPlanDataProjeId(null);
    setHesapPlanDataParentId(null);
    setHesapPlanDataId(null);
    setHesapPlanData(null);
    setCurrentHesapPlan(null);
    setTimeout(() => {
      setHesapPlanDataKurumId(e.itemData.data.kurumId);
      setHesapPlanDataProjeId(e.itemData.data.projeId);
      setHesapPlanDataParentId(e.itemData.data.parentHesapPlanId);
      if (e.itemData.data.hesapPlanId) {
        setHesapPlanDataId(e.itemData.data.hesapPlanId);
        setHesapPlanData(e.itemData.data);
      } else {
        setHesapPlanDataId(0);
        setHesapPlanData({});
      }
      setCurrentHesapPlan(e.itemData);
    }, 100);
  };

  /***********METODLAR************/

  /**************************************/
  const [screenwidth, setWidth] = useState(window.innerWidth);
  const [screenheight, setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1704.0;
    var oh = 959.0;
    var oo = ow / oh;
    console.log(o);
    console.log(oo);
    if (oo > o) {
      console.log(h);
      console.log(o / oo);
      h = h * (o / oo);
      o = w / h;
      console.log("oo < o");
      console.log(o);
    } else if (oo < o) {
    }
    setWidth(w);
    setHeight(h);
    setBoyutOran(h / oh);
    setSolBosluk(10);
  };

  useEffect(() => {
    loadAgac();
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  /**************************************/

  return (
    <Fragment>
      <div className={"form"}>
        <div
          className="boxLeft"
          style={{
            height: 1000 * boyutOran,
            width: 300 * boyutOran,
            padding: 10
          }}
        >
          <TreeView
            id={"simple-treeview"}
            items={agac}
            onItemClick={selectItem}
          />
        </div>
        {hesapPlanData && (
          <Fragment>
            <HesapPlanDuzenle
              data={hesapPlanData}
              dataId={hesapPlanDataId}
              kurumId={hesapPlanDataKurumId}
              projeId={hesapPlanDataProjeId}
              parentId={hesapPlanDataId}
              loadAgacHandle={loadAgac}
            ></HesapPlanDuzenle>
          </Fragment>
        )}

        {currentHesapPlan &&
          currentHesapPlan.data &&
          currentHesapPlan.data.simge && (
            <div>
              <img
                src={"data:image/jpg;base64," + currentHesapPlan.data.simge}
              />
            </div>
          )}
      </div>
    </Fragment>
  );
};

export default HesapPlani;
