import React from "react";

import Login from "./Login/Login";
import BolumSecim from "./BolumSecim/BolumSecim";
import AnaMenu from "./AkisSecim/AnaMenu";
import SahneDokumu from "./SahneDokumu/SahneDokumu";
import IsProgramiListe from "./IsProgramiListe/IsProgramiListe";
import IsProgrami from "./IsProgrami/IsProgrami";
import BolumMasrafKalem from "./BolumMasrafKalem/BolumMasrafKalem";
import Senaryo from "./Senaryo/Senaryo";
import Ayarlar from "./Ayarlar/Ayarlar";
import IsGuvenligiDokumanlar from "./IsGuvenligi/IsGuvenligiDokumanlar";
import Ongoru from "./OnGoru/Ongoru";
import DemirbasEkle from "./Demirbas/DemirbasTutanakFormu";
import MasrafFisleri from "./MasrafFisleri/MasrafFisleri";
import { BrowserRouter, Route, Switch } from "react-router-dom";

const App = () => {
  // <BrowserRouter>
  //   <Switch>

  //   </Switch>
  // </BrowserRouter>
  if (localStorage.getItem("Token") === null) {
    return <Login />;
  } else if (localStorage.getItem("BolumId") === null) {
    return <BolumSecim />;
  } else if (localStorage.getItem("MenuId") !== null) {
    switch (localStorage.getItem("MenuId")) {
      case "1":
        return <Senaryo />;
      case "2":
        return <SahneDokumu />;
      case "3":
        if (localStorage.getItem("IsProgramiBaslikId") === null)
          return <IsProgramiListe />;
        else return <IsProgrami />;
      case "4":
        return <Ongoru />;
      case "5":
        return <MasrafFisleri />;
      case "6":
        return <BolumMasrafKalem ongorulen={true} gerceklesen={true} />;
      case "7":
        return <DemirbasEkle />;
      case "8":
        return <IsGuvenligiDokumanlar />;
      case "0":
        return (
          <div>
            <Ayarlar></Ayarlar>
          </div>
        );
      default:
        break;
    }
  } else {
    return <AnaMenu />;
  }
};

export default App;
