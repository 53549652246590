import React, { useState, useEffect } from "react";
//import TreeView from "devextreme-react/tree-view";
//import TreeView from "../CustomComponents/TreeView";

import "whatwg-fetch";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import { useWindowResize } from "../Custom-Hooks/useWindowResize.js";
import MasrafKalemDuzenle from "./MasrafKalemDuzenle.js";
import DataTreeView from "../CustomComponents/DataTreeView";

const MasrafKalemListe = props => {
  const { url } = useApiURL();
  const { screenwidth, screenheight } = useWindowResize();
  const [projeId] = useState(localStorage.getItem("ProjeId"));
  const [kurumId] = useState(localStorage.getItem("KurumId"));
  const [currentMasrafKalemId, setCurrentMasrafKalemId] = useState(null);
  const [rootValue, setRootValue] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState(null);

  const createChildren = () => {
    console.trace();
    let ep =
      url + "/api/MasrafKalemListe/GetMasrafKalemDetayTree?projeId=" + projeId;
    return fetch(ep)
      .then(response => response.json())
      .catch(() => {
        throw "Data Loading Error";
      });
  };
  const selectItem = (e, i) => {
    setCurrentMasrafKalemId(null);

    setTimeout(() => {
      setSelectedItem(e);
      if (e) setCurrentMasrafKalemId(e);
    }, 100);
  };
  const yenileClick = () => {
    createChildren(null)
      .then(function(response) {
        console.log(response);
        if (response == null) setItems([{ id: 0, text: "Yeni" }]);
        else setItems(response);
      })
      .catch(function(error) {
        console.log(error);
        setItems(null);
      });
  };
  useEffect(() => {
    yenileClick();
  }, [projeId]);
  return (
    <React.Fragment>
      <div
        className="boxLeft"
        style={{
          height: screenheight - 150,
          marginTop: -11,
          width: 400,
          marginLeft: -20
        }}
      >
        <DataTreeView treeItems={items} selectItem={selectItem}></DataTreeView>
      </div>
      <div className="boxLeft">
        {currentMasrafKalemId && (
          <MasrafKalemDuzenle
            MasrafKalemId={currentMasrafKalemId}
            projeId={projeId}
            kurumId={kurumId}
            currentNode={selectedItem}
            refreshTree={yenileClick}
          ></MasrafKalemDuzenle>
        )}
      </div>
    </React.Fragment>
  );
};
export default MasrafKalemListe;
