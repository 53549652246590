import React, { Fragment, useState, useEffect } from "react";
import MenuAppBar from "./MenuAppBar.js";
import "../App.css";
import { useWindowResize } from "../Custom-Hooks/useWindowResize";
import { red } from "@material-ui/core/colors";
import ProjectList from "./ProjectList.js";
import SeasonList from "./SeasonList";
import BolumListe from "./BolumListe";
import axios from "axios";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";

const BolumSecim = () => {
  const { url } = useApiURL();
  const { screenwidth, screenheight } = useWindowResize();
  const [token] = useState(localStorage.getItem("Token"));
  var projeDataInitial1 = [];
  var sezonDataInitial1 = [];
  var bolumDataInitial1 = [];
  const [projeData, setProjeler] = useState(projeDataInitial1);
  const [sezonData, setSezonlar] = useState(sezonDataInitial1);
  const [bolumData, setBolumler] = useState(bolumDataInitial1);
  const [selectedProjeId, setSelectedProjeId] = useState(
    localStorage.getItem("ProjeId")
  );
  const [selectedSezonId, setSelectedSezonId] = useState(
    localStorage.getItem("SezonId")
  );
  const [selectedBolumId, setselectedBolumId] = useState(
    localStorage.getItem("BolumId")
  );
  useEffect(() => {
    document.title = `Proje-Sezon-Bölüm`;
    loadProjects();
  }, [token]);

  const loadProjects = () => {
    var requestModel = {
      Token: localStorage.getItem("Token")
    };
    axios
      .post(url + "/api/ProjeListe/ProjeListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setProjeler(response.data.data.projeListe);
            } else {
              alert(response.data.responseMessage);
            }
          } else {
            alert(response);
          }
        } catch (error) {
          alert(error);
        }
      })
      .catch(function(error) {
        console.log(error);
        alert(error);
      });
  };
  const handleProjectClick = (ProjeId, ProjeAd) => {
    localStorage.setItem("ProjeId", ProjeId);
    localStorage.setItem("ProjeAd", ProjeAd);
    setSelectedProjeId(ProjeId);
    var requestModel = {
      ProjeId: ProjeId,
      Token: localStorage.getItem("Token")
    };
    axios
      .post(url + "/api/SezonListe/SezonListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setSezonlar(response.data.data.sezonListe);
            } else {
              alert(response.data.responseMessage);
            }
          } else {
            alert(response);
          }
        } catch (error) {
          alert(error);
        }
      })
      .catch(function(error) {
        console.log(error);
        alert(error);
      });
  };
  const handleSezonClick = (SezonId, SezonKod, SezonAd) => {
    localStorage.setItem("SezonId", SezonId);
    localStorage.setItem("SezonKod", SezonKod);
    localStorage.setItem("SezonAd", SezonAd);
    setSelectedSezonId(SezonId);
    var requestModel = {
      SezonId: SezonId,
      Token: localStorage.getItem("Token")
    };
    axios
      .post(url + "/api/BolumListe/BolumListe", requestModel)
      .then(function(response) {
        try {
          // console.log(response);
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              // console.log("Sezon Listelendi");
              // console.log(response.data);
              setBolumler(response.data.data.bolumListe);
            } else {
              alert(response.data.responseMessage);
            }
          } else {
            console.log(response);
            alert(response);
          }
        } catch (error) {
          alert(error);
        }
      })
      .catch(function(error) {
        console.log(error);
        alert(error);
      });
  };

  const handleBolumClick = (BolumId, BolumKod, BolumAd) => {
    localStorage.setItem("BolumId", BolumId);
    localStorage.setItem("BolumKod", BolumKod);
    localStorage.setItem("BolumAd", BolumAd);

    setselectedBolumId(BolumId);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  return (
    <Fragment>
      <MenuAppBar />
      <div
        className="boxBorderBeige boxLeft"
        style={{
          borderColor: red,
          width: Math.max(230, (screenwidth - 80) / 2 - 10),
          height: screenheight - 150,
          padding: 10,
          overflowY: "auto"
        }}
      >
        <ProjectList
          projeData={projeData}
          selectedProjeId={selectedProjeId}
          onProjectClick={handleProjectClick}
        />
      </div>
      <div
        id="divEpisodes"
        className="boxBorderBeige boxLeft "
        style={{
          borderColor: red,
          width: Math.max(220, (screenwidth - 80) / 4 - 10),
          height: screenheight - 150,
          padding: 10,
          overflowY: "auto"
        }}
      >
        <SeasonList
          sezonData={sezonData}
          selectedSezonId={selectedSezonId}
          onSezonClick={handleSezonClick}
        />
      </div>
      <div
        className="boxBorderBeige boxLeft "
        style={{
          borderColor: red,
          width: Math.max(220, (screenwidth - 80) / 4 - 10),
          height: screenheight - 150,
          padding: 10,
          overflowY: "auto"
        }}
      >
        <BolumListe
          bolumData={bolumData}
          selectedBolumId={selectedBolumId}
          onBolumClick={handleBolumClick}
        />
      </div>
    </Fragment>
  );
};

export default BolumSecim;
