import React, { Fragment, useRef, createRef, useState, useEffect } from "react";
import "../App.css";
import { useWindowResize } from "../Custom-Hooks/useWindowResize";
import axios from "axios";
import ImgYes from "../img/IsProgramiDetay/Tamam.png";
import ImgSoru from "../img/IsProgramiDetay/Cekilemedi.png";
import ImgNo from "../img/IsProgramiDetay/iptal.png";
import BaslikDetay from "../img/IsProgramiDetay/Baslik.png";
import CekimGunBuyuk from "./CekimGunBuyuk.js";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import MenuWrapper from "../CircleMenu/FullScreenMenu";
import Select from "react-select";
import Ekle from "../img/SahneDokumu/Ekle.png";
import Sil from "../img/OnGoru/Sil.png";
const IsProgrami = () => {
  const { url } = useApiURL();
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [projeId] = useState(localStorage.getItem("ProjeId"));
  const [bolumId, setBolumId] = useState(localStorage.getItem("BolumId"));
  const [selectedDate] = React.useState(new Date(Date.now()));
  const [isProgramiBaslik, setIsProgramiBaslik] = React.useState({});
  const [isProgramiBaslikId] = useState(
    localStorage.getItem("IsProgramiBaslikId")
  );
  let checkEl = useRef([createRef()]);
  let inputEl = useRef([createRef()]);
  const [sahneInitial] = useState({});
  const [isProgramiDetayListe, setIsProgramiDetayListe] = useState([
    sahneInitial
  ]);
  const listeInitial = [{ value: 1, label: "" }];

  const [yardimciOyuncuAdlariListe, setYardimciOyuncuAdlariListe] = useState(
    listeInitial
  );

  const [
    isProgramiMasrafKalemYemekListe,
    setIsProgramiMasrafKalemYemekListe
  ] = useState([]);
  const [
    isProgramiMasrafKalemFigurasyonListe,
    setIsProgramiMasrafKalemFigurasyonListe
  ] = useState([]);
  const [
    isProgramiMasrafKalemOyuncularListe,
    setIsProgramiMasrafKalemOyuncularListe
  ] = useState([]);
  const [
    isProgramiMasrafKalemYardimciOyuncularListe,
    setIsProgramiMasrafKalemYardimciOyuncularListe
  ] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  function logOut() {
    setToken("");
    localStorage.removeItem("BolumId");
    localStorage.removeItem("MenuId");
    localStorage.removeItem("Token");
    localStorage.removeItem("IsProgramiBaslikId");
    window.location.reload();
  }
  function handleDurumDegistirClick(event, durum) {
    if (isProgramiDetayListe == null) return;
    var fl = isProgramiDetayListe.filter(item => item.checked);
    if (fl.length === 0) {
      alert("Bir Sahne Seçiniz");
    }
    fl.forEach(element => {
      loadIsProgramiDetayDurum(element.vw_IsProgramiDetayId, durum);
    });
    isProgramiDetayListe.forEach(sahne => {
      sahne.checked = false;
      checkEl.current[sahne.lineIndex].checked = false;
    });
  }

  function handleKaydetClick() {
    saveIsProgramiBaslik(token, isProgramiBaslikId, isProgramiBaslik);
  }
  function handleSilClick() {}

  function handleDateClick(event) {
    setAnchorEl(event.currentTarget);
  }
  const saveIsProgramiBaslik = (
    token,
    isProgramiBaslikId,
    isProgramiBaslik
  ) => {
    let oyuncularText = isProgramiMasrafKalemOyuncularListe.map(line =>
      JSON.stringify(line)
    );
    let yardimciOyuncularText = isProgramiMasrafKalemYardimciOyuncularListe.map(
      line => JSON.stringify(line)
    );
    let figurasyonText = isProgramiMasrafKalemFigurasyonListe.gruplar.map(
      line => JSON.stringify(line)
    );
    let yemekText = isProgramiMasrafKalemYemekListe.map(line =>
      JSON.stringify(line)
    );
    var requestModel = {
      projeId: projeId,
      isProgramiBaslik: isProgramiBaslik,
      isProgramiBaslikId,
      oyuncular: oyuncularText,
      yardimciOyuncular: yardimciOyuncularText,
      figurasyon: figurasyonText,
      yemek: yemekText,
      token
    };
    console.log(requestModel);
    axios
      .post(url + "/api/SahneListe/IsProgramiKaydet", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              var i = 0;
              if (res == null) return;
              var res = response.data.responseObject;
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  function siralaSahneNo(sahneDokumu) {
    var sd = sahneDokumu;
    sd = sd.sort(function(a, b) {
      var x = a.sahneNo;
      var y = b.sahneNo;
      try {
        x = x.match(/\d+/)[0].padStart(6, "0");
      } catch {}
      try {
        y = y.match(/\d+/)[0].padStart(6, "0");
      } catch {}
      console.log(a.sahneId);
      if (a.sahneId === "00000000-0000-0000-0000-000000000000") return 1;

      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    var i = 0;
    for (let index = 0; index < sd.length; index++) {
      sd[index].index = index;
      sd[index].lineIndex = index * 12;
    }
    return sd;
  }
  const loadIsProgramiDetayDurum = (isProgramiDetayId, durum) => {
    if (isProgramiBaslikId === 0) {
      return;
    }
    var requestModel = {
      Id: isProgramiDetayId,
      Value: durum,
      Token: token
    };
    axios
      .post(url + "/api/SahneListe/IsProgramiDetayDurum", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response);
              var i = 0;
              var res = response.data.responseObject;

              res.forEach(element => {
                element.mekanListesi = JSON.parse(element.mekanListe);
                element.teknikMalzemeListesi = JSON.parse(
                  element.teknikMalzemeListe
                );
                element.aksesuarListesi = JSON.parse(element.aksesuarListe);
                element.oyuncularListesi = JSON.parse(element.oyuncularListe);
                element.yardimciOyuncularListesi = JSON.parse(
                  element.yardimciOyuncularListe
                );

                element.index = i;
                element.lineIndex = i * 12;
                i++;
              });
              setIsProgramiDetayListe(res);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  const loadIsProgramiDetay = (isProgramiBaslikId, token) => {
    if (isProgramiBaslikId === 0) {
      return;
    }
    var requestModel = {
      Id: isProgramiBaslikId,
      Token: token
    };
    axios
      .post(url + "/api/SahneListe/IsProgramiDetay", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response);
              var i = 0;
              var res = response.data.responseObject;
              res = siralaSahneNo(res);
              res.forEach(element => {
                element.mekanListesi = JSON.parse(element.mekanListe);
                element.teknikMalzemeListesi = JSON.parse(
                  element.teknikMalzemeListe
                );
                element.aksesuarListesi = JSON.parse(element.aksesuarListe);
                element.oyuncularListesi = JSON.parse(element.oyuncularListe);
                element.yardimciOyuncularListesi = JSON.parse(
                  element.yardimciOyuncularListe
                );

                element.index = i;
                element.lineIndex = i * 12;
                i++;
              });

              setIsProgramiDetayListe(res);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadIsProgramiBaslik = (isProgramiBaslikId, token) => {
    if (isProgramiBaslikId === 0) {
      return;
    }
    var requestModel = {
      Id: isProgramiBaslikId,
      Token: token
    };
    axios
      .post(url + "/api/SahneListe/IsProgramiBaslik", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              var i = 0;
              var res = response.data.responseObject;
              res.tarih = res.tarih.substring(0, 10);
              console.log(res);

              setIsProgramiBaslik(res);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  // const loadOrtam = token => {
  //   var requestModel = {
  //     Token: token
  //   };
  //   axios
  //     .post(url + "/api/OrtamListe/OrtamListe", requestModel)
  //     .then(function(response) {
  //       try {
  //         if (response.status === 200) {
  //           if (response.data.responseCode === 200) {
  //             // console.log(response);
  //             setOrtamListe(
  //               response.data.data.ortamListe.map(suggestion => ({
  //                 value: suggestion.ortamId,
  //                 label: suggestion.ortamAd
  //               }))
  //             );
  //           } else if (response.data.responseCode === 401) {
  //             logOut();
  //           } else {
  //             alert(response.data.responseMessage);
  //             console.log(response.data.responseMessage);
  //           }
  //         } else {
  //           alert(response);
  //           console.log(response);
  //         }
  //       } catch (error) {
  //         alert(error);
  //         console.log(error);
  //       }
  //     })
  //     .catch(function(error) {
  //       alert(error);
  //       console.log(error);
  //     });
  // };
  // const loadMekan = (token, projeId) => {
  //   var requestModel = {
  //     Token: token,
  //     ProjeId: projeId
  //   };
  //   axios
  //     .post(url + "/api/MekanListe/MekanListe", requestModel)
  //     .then(function(response) {
  //       try {
  //         if (response.status === 200) {
  //           if (response.data.responseCode === 200) {
  //             // console.log(response);
  //             setMekanListe(
  //               response.data.data.mekanListe.map(suggestion => ({
  //                 value: suggestion.mekanId,
  //                 label: suggestion.mekanAd
  //               }))
  //             );
  //           } else if (response.data.responseCode === 401) {
  //             logOut();
  //           } else {
  //             alert(response.data.responseMessage);
  //             console.log(response.data.responseMessage);
  //           }
  //         } else {
  //           alert(response);
  //           console.log(response);
  //         }
  //       } catch (error) {
  //         alert(error);
  //         console.log(error);
  //       }
  //     })
  //     .catch(function(error) {
  //       alert(error);
  //       console.log(error);
  //     });
  // };

  // const loadOyuncular = (token, projeId) => {
  //   var requestModel = {
  //     Token: token,
  //     Id: projeId
  //   };
  //   axios
  //     .post(url + "/api/MasrafKalemListe/OyuncuListe", requestModel)
  //     .then(function(response) {
  //       try {
  //         if (response.status === 200) {
  //           if (response.data.responseCode === 200) {
  //             // console.log(response);
  //             setOyuncularListe(
  //               response.data.data.masrafKalemListe.map(suggestion => ({
  //                 value: suggestion.masrafKalemId,
  //                 label: suggestion.masrafKalemAd
  //               }))
  //             );

  //             setOyuncuAdlariListe(
  //               response.data.data.masrafKalemListe.map(suggestion => ({
  //                 value: suggestion.masrafKalemId,
  //                 label: suggestion.masrafKalemAciklama2
  //               }))
  //             );
  //           } else if (response.data.responseCode === 401) {
  //             logOut();
  //           } else {
  //             alert(response.data.responseMessage);
  //             console.log(response.data.responseMessage);
  //           }
  //         } else {
  //           alert(response);
  //           console.log(response);
  //         }
  //       } catch (error) {
  //         alert(error);
  //         console.log(error);
  //       }
  //     })
  //     .catch(function(error) {
  //       alert(error);
  //       console.log(error);
  //     });
  // };

  const loadIsProgramiMasrafKalemYemek = (
    projeId,
    isProgramiBaslikId,
    token
  ) => {
    var requestModel = {
      Token: token,
      ProjeId: projeId,
      IsProgramiBaslikId: isProgramiBaslikId,
      AlanId: 8
    };
    axios
      .post(url + "/api/SahneListe/IsProgramiMasrafKalemListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);
              setIsProgramiMasrafKalemYemekListe(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadIsProgramiMasrafKalemFigurasyon = (
    projeId,
    isProgramiBaslikId,
    token
  ) => {
    var requestModel = {
      Token: token,
      ProjeId: projeId,
      IsProgramiBaslikId: isProgramiBaslikId,
      AlanId: 10
    };
    axios
      .post(
        url + "/api/SahneListe/IsProgramiMasrafKalemListeGrup",
        requestModel
      )
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);

              setIsProgramiMasrafKalemFigurasyonListe(
                response.data.responseObject
              );
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadIsProgramiMasrafKalemOyuncular = (
    projeId,
    isProgramiBaslikId,
    token
  ) => {
    var requestModel = {
      Token: token,
      ProjeId: projeId,
      IsProgramiBaslikId: isProgramiBaslikId,
      AlanId: 2
    };
    axios
      .post(url + "/api/SahneListe/IsProgramiMasrafKalemListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);

              setIsProgramiMasrafKalemOyuncularListe(
                response.data.responseObject
              );
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  const loadIsProgramiMasrafKalemYardimciOyuncular = (
    projeId,
    isProgramiBaslikId,
    token
  ) => {
    var requestModel = {
      Token: token,
      ProjeId: projeId,
      IsProgramiBaslikId: isProgramiBaslikId,
      AlanId: 3
    };
    axios
      .post(url + "/api/SahneListe/IsProgramiMasrafKalemListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);

              setIsProgramiMasrafKalemYardimciOyuncularListe(
                response.data.responseObject
              );
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
  // const loadYOyuncular = (token, projeId) => {
  //   var requestModel = {
  //     Token: token,
  //     ProjeId: projeId,
  //     AlanId: 3
  //   };
  //   axios
  //     .post(url + "/api/MasrafKalemListe/MasrafKalemListe", requestModel)
  //     .then(function(response) {
  //       try {
  //         if (response.status === 200) {
  //           if (response.data.responseCode === 200) {
  //             // console.log(response);
  //             setYOyuncularListe(
  //               response.data.data.masrafKalemListe.map(suggestion => ({
  //                 value: suggestion.masrafKalemId,
  //                 label: suggestion.masrafKalemAd
  //               }))
  //             );
  //             setYardimciOyuncuAdlariListe(
  //               response.data.data.masrafKalemListe.map(suggestion => ({
  //                 value: suggestion.masrafKalemId,
  //                 label: suggestion.masrafKalemAciklama2
  //               }))
  //             );
  //           } else if (response.data.responseCode === 401) {
  //             logOut();
  //           } else {
  //             alert(response.data.responseMessage);
  //             console.log(response.data.responseMessage);
  //           }
  //         } else {
  //           alert(response);
  //           console.log(response);
  //         }
  //       } catch (error) {
  //         alert(error);
  //         console.log(error);
  //       }
  //     })
  //     .catch(function(error) {
  //       alert(error);
  //       console.log(error);
  //     });
  // };
  // const loadAksesuar = (token, projeId) => {
  //   var requestModel = {
  //     Token: token,
  //     ProjeId: projeId,
  //     AlanId: 4
  //   };
  //   axios
  //     .post(url + "/api/MasrafKalemListe/MasrafKalemListe", requestModel)
  //     .then(function(response) {
  //       try {
  //         if (response.status === 200) {
  //           if (response.data.responseCode === 200) {
  //             // console.log(response);
  //             setAksesuarListe(
  //               response.data.data.masrafKalemListe.map(suggestion => ({
  //                 value: suggestion.masrafKalemId,
  //                 label: suggestion.masrafKalemAd
  //               }))
  //             );
  //           } else if (response.data.responseCode === 401) {
  //             logOut();
  //           } else {
  //             alert(response.data.responseMessage);
  //             console.log(response.data.responseMessage);
  //           }
  //         } else {
  //           alert(response);
  //           console.log(response);
  //         }
  //       } catch (error) {
  //         alert(error);
  //         console.log(error);
  //       }
  //     })
  //     .catch(function(error) {
  //       alert(error);
  //       console.log(error);
  //     });
  // };
  // const loadTeknikMalzeme = (token, projeId) => {
  //   var requestModel = {
  //     Token: token,
  //     ProjeId: projeId,
  //     AlanId: 5
  //   };
  //   axios
  //     .post(url + "/api/MasrafKalemListe/MasrafKalemListe", requestModel)
  //     .then(function(response) {
  //       try {
  //         if (response.status === 200) {
  //           if (response.data.responseCode === 200) {
  //             setTeknikMalzeme(
  //               response.data.data.masrafKalemListe.map(suggestion => ({
  //                 value: suggestion.masrafKalemId,
  //                 label: suggestion.masrafKalemAd
  //               }))
  //             );
  //           } else if (response.data.responseCode === 401) {
  //             logOut();
  //           } else {
  //             alert(response.data.responseMessage);
  //             console.log(response.data.responseMessage);
  //           }
  //         } else {
  //           alert(response);
  //           console.log(response);
  //         }
  //       } catch (error) {
  //         alert(error);
  //         console.log(error);
  //       }
  //     })
  //     .catch(function(error) {
  //       alert(error);
  //       console.log(error);
  //     });
  // };
  const customStylesOyuncu = {
    control: (base, state) => ({
      ...base,
      borderRadius: state.isFocused ? "0px 0px 0 0" : 3,
      border: 0,
      borderColor: state.isFocused ? "yellow" : "green",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "red" : "blue"
      }
    }),
    menu: base => ({
      ...base,
      borderRadius: 0,
      marginTop: 0
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 0,
      display: "none"
    }),
    menuList: base => ({
      ...base,
      padding: 0
    })
  };

  // useEffect(
  //   () => {
  //     document.title = `CineCost Sahne Dökümü`;
  //     loadSahne();
  //     loadOrtam();
  //     loadMekan();
  //     loadOyuncular();
  //     loadYOyuncular();
  //     loadAksesuar();
  //     loadTeknikMalzeme();
  //     loadProduksiyon();
  //   },
  //   { token }
  // );

  useEffect(() => {
    //console.log("sahnedokumu");
  }, [isProgramiDetayListe]);
  useEffect(() => {
    document.title = `CineCost İş Programı Detay`;
    //loadOrtam();
  }, [token]);
  useEffect(() => {
    loadIsProgramiDetay(isProgramiBaslikId, token);
    loadIsProgramiBaslik(isProgramiBaslikId, token);
    loadIsProgramiMasrafKalemYemek(projeId, isProgramiBaslikId, token);
    setTimeout(() => {
      loadIsProgramiMasrafKalemFigurasyon(projeId, isProgramiBaslikId, token);  
    }, 500);    
    loadIsProgramiMasrafKalemOyuncular(projeId, isProgramiBaslikId, token);
    loadIsProgramiMasrafKalemYardimciOyuncular(
      projeId,
      isProgramiBaslikId,
      token
    );
  }, [isProgramiBaslikId, token]);
  // useEffect(() => {
  //   loadMekan(token, projeId);
  //   loadOyuncular(token, projeId);
  //   loadYOyuncular(token, projeId);
  //   loadAksesuar(token, projeId);
  //   loadTeknikMalzeme(token, projeId);
  // }, [projeId]);
  const { screenwidth, screenheight } = useWindowResize();
  var totalwidth = Math.max(screenwidth, 1366);
  const [innerwidth] = useState(totalwidth - 86);
  function handleIsProgramiBaslikChange(isProgramiBaslik) {
    console.log(isProgramiBaslik);
  }

  // select  'function handle'+Name+'Change(evt) {    isProgramiBaslik.'+lower(substring(name,1,1))+substring(name,2,20)+' = evt.target.value; handleIsProgramiBaslikChange(isProgramiBaslik); }' from syscolumns where id = object_id('IsProgramiBaslik')
  function handleIsProgramiBaslikIdChange(evt) {
    isProgramiBaslik.ısProgramiBaslikId = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleKurumIdChange(evt) {
    isProgramiBaslik.kurumId = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleSezonIdChange(evt) {
    isProgramiBaslik.sezonId = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleTarihChange(evt) {
    isProgramiBaslik.tarih = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleEkipNoChange(evt) {
    isProgramiBaslik.ekipNo = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleYapimChange(evt) {
    isProgramiBaslik.yapim = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleYonetmenChange(evt) {
    isProgramiBaslik.yonetmen = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleGoruntuYonetmeniChange(evt) {
    isProgramiBaslik.goruntuYonetmeni = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleIkinciYonetmenChange(evt) {
    isProgramiBaslik.ıkinciYonetmen = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleUygulayiciYapimciChange(evt) {
    isProgramiBaslik.uygulayiciYapimci = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleYardimciYonetmenChange(evt) {
    isProgramiBaslik.yardimciYonetmen = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleDuyuruChange(evt) {
    isProgramiBaslik.duyuru = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleNotlarChange(evt) {
    isProgramiBaslik.notlar = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleIsGunuChange(evt) {
    isProgramiBaslik.ısGunu = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleMekanChange(evt) {
    isProgramiBaslik.mekan = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleLokasyonChange(evt) {
    isProgramiBaslik.lokasyon = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleSetSaatiChange(evt) {
    isProgramiBaslik.setSaati = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleServis1YerChange(evt) {
    isProgramiBaslik.servis1Yer = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleServis1SaatChange(evt) {
    isProgramiBaslik.servis1Saat = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleServis2YerChange(evt) {
    isProgramiBaslik.servis2Yer = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleServis2SaatChange(evt) {
    isProgramiBaslik.servis2Saat = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleHavaDurumuChange(evt) {
    isProgramiBaslik.havaDurumu = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleGunDogumuChange(evt) {
    isProgramiBaslik.gunDogumu = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleGunBatimiChange(evt) {
    isProgramiBaslik.gunBatimi = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleSayfaGunChange(evt) {
    isProgramiBaslik.sayfaGun = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleSayfaGeceChange(evt) {
    isProgramiBaslik.sayfaGece = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleSayfaToplamChange(evt) {
    isProgramiBaslik.sayfaToplam = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleServisDagitimliChange(evt) {
    isProgramiBaslik.servisDagitimli = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleServisDagitimsizChange(evt) {
    isProgramiBaslik.servisDagitimsiz = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleServisEvdenToplamaChange(evt) {
    isProgramiBaslik.servisEvdenToplama = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleProduksiyonaOzelNotChange(evt) {
    isProgramiBaslik.produksiyonaOzelNot = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleEklemeChange(evt) {
    isProgramiBaslik.ekleme = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleEkleyenChange(evt) {
    isProgramiBaslik.ekleyen = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleDegistirmeChange(evt) {
    isProgramiBaslik.degistirme = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleDegistirenChange(evt) {
    isProgramiBaslik.degistiren = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  function handleSilindiChange(evt) {
    isProgramiBaslik.silindi = evt.target.value;
    handleIsProgramiBaslikChange(isProgramiBaslik);
  }
  // Auto Genarated Bitti

  //İş Programı Başlık Bitti
  //İş Programı Masraf Kalem
  function handleIsProgramiMasrafKalemOYUNCULARChange(evt, mk) {
    isProgramiBaslik.oyuncularListesi = evt;
  }
  function handleIsProgramiMasrafKalemYARDIMCIOYUNCULARChange(evt, mk) {
    isProgramiBaslik.yardimciOyuncularListesi = evt;
  }

  function handleIsProgramiMasrafKalembaslamaSaatChange(evt, mk) {
    mk.baslamaSaat = evt.target.value;
  }
  function handleIsProgramiMasrafKalembitisSaatChange(evt, mk) {
    mk.bitisSaat = evt.target.value;
  }

  function handleIsProgramiMasrafKalembaslamaSaatTextChange(evt, mk) {
    mk.baslamaSaatText = evt.target.value;
  }
  function handleIsProgramiMasrafKalembitisSaatTextChange(evt, mk) {
    mk.bitisSaatText = evt.target.value;
  }

  function handleIsProgramiMasrafKalemgercekAdetChange(evt, mk) {
    mk.gercekAdet = evt.target.value;
  }

  //İş Programı Detay
  function handleChange(sahne) {
    console.log(sahne);
  }
  function handleCHKAllChange(evt) {
    console.log(isProgramiDetayListe);

    isProgramiDetayListe.forEach(sahne => {
      sahne.checked = evt.target.checked;
      checkEl.current[sahne.lineIndex].checked = evt.target.checked;
    });
    console.log(isProgramiDetayListe);
  }
  function handleCHKChange(evt, sahne) {
    handleChange(sahne);
    sahne.checked = evt.target.checked;
  }
  function handleOnKeyDown(event, inputIndex) {
    try {
      if (
        !(
          event.key === "ArrowRight" ||
          event.key === "ArrowLeft" ||
          event.key === "ArrowDown" ||
          event.key === "ArrowUp"
        )
      )
        return;
      event.preventDefault();
      var elementToFocus;
      var elementToFocusIndex = inputIndex;
      var findIndex = 0;
      if (event.key === "ArrowRight") {
        findIndex = 1;
      }
      if (event.key === "ArrowLeft") {
        findIndex = -1;
      }
      if (event.key === "ArrowDown") {
        findIndex = 12;
      }
      if (event.key === "ArrowUp") {
        findIndex = -12;
      }
      console.log(elementToFocusIndex);
      for (let i = 1; i < 12; i++) {
        elementToFocusIndex = inputIndex + findIndex * i;
        console.log(elementToFocusIndex);
        elementToFocus = inputEl.current[elementToFocusIndex];
        if (elementToFocus != null) break;
      }

      elementToFocus.focus();
      try {
        elementToFocus.select();
      } catch {}
    } catch (err) {
      console.log(elementToFocus);
      console.log(err.message);
    }
  }
  // console.log((innerwidth / 40) * 1);
  return (
    <Fragment>
      <div
        id="divBaslik"
        style={{
          width: totalwidth,
          height: 60,
          padding: 0,
          margin: 0
        }}
      >
        <div
          id="divBaslikResim"
          style={{
            height: 40,
            padding: 0,
            margin: 0,
            width: 250
          }}
          className="boxLeft"
        >
          <img src={BaslikDetay} alt="Baslik" />
        </div>

        <div
          style={{
            height: 80,
            padding: 0,
            margin: 0,
            width: 65,
            marginLeft: 300
          }}
          className={"boxLeft"}
        >
          <img
            src={ImgYes}
            alt="Çekim Başladı"
            title="Çekim Başladı"
            onClick={ev => handleDurumDegistirClick(ev, 6)}
            style={{
              padding: 0,
              margin: 7,
              width: 65
            }}
          />
        </div>
        <div
          style={{
            height: 80,
            padding: 0,
            margin: 0,
            width: 65,
            marginLeft: 30
          }}
          className={"boxLeft"}
        >
          <img
            src={ImgSoru}
            alt="Cekilemedi"
            title="Cekilemedi"
            onClick={ev => handleDurumDegistirClick(ev, 7)}
            style={{
              padding: 0,
              margin: 7,
              width: 65
            }}
          />
        </div>
        <div
          style={{
            height: 80,
            padding: 0,
            margin: 0,
            width: 65,
            marginLeft: 30
          }}
          className="boxLeft"
        >
          <img
            src={ImgNo}
            alt="Çekim İptal"
            onClick={ev => handleDurumDegistirClick(ev, 8)}
            style={{
              padding: 0,
              margin: 7,
              width: 65
            }}
          />
        </div>
        <div  style={{
          position: "absolute",
          left: -35 ,
          top: 25,
        }}>
    <MenuWrapper>

    </MenuWrapper>
      </div>
        <div
          style={{
            height: 80,
            padding: 0,
            margin: 0,
            width: 333,
            marginLeft: 30
          }}
          className="boxLeft"
        >
          <div
            style={{
              height: 80,
              padding: 0,
              margin: 0,
              width: 20
            }}
            className="boxLeft"
          />

          <div className="boxLeft" onClick={ev => handleKaydetClick(ev)}>
            <img
              src={Ekle}
              alt="Kaydet"
              title="Kaydet"
              style={{
                padding: 0,
                margin: 7
              }}
            />
          </div>
          <div className="boxLeft" onClick={ev => handleSilClick(ev)}>
            <img
              src={Sil}
              alt="Çıkar"
              style={{
                padding: 0,
                margin: 7,
                height: 58
              }}
            />
          </div>

          <br />
          <br />
          {/* <div
            className="sahneDokumuBolumSec boxLeft"
            onClick={ev => handleBolumSecClick(ev)}
          >
            Bölüm Seç
          </div>
          <div
            className="sahneDokumuBolumSec boxLeft"
            onClick={ev => handleMenuSecClick(ev)}
          >
            Menü Seç
          </div>
          <div
            className="sahneDokumuBolumSec boxLeft"
            onClick={ev => handleYenileClick(ev)}
          >
            Yenile
          </div> */}
        </div>
        <div
          style={{
            height: 80,
            padding: 0,
            margin: 0,
            width: 230
          }}
          className="boxRight"
        >
          <CekimGunBuyuk
            key="CekimGun1"
            id="CekimGun1"
            onSelectDate={handleDateClick}
            tarih={selectedDate}
            ekip={1}
            cekimGunWidth={230}
            bolumId={bolumId}
            isProgramiBaslikId={isProgramiBaslikId}
          />
        </div>
      </div>
      <div>
        <table
          id="divIsProgramiBaslik"
          style={{
            width: totalwidth - 24,
            marginLeft: 11,
            marginBottom: 3,
            padding: 0,
            borderColor: "black",
            borderWidth: 2,
            borderStyle: "solid"
          }}
          cellPadding="0"
          cellSpacing="0"
        >
          <tbody>
            <tr>
              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Yapım:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="yapim"
                  type="text"
                  defaultValue={isProgramiBaslik.yapim}
                  onChange={ev => handleYapimChange(ev)}
                  ref={input => {
                    inputEl.current[-8 * 12 + 0] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -8 * 12 + 0)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
              <td
                style={{
                  padding: 0,
                  width: (innerwidth / 40) * 13,
                  textAlign: "center",
                  fontSize: 70,
                  fontWeight: "bold"
                }}
                rowSpan="6"
              >
                {localStorage.getItem("ProjeAd")}
              </td>
              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                İş Günü:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="isGunu"
                  type="text"
                  defaultValue={isProgramiBaslik.isGunu}
                  onChange={ev => handleIsGunuChange(ev)}
                  ref={input => {
                    inputEl.current[-8 * 12 + 2] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -8 * 12 + 2)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Tarih:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="tarih"
                  type="date"
                  defaultValue={isProgramiBaslik.tarih}
                  onChange={ev => handleTarihChange(ev)}
                  ref={input => {
                    inputEl.current[-8 * 12 + 3] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -8 * 12 + 3)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
            </tr>
            <tr>
              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Yönetmen:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="yonetmen"
                  type="text"
                  defaultValue={isProgramiBaslik.yonetmen}
                  onChange={ev => handleYonetmenChange(ev)}
                  ref={input => {
                    inputEl.current[-7 * 12 + 0] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -7 * 12 + 0)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>

              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Mekan:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="mekan"
                  type="text"
                  defaultValue={isProgramiBaslik.mekan}
                  onChange={ev => handleMekanChange(ev)}
                  ref={input => {
                    inputEl.current[-7 * 12 + 2] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -7 * 12 + 2)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Hava Durumu:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="havaDurumu"
                  type="text"
                  defaultValue={isProgramiBaslik.havaDurumu}
                  onChange={ev => handleHavaDurumuChange(ev)}
                  ref={input => {
                    inputEl.current[-7 * 12 + 3] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -7 * 12 + 3)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
            </tr>
            <tr>
              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Gör.Yönetmeni:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="goruntuYonetmeni"
                  type="text"
                  defaultValue={isProgramiBaslik.goruntuYonetmeni}
                  onChange={ev => handleGoruntuYonetmeniChange(ev)}
                  ref={input => {
                    inputEl.current[-6 * 12 + 0] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -6 * 12 + 0)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>

              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Lokasyon:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="lokasyon"
                  type="text"
                  defaultValue={isProgramiBaslik.lokasyon}
                  onChange={ev => handleLokasyonChange(ev)}
                  ref={input => {
                    inputEl.current[-6 * 12 + 2] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -6 * 12 + 2)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Gün Doğumu:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="gunDogumu"
                  type="text"
                  defaultValue={isProgramiBaslik.gunDogumu}
                  onChange={ev => handleGunDogumuChange(ev)}
                  ref={input => {
                    inputEl.current[-6 * 12 + 3] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -6 * 12 + 3)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
            </tr>
            <tr>
              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                2.Yönetmen:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="ikinciYonetmen"
                  type="text"
                  defaultValue={isProgramiBaslik.ikinciYonetmen}
                  onChange={ev => handleIkinciYonetmenChange(ev)}
                  ref={input => {
                    inputEl.current[-5 * 12 + 0] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -5 * 12 + 0)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>

              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Set Saati:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="setSaati"
                  type="text"
                  defaultValue={isProgramiBaslik.setSaati}
                  onChange={ev => handleSetSaatiChange(ev)}
                  ref={input => {
                    inputEl.current[-5 * 12 + 2] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -5 * 12 + 2)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Gün Batımı:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="gunBatimi"
                  type="text"
                  defaultValue={isProgramiBaslik.gunBatimi}
                  onChange={ev => handleGunBatimiChange(ev)}
                  ref={input => {
                    inputEl.current[-5 * 12 + 3] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -5 * 12 + 3)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
            </tr>
            <tr>
              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Uyg.Yapımcı:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="uygulayiciYapimci"
                  type="text"
                  defaultValue={isProgramiBaslik.uygulayiciYapimci}
                  onChange={ev => handleUygulayiciYapimciChange(ev)}
                  ref={input => {
                    inputEl.current[-4 * 12 + 0] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -4 * 12 + 0)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>

              <td style={{ padding: 0, width: (innerwidth / 40) * 3 }}>
                <input
                  id="servis1Yer"
                  type="text"
                  defaultValue={isProgramiBaslik.servis1Yer}
                  onChange={ev => handleServis1YerChange(ev)}
                  ref={input => {
                    inputEl.current[-4 * 12 + 1] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -4 * 12 + 1)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="servis1Saat"
                  type="text"
                  defaultValue={isProgramiBaslik.servis1Saat}
                  onChange={ev => handleServis1SaatChange(ev)}
                  ref={input => {
                    inputEl.current[-4 * 12 + 2] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -4 * 12 + 2)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Sayfa Gün/Gece:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="sayfaGun"
                  type="text"
                  defaultValue={isProgramiBaslik.sayfaGun}
                  onChange={ev => handleSayfaGunChange(ev)}
                  ref={input => {
                    inputEl.current[-4 * 12 + 3] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -4 * 12 + 3)}
                  style={{ width: "45%", backgroundColor: "#ffffff" }}
                />
                /
                <input
                  id="sayfaGece"
                  type="text"
                  defaultValue={isProgramiBaslik.sayfaGece}
                  onChange={ev => handleSayfaGeceChange(ev)}
                  ref={input => {
                    inputEl.current[-4 * 12 + 4] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -4 * 12 + 4)}
                  style={{ width: "45%", backgroundColor: "#ffffff" }}
                />
              </td>
            </tr>
            <tr>
              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Yrd.Yönetmen:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="yardimciYonetmen"
                  type="text"
                  defaultValue={isProgramiBaslik.yardimciYonetmen}
                  onChange={ev => handleYardimciYonetmenChange(ev)}
                  ref={input => {
                    inputEl.current[-3 * 12 + 0] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -3 * 12 + 0)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>

              <td style={{ padding: 0, width: (innerwidth / 40) * 3 }}>
                <input
                  id="servis2Yer"
                  type="text"
                  defaultValue={isProgramiBaslik.servis2Yer}
                  onChange={ev => handleServis2YerChange(ev)}
                  ref={input => {
                    inputEl.current[-3 * 12 + 1] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -3 * 12 + 1)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="servis2Saat"
                  type="text"
                  defaultValue={isProgramiBaslik.servis2Saat}
                  onChange={ev => handleServis2SaatChange(ev)}
                  ref={input => {
                    inputEl.current[-3 * 12 + 2] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -3 * 12 + 2)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
              <td className="baslik" style={{ width: (innerwidth / 40) * 3 }}>
                Sayfa Toplam:
              </td>
              <td style={{ padding: 0, width: (innerwidth / 40) * 6 }}>
                <input
                  id="sayfatoplam"
                  type="text"
                  defaultValue={isProgramiBaslik.sayfaToplam}
                  onChange={ev => handleSayfaToplamChange(ev)}
                  ref={input => {
                    inputEl.current[-3 * 12 + 3] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -3 * 12 + 3)}
                  style={{ width: "98%", backgroundColor: "#ffffff" }}
                />
              </td>
            </tr>
            <tr>
              <td className="baslik">Duyuru:</td>
              <td colSpan="6">
                <input
                  id="duyuru"
                  type="text"
                  defaultValue={isProgramiBaslik.duyuru}
                  onChange={ev => handleDuyuruChange(ev)}
                  ref={input => {
                    inputEl.current[-2 * 12 + 0] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -2 * 12 + 0)}
                  style={{
                    width: "98%",
                    backgroundColor: "#ffffff",
                    textAlign: "left"
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="baslik">Notlar:</td>
              <td colSpan="6">
                <input
                  id="notlar"
                  type="text"
                  defaultValue={isProgramiBaslik.notlar}
                  onChange={ev => handleNotlarChange(ev)}
                  ref={input => {
                    inputEl.current[-1 * 12 + 0] = input;
                  }}
                  onKeyDown={ev => handleOnKeyDown(ev, -1 * 12 + 0)}
                  style={{
                    width: "98%",
                    backgroundColor: "#ffffff",
                    textAlign: "left"
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style={{
          width: totalwidth,
          height: screenheight - 440,
          overflowX: "hidden",
          overflowY: "auto",
          padding: 0,
          margin: 0
        }}
      >
        <table
          id="sahneDokumuTable"
          style={{
            width: totalwidth - 24,

            marginLeft: 11,
            padding: 0,
            tableLayout: "fixed"
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  padding: 5,
                  width: 43,
                  borderWidth: 0
                }}
              >
                <input
                  type="checkbox"
                  id={"cbAll"}
                  key={"cbAll"}
                  style={{ width: 25, padding: 0, margin: 0 }}
                  onChange={ev => handleCHKAllChange(ev)}
                  value="checkedA"
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 1 }}>BLM</th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 1 }}>SHN</th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 1 }}>GÜN</th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 3 }}>
                ZAMAN
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 1 }}>SYF</th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 5 }}>
                MEKAN
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 5 }}>
                OYUNCULAR
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 4 }}>
                YRD.OYUNCULAR
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 4 }}>
                AKSESUAR
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 3 }}>
                TEK.MALZEME
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 3 }}>
                PROD.NOTU
              </th>
              <th style={{ padding: 0, width: (innerwidth / 40) * 9 }}>ÖZET</th>
            </tr>
          </thead>
          <tbody
            style={{
              width: totalwidth,
              height: screenheight - 440
            }}
          >
            {isProgramiDetayListe &&
              isProgramiDetayListe.map &&
              isProgramiDetayListe.map(sahne => {
                let bgColor = sahne.sahneDurumRenkKod;
                let fontColor = sahne.sahneDurumYaziRenkKod;
                return (
                  <tr
                    key={"tr" + sahne.lineIndex}
                    style={{
                      padding: 0,
                      backgroundColor: bgColor,
                      color: fontColor,
                      minHeight: 35
                    }}
                  >
                    <td
                      style={{
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 0,
                        paddingRight: 0,
                        backgroundColor: bgColor,
                        color: fontColor
                      }}
                    >
                      <input
                        type="checkbox"
                        id={"cb" + sahne.lineIndex}
                        key={"cb" + sahne.lineIndex}
                        ref={input => {
                          checkEl.current[sahne.lineIndex] = input;
                        }}
                        checked={sahne.checked}
                        style={{ width: 25, padding: 0, margin: 0 }}
                        onChange={ev => handleCHKChange(ev, sahne)}
                        value="checkedA"
                        inputProps={{
                          "aria-label": "primary checkbox"
                        }}
                      />
                    </td>
                    <td style={{ padding: 0, width: (innerwidth / 40) * 1 }}>
                      {sahne.bolumKod}
                      {/* <input
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 0] = input;
                        }}
                        id={"blm" + sahne.lineIndex}
                        key={"blm" + sahne.lineIndex}
                        type="text"
                        defaultValue={sahne.bolumKod}
                        onChange={ev => handleBLMChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 0)
                        }
                        style={{
                          width: "100%",
                          backgroundColor: bgColor,
                          color: fontColor
                        }}
                      /> */}
                    </td>
                    <td style={{ padding: 0, width: (innerwidth / 40) * 1 }}>
                      {sahne.sahneNo}
                      {/* <input
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 1] = input;
                        }}
                        id={"shn" + sahne.lineIndex}
                        key={"shn" + sahne.lineIndex}
                        type="number"
                        defaultValue={sahne.sahneNo}
                        onChange={ev => handleSHNChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 1)
                        }
                        style={{
                          width: "100%",
                          backgroundColor: bgColor,
                          color: fontColor
                        }}
                      /> */}
                    </td>
                    <td style={{ width: (innerwidth / 40) * 1 }}>
                      {sahne.gun}
                      {/* <input
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 2] = input;
                        }}
                        id={"gun" + sahne.lineIndex}
                        key={"gun" + sahne.lineIndex}
                        type="number"
                        defaultValue={sahne.gun}
                        onChange={ev => handleGUNChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 2)
                        }
                        style={{
                          width: "100%",
                          backgroundColor: bgColor,
                          color: fontColor
                        }}
                      /> */}
                    </td>
                    <td style={{ width: (innerwidth / 40) * 3 }}>
                      {sahne.ortamAd}
                      {/* <Select
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 3] = input;
                        }}
                        id={"ort" + sahne.lineIndex}
                        key={"ort" + sahne.lineIndex}
                        defaultValue={{
                          value: sahne.ortamId,
                          label: sahne.ortamAd
                        }}
                        onChange={ev => handleZAMANChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 3)
                        }
                        options={ortamListe}
                        styles={customStyles}
                      /> */}
                    </td>
                    <td style={{ width: (innerwidth / 40) * 1 }}>
                      {sahne.sayfa}
                      {/* <input
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 4] = input;
                        }}
                        id={"syf" + sahne.lineIndex}
                        key={"syf" + sahne.lineIndex}
                        type="number"
                        defaultValue={sahne.sayfa}
                        onChange={ev => handleSayfaChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 4)
                        }
                        style={{
                          width: "100%",
                          backgroundColor: bgColor,
                          color: fontColor
                        }}
                      /> */}
                    </td>
                    <td style={{ width: (innerwidth / 40) * 5 }}>
                      {sahne.mekanAd}
                      {/* <Select
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 5] = input;
                        }}
                        id={"mkn" + sahne.lineIndex}
                        key={"mkn" + sahne.lineIndex}
                        defaultValue={{
                          value: sahne.mekanId,
                          label: sahne.mekanAd
                        }}
                        onChange={ev => handleMEKANChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 5)
                        }
                        options={mekanListe}
                        styles={customStyles}
                      /> */}
                    </td>
                    <td style={{ width: (innerwidth / 40) * 5 }}>
                      {sahne &&
                        sahne.oyuncularListesi &&
                        sahne.oyuncularListesi.map &&
                        sahne.oyuncularListesi.map(item => {
                          return item.label + "-";
                        })}
                      {/* <Select
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 6] = input;
                        }}
                        placeholder="..."
                        id={"oyn" + sahne.lineIndex}
                        key={"oyn" + sahne.lineIndex}
                        defaultValue={sahne.oyuncularListesi}
                        onChange={ev => handleOYUNCULARChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 6)
                        }
                        options={oyuncularListe}
                        isMulti
                        isClearable={false}
                        closeMenuOnSelect={false}
                        styles={customStyles}
                      /> */}
                    </td>
                    <td style={{ width: (innerwidth / 40) * 4 }}>
                      {sahne &&
                        sahne.yardimciOyuncularListesi &&
                        sahne.yardimciOyuncularListesi.map &&
                        sahne.yardimciOyuncularListesi.map(item => {
                          return item.label + "-";
                        })}
                      {/* <Select
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 7] = input;
                        }}
                        placeholder="..."
                        id={"yoy" + sahne.lineIndex}
                        key={"yoy" + sahne.lineIndex}
                        defaultValue={sahne.yardimciOyuncularListesi}
                        onChange={ev =>
                          handleYARDIMCIOYUNCULARChange(ev, sahne)
                        }
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 7)
                        }
                        options={yOyuncularListe}
                        isMulti={true}
                        isClearable={false}
                        isOptionDisabled={false}
                        styles={customStyles}
                      /> */}
                    </td>
                    <td style={{ width: (innerwidth / 40) * 4 }}>
                      {sahne.aksesuar}
                      {/* <Select
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 8] = input;
                        }}
                        placeholder="..."
                        id={"aks" + sahne.lineIndex}
                        key={"aks" + sahne.lineIndex}
                        defaultValue={sahne.aksesuarListesi}
                        onChange={ev => handleAKSESUARChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 8)
                        }
                        options={aksesuarListe}
                        isMulti={true}
                        isClearable={false}
                        styles={customStyles}
                      /> */}
                    </td>
                    <td style={{ width: (innerwidth / 40) * 3 }}>
                      {sahne &&
                        sahne.teknikMalzemeListesi &&
                        sahne.teknikMalzemeListesi.map &&
                        sahne.teknikMalzemeListesi.map(item => {
                          return item.label + "-";
                        })}
                      {/* <Select
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 9] = input;
                        }}
                        placeholder="..."
                        id={"tml" + sahne.lineIndex}
                        key={"tml" + sahne.lineIndex}
                        defaultValue={sahne.teknikMalzemeListesi}
                        onChange={ev => handleTEKNIKMALZEMEChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 9)
                        }
                        options={teknikMalzemeListe}
                        isMulti={true}
                        isClearable={false}
                        styles={customStyles}
                      /> */}
                    </td>
                    <td style={{ width: (innerwidth / 40) * 3 }}>
                      {sahne.produksiyonNot}
                      {/* <textarea
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 10] = input;
                        }}
                        id={"prd" + sahne.lineIndex}
                        key={"prd" + sahne.lineIndex}
                        defaultValue={sahne.produksiyonNot}
                        onChange={ev => handlePNOTChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 10)
                        }
                        style={{
                          width: "98%",
                          backgroundColor: bgColor,
                          color: fontColor,
                          border: 0
                        }}
                      /> */}
                    </td>
                    <td style={{ width: (innerwidth / 40) * 9 }}>
                      {sahne.ozet}
                      {/* <textarea
                        ref={input => {
                          inputEl.current[sahne.lineIndex + 11] = input;
                        }}
                        id={"ozt" + sahne.lineIndex}
                        key={"ozt" + sahne.lineIndex}
                        defaultValue={sahne.ozet}
                        onChange={ev => handleOZETChange(ev, sahne)}
                        onKeyDown={ev =>
                          handleOnKeyDown(ev, sahne.lineIndex + 11)
                        }
                        style={{
                          width: "98%",
                          backgroundColor: bgColor,
                          color: fontColor,
                          border: 0
                        }}
                      /> */}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div
        style={{
          width: totalwidth - 24,
          height: 180,
          padding: 0,
          marginLeft: 11
        }}
      >
        <div
          className="boxLeft"
          style={{
            width: 305,
            height: 180,
            overflowX: "hidden",
            overflowY: "auto",
            padding: 0,
            margin: 0
          }}
        >
          <table
            id="divIsProgramiBaslik"
            style={{
              width: 305,
              border: "1px solid gray",
              borderCollapse: "collapse"
            }}
            cellSpacing="0"
            cellPadding="0"
          >
            <tbody>
              <tr>
                <td
                  colSpan="3"
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  Oyuncular
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: 8,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse",
                    width: 35
                  }}
                />
                <td
                  style={{
                    fontSize: 8,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse",
                    width: 200
                  }}
                >
                  Oyuncu
                </td>
                <td
                  style={{
                    fontSize: 8,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse",
                    width: 70
                  }}
                >
                  Set Saati
                </td>
              </tr>
            </tbody>
          </table>
          <div
            id="divOyuncular"
            className="boxLeft    "
            style={{
              width: 305,
              height: 150,
              overflowX: "hidden",
              overflowY: "auto",
              padding: 0,
              margin: 0
            }}
          >
            <table
              id="divIsProgramiBaslik"
              style={{
                width: 300,
                border: "1px solid gray",
                borderCollapse: "collapse"
              }}
              cellSpacing="0"
            >
              <tbody style={{ fontSize: 12 }}>
                {isProgramiMasrafKalemOyuncularListe &&
                  isProgramiMasrafKalemOyuncularListe.map((mk, index) => {
                    return (
                      <tr id={"trOyn" + index} key={"trOyn" + index}>
                        <td
                          style={{
                            width: 35
                          }}
                        >
                          {index + 1}-)
                        </td>
                        <td
                          style={{
                            width: 200
                          }}
                        >
                          {mk.oyuncuAdSoyad}
                          {/* <Select
                          placeholder="..."
                          id={"oynAd" + index}
                          key={"oynAd" + index}
                          defaultValue={[
                            {
                              value: mk.masrafKalemId,
                              label: mk.masrafKalemAciklama2
                            }
                          ]}
                          onChange={ev =>
                            handleIsProgramiMasrafKalemOYUNCULARChange(ev, mk)
                          }
                          options={oyuncuAdlariListe}
                          isClearable={false}
                          styles={customStylesOyuncu}
                        /> */}
                        </td>
                        <td
                          style={{
                            width: 70
                          }}
                        >
                          <input
                            id={"oynSetSaat" + index}
                            key={"oynSetSaat" + index}
                            type="time"
                            defaultValue={mk.baslamaSaatText}
                            onChange={ev =>
                              handleIsProgramiMasrafKalembaslamaSaatTextChange(
                                ev,
                                mk
                              )
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div
          id="divYardimciOyuncular"
          className="boxLeft"
          style={{
            width: 305,
            height: 180,
            overflowX: "hidden",
            overflowY: "auto",
            padding: 0,
            margin: 0
          }}
        >
          <table
            style={{
              width: 300,
              border: "1px solid gray",
              borderCollapse: "collapse"
            }}
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th
                  colSpan="3"
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  Yardımcı Oyuncular
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    fontSize: 8,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                />
                <th
                  style={{
                    fontSize: 8,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse",
                    width: 200
                  }}
                >
                  Yrd. Oyuncu
                </th>
                <th
                  style={{
                    fontSize: 8,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  Set Saati
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 12 }}>
              {isProgramiMasrafKalemYardimciOyuncularListe &&
                isProgramiMasrafKalemYardimciOyuncularListe.map((mk, index) => {
                  return (
                    <tr
                      id={"trYardimciOyn" + index}
                      key={"trYardimciOyn" + index}
                    >
                      <td>{index + 1}-)</td>
                      <td>
                        {mk.oyuncuAdSoyad}
                        {/* <Select
                          placeholder="..."
                          id={"yardimciOynAd" + index}
                          key={"yardimciOynAd" + index}
                          defaultValue={[
                            {
                              value: mk.masrafKalemId,
                              label: mk.masrafKalemAciklama2
                            }
                          ]}
                          onChange={ev =>
                            handleIsProgramiMasrafKalemYARDIMCIOYUNCULARChange(
                              ev,
                              mk
                            )
                          }
                          options={yardimciOyuncuAdlariListe}
                          isClearable={false}
                          styles={customStylesOyuncu}
                        /> */}
                      </td>
                      <td>
                        <input
                          id={"yardimciOynSetSaat" + index}
                          key={"yardimciOynSetSaat" + index}
                          type="time"
                          defaultValue={mk.baslamaSaatText}
                          onChange={ev =>
                            handleIsProgramiMasrafKalembaslamaSaatTextChange(
                              ev,
                              mk
                            )
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div
          id="divFigurasyon"
          className="boxLeft"
          style={{
            width: 320,
            height: 180,
            overflowX: "hidden",
            overflowY: "auto",
            padding: 0,
            margin: 0
          }}
        >
          <table
            style={{
              width: 300,
              border: "1px solid gray",
              borderCollapse: "collapse"
            }}
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th
                  colSpan="5"
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  Figurasyon
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 12 }}>
              {isProgramiMasrafKalemFigurasyonListe &&
                isProgramiMasrafKalemFigurasyonListe.gruplar &&
                isProgramiMasrafKalemFigurasyonListe.gruplar.map(
                  (mkg, index) => {
                    return (
                      <Fragment key={"fragmentFigurasyon" + mkg.grup}>
                        {/* <tr key={"trFigurasyon" + mkg.grup}>
                          <th
                            key={"thFigurasyon" + mkg.grup}
                            colSpan="5"
                            style={{
                              fontSize: 10,
                              fontWeight: "bold",
                              backgroundColor: "#ddd",
                              border: "1px solid gray",
                              borderCollapse: "collapse"
                            }}
                          >
                            {mkg.grup}
                          </th>
                        </tr> */}
                        <tr>
                          <th
                            rowSpan={mkg.liste.length + 1}
                            style={{
                              fontSize: 8,
                              fontWeight: "bold",
                              backgroundColor: "#ddd",
                              border: "1px solid gray",
                              borderCollapse: "collapse"
                            }}
                          >
                            <span
                              style={{
                                writingMode: "vertical-lr",
                                msWritingMode: "tb-rl",
                                transform: "rotate(180deg)"
                              }}
                            >
                              {mkg.grup}
                            </span>
                          </th>
                          <th
                            style={{
                              fontSize: 8,
                              fontWeight: "bold",
                              backgroundColor: "#ddd",
                              border: "1px solid gray",
                              borderCollapse: "collapse"
                            }}
                          >
                            Figurasyon
                          </th>
                          <th
                            style={{
                              fontSize: 8,
                              fontWeight: "bold",
                              backgroundColor: "#ddd",
                              border: "1px solid gray",
                              borderCollapse: "collapse",
                              width: 200
                            }}
                          >
                            Sayı:
                          </th>

                          <th
                            style={{
                              fontSize: 8,
                              fontWeight: "bold",
                              backgroundColor: "#ddd",
                              border: "1px solid gray",
                              borderCollapse: "collapse"
                            }}
                          >
                            Geliş Saat
                          </th>
                          <th
                            style={{
                              fontSize: 8,
                              fontWeight: "bold",
                              backgroundColor: "#ddd",
                              border: "1px solid gray",
                              borderCollapse: "collapse"
                            }}
                          >
                            Çıkış Saat
                          </th>
                        </tr>
                        {mkg &&
                          mkg.liste &&
                          mkg.liste.map((mk, index) => {
                            return (
                              <tr
                                id={"trFigurasyon" + mkg.grup + index}
                                key={"trFigurasyon" + mkg.grup + index}
                              >
                                <td>{mk.masrafKalemAd}</td>
                                <td>
                                  <input
                                    id={"figurasyonAdet" + mkg.grup + index}
                                    key={"figurasyonAdet" + index}
                                    type="number"
                                    defaultValue={mk.gercekAdet}
                                    style={{ width: 40 }}
                                    onChange={ev =>
                                      handleIsProgramiMasrafKalemgercekAdetChange(
                                        ev,
                                        mk
                                      )
                                    }
                                  />
                                </td>

                                <td>
                                  <input
                                    id={"figurasyonBaslama" + mkg.grup + index}
                                    key={"figurasyonBaslama" + mkg.grup + index}
                                    type="time"
                                    defaultValue={mk.baslamaSaatText}
                                    onChange={ev =>
                                      handleIsProgramiMasrafKalembaslamaSaatTextChange(
                                        ev,
                                        mk
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    id={"figurasyonBitis" + mkg.grup + index}
                                    key={"figurasyonBitis" + mkg.grup + index}
                                    type="time"
                                    defaultValue={mk.bitisSaatText}
                                    onChange={ev =>
                                      handleIsProgramiMasrafKalembitisSaatTextChange(
                                        ev,
                                        mk
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </Fragment>
                    );
                  }
                )}
            </tbody>
          </table>
        </div>

        <div
          id="divYemek"
          className="boxRight"
          style={{
            width: 225,
            height: 180,
            overflowX: "hidden",
            overflowY: "auto",
            padding: 0,
            margin: 0
          }}
        >
          <table
            style={{
              width: 220,
              border: "1px solid gray",
              borderCollapse: "collapse"
            }}
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th
                  colSpan="3"
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  Yemek
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    fontSize: 8,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  Tür
                </th>
                <th
                  style={{
                    fontSize: 8,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse",
                    width: 200
                  }}
                >
                  Sayı:
                </th>

                <th
                  style={{
                    fontSize: 8,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  Saat
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 12 }}>
              {isProgramiMasrafKalemYemekListe &&
                isProgramiMasrafKalemYemekListe.map((mk, index) => {
                  return (
                    <tr id={"trYemek" + index} key={"trYemek" + index}>
                      <td>{mk.masrafKalemAd}</td>
                      <td>
                        <input
                          id={"yemekAdet" + index}
                          key={"yemekAdet" + index}
                          type="number"
                          defaultValue={mk.gercekAdet}
                          style={{ width: 40 }}
                          onChange={ev =>
                            handleIsProgramiMasrafKalemgercekAdetChange(ev, mk)
                          }
                        />
                      </td>

                      <td>
                        <input
                          id={"yemekBaslama" + index}
                          key={"yemekBaslama" + index}
                          type="time"
                          defaultValue={mk.baslamaSaatText}
                          onChange={ev =>
                            handleIsProgramiMasrafKalembaslamaSaatTextChange(
                              ev,
                              mk
                            )
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div
          id="divFGRServisleri"
          className="boxRight "
          style={{
            width: 120
          }}
        >
          <table
            style={{
              width: 120,
              border: "1px solid gray",
              borderCollapse: "collapse"
            }}
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th
                  colSpan="3"
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  FGR Servisleri
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 8 }}>
              <tr>
                <td
                  style={{
                    fontSize: 8,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  Dagıtımlı
                </td>
                <td
                  style={{
                    fontSize: 8,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  Dagıtımsız
                </td>
                <td
                  style={{
                    fontSize: 8,
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  Evden-Top.
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: 8,
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  <input
                    id="servisDagitimli"
                    type="text"
                    defaultValue={isProgramiBaslik.servisDagitimli}
                    onChange={ev => handleServisDagitimliChange(ev)}
                    style={{
                      width: "98%",
                      backgroundColor: "#ffffff",
                      border: 0
                    }}
                  />
                </td>
                <td
                  style={{
                    fontSize: 8,
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  <input
                    id="servisDagitimsiz"
                    type="text"
                    defaultValue={isProgramiBaslik.servisDagitimsiz}
                    onChange={ev => handleServisDagitimsizChange(ev)}
                    style={{
                      width: "98%",
                      backgroundColor: "#ffffff",
                      border: 0
                    }}
                  />
                </td>
                <td
                  style={{
                    fontSize: 8,
                    backgroundColor: "#ddd",
                    border: "1px solid gray",
                    borderCollapse: "collapse"
                  }}
                >
                  <input
                    id="ServisEvdenToplama"
                    type="text"
                    defaultValue={isProgramiBaslik.servisEvdenToplama}
                    onChange={ev => handleServisEvdenToplamaChange(ev)}
                    style={{
                      width: "98%",
                      backgroundColor: "#ffffff",
                      border: 0
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="divFooter1" className="boxWidth100" style={{ paddingTop: 5 }}>
        <div className="boxLeft boxWidth15 boxBorderGray">
          Produksiyona Özel Not
        </div>
        <div className="boxLeft boxWidth80 boxBorderGray">
          <input
            id="produksiyonaOzelNot"
            type="text"
            defaultValue={isProgramiBaslik.produksiyonaOzelNot}
            onChange={ev => handleProduksiyonaOzelNotChange(ev)}
            style={{
              width: "98%",
              backgroundColor: "#ffffff",
              border: 0
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default IsProgrami;
