import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import "../App.css";
import "./Login.css";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import LoginPNG from "./login.png";

const Login = () => {
  const { url } = useApiURL();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const handleChangeUserName = e => {
    setUserName(e.target.value);
  };
  const handleChangePassword = e => {
    setPassword(e.target.value);
  };

  /**************************************/
  const [screenwidth, setWidth] = useState(window.innerWidth);
  const [screenheight, setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1359.0;
    var oh = 768.0;
    var oo = ow / oh;
    if (oo > o) {
      setBoyutOran(w / ow);
    } else if (oo < o) {
      setBoyutOran(h / oh);
    }
  };

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  /**************************************/

  const handleClick = event => {
    var LoginRequest = {
      KullaniciAd: userName,
      Sifre: password
    };
    console.log(LoginRequest.KullaniciAd);
    console.log(LoginRequest.Sifre);
    axios
      .post(url + "/api/Login/GetToken", LoginRequest)
      .then(function(response) {
        try {
          console.log(response);
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log("Login successfull");
              localStorage.setItem("KullaniciAd", userName);
              localStorage.setItem("Token", response.data.token);
              localStorage.setItem("KurumId", response.data.kurumId);
              window.location.reload();
            } else {
              alert(response.data.responseMessage);
            }
          } else if (response.status === 204) {
            alert("Kullanıcı adı ya da şifre yanlış");
          } else {
            alert("Kullanıcı adı ya da şifre yanlış");
          }
        } catch (error) {
          alert(error);
        }
      })
      .catch(function(error) {
        console.log(error);
        alert(error);
      });
  };

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        console.log("submitted");
      }}
    >
      <img
        src={LoginPNG}
        alt="Login Image"
        style={{ width: 1359 * boyutOran }}
      ></img>
      <div
        id="divEkran"
        style={{
          position: "absolute",
          top: 250 * boyutOran,
          left: 1020 * boyutOran
        }}
      >
        <div
          className="boxRight boxBorderTransparent"
          style={{
            width: 240,
            transform: "scale(" + boyutOran + ", " + boyutOran + ")",
            transformOrigin: "top left"
          }}
        >
          Kullanıcı
          <br />
          <TextField
            variant="outlined"
            onChange={handleChangeUserName}
            value={userName}
          />
          <br />
          Şifre
          <br />
          <TextField
            variant="outlined"
            type="password"
            onChange={handleChangePassword}
          />
          <br />
          <br />
          <div className="boxRight">
            <Button variant="outlined" type="submit" onClick={handleClick}>
              Giriş
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
export default Login;
