import React, { useState } from "react";
import config from "devextreme/core/config";
import DataGrid, { Column, Editing, Lookup } from "devextreme-react/data-grid";
import { SpeedDialAction } from "devextreme-react/speed-dial-action";
import { employees, states } from "./data.js";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";

config({
  floatingActionButtonConfig: {
    icon: "rowfield",
    position: {
      of: "#grid",
      my: "right bottom",
      at: "right bottom",
      offset: "-16 -16"
    }
  }
});

const GenelAyarlar = props => {
  const { url } = useApiURL();

  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  var grid = null;

  function selectionChanged(e) {
    setSelectedRowIndex(e.component.getRowIndexByKey(e.selectedRowKeys[0]));
  }
  function editRow() {
    grid.instance.editRow(selectedRowIndex);
    grid.instance.deselectAll();
  }
  function deleteRow() {
    grid.instance.deleteRow(selectedRowIndex);
    grid.instance.deselectAll();
  }

  function addRow() {
    grid.instance.addRow();
  }

  return (
    <div>
      <DataGrid
        id={"grid"}
        dataSource={employees}
        keyExpr={"ID"}
        ref={ref => (grid = ref)}
        selection={{ mode: "single" }}
        showBorders={true}
        onSelectionChanged={selectionChanged}
      >
        <Column dataField={"Prefix"} caption={"Title"} />
        <Column dataField={"FirstName"} />
        <Column dataField={"LastName"} />
        <Column dataField={"Position"} width={130} />
        <Column dataField={"StateID"} caption={"State"} width={125}>
          <Lookup dataSource={states} valueExpr={"ID"} displayExpr={"Name"} />
        </Column>
        <Column dataField={"BirthDate"} dataType={"date"} width={125} />
        <Editing mode={"popup"}></Editing>
      </DataGrid>
      <SpeedDialAction icon={"add"} hint={"Add row"} onClick={addRow} />
      {selectedRowIndex !== undefined && selectedRowIndex !== -1 && (
        <div>
          <SpeedDialAction
            icon={"trash"}
            hint={"Delete row"}
            onClick={deleteRow}
          />
          <SpeedDialAction icon={"edit"} hint={"Edit row"} onClick={editRow} />
        </div>
      )}
    </div>
  );
};

export default GenelAyarlar;
