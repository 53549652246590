import React, { Fragment, useRef, createRef, useState, useEffect } from "react";
import "../App.css";
import { useWindowResize } from "../Custom-Hooks/useWindowResize";
import axios from "axios";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { DotLoader } from "react-spinners";

const BolumMasrafKalem = sutunlar => {
  const { url } = useApiURL();
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [projeId] = useState(localStorage.getItem("ProjeId"));
  const [bolumId, setBolumId] = useState(localStorage.getItem("BolumId"));
  const [bolumMasrafKalem,] = React.useState([{}]);
  const [colcolSpan] = React.useState(
    sutunlar.gerceklesen === true ? 1 : 2
  );

  let inputEl = useRef([createRef()]);
  const [bolumDetayListe, setBolumDetayListe] = useState([]);
  const [masrafKalemLoading, setMasrafKalemLoading] = useState(false);
  function printDocument() {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then(canvas => {  
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("BolumMasraf.pdf");
    });
  }
  function logOut() {
    setToken("");
    localStorage.removeItem("BolumId");
    localStorage.removeItem("MenuId");
    localStorage.removeItem("Token");
    localStorage.removeItem("IsProgramiBaslikId");
    window.location.reload();
  }
  function handleKaydetClick(event) {
    saveBolumMasrafKalem(token, projeId, bolumId, bolumMasrafKalem);
  }
  function handleOnKeyDown(event, inputIndex) {
    try {
      if (
        !(
          event.key === "ArrowRight" ||
          event.key === "ArrowLeft" ||
          event.key === "ArrowDown" ||
          event.key === "Enter" ||
          event.key === "PageDown" ||
          event.key === "PageUp" ||
          event.key === "ArrowUp"
        )
      )
        return;
      event.preventDefault();
      var elementToFocus;
      var elementToFocusIndex = inputIndex;
      var findIndex = 0;
      if (event.key === "ArrowRight") {
        findIndex = 1;
      }
      if (event.key === "ArrowLeft") {
        findIndex = -1;
      }
      if (event.key === "ArrowDown") {
        findIndex = 12;
      }
      if (event.key === "Enter") {
        findIndex = 1;
      }
      if (event.key === "ArrowUp") {
        findIndex = -12;
      }
      if (event.key === "PageDown") {
        findIndex = 120;
      }
      if (event.key === "PageUp") {
        findIndex = -120;
      }
      console.log(elementToFocusIndex);
      for (let i = 1; i < 12; i++) {
        elementToFocusIndex = inputIndex + findIndex * i;
        console.log(elementToFocusIndex);
        elementToFocus = inputEl.current[elementToFocusIndex];
        if (elementToFocus != null) break;
      }

      elementToFocus.focus();
      try {
        elementToFocus.select();
      } catch {}
    } catch (err) {
      console.log(elementToFocus);
      console.log(err.message);
    }
  }

  function handleBolumSecClick(event) {
    localStorage.removeItem("BolumId");
    localStorage.removeItem("MenuId");
    localStorage.removeItem("IsProgramiBaslikId");
    window.location.reload();
  }
  function handleMenuSecClick(event) {
    localStorage.removeItem("MenuId");
    localStorage.removeItem("IsProgramiBaslikId");
    window.location.reload();
  }
  function handleYenileClick(event) {
    setBolumId(0);
    setTimeout(() => {
      setBolumId(bolumId);
    }, 10);
  }
  const saveBolumMasrafKalem = (token, projeId, bolumId, bolumMasrafKalem) => {
    var bmkList = bolumMasrafKalem.map(line => JSON.stringify(line));

    var requestModel = {
      projeId: projeId,
      bolumId: bolumId,
      bolumMasrafKalem: bmkList,
      token
    };
    console.log(requestModel);
    axios
      .post(url + "/api/SahneListe/BolumMasrafKalemKaydet", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
            
              if (res == null) return;
              var res = response.data.responseObject;
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  useEffect(() => {}, [bolumDetayListe]);
  useEffect(() => {
    document.title = `CineCost Bölüm Masraf Kalemleri`;
  }, [token]);
  useEffect(() => {
    loadBolumDetay(projeId, bolumId, token);
  }, [projeId, bolumId, token]);
  const loadBolumDetay = (projeId, bolumId, token) => {
    var requestModel = {
      ProjeId: projeId,
      BolumId: bolumId,
      Token: token
    };

    setMasrafKalemLoading(true);
    console.log(requestModel);
    console.log(sutunlar);

    axios
      .post(url + "/api/SahneListe/BolumMasrafKalemListeGrup", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              var res = response.data.responseObject;
              console.log(res);
              setBolumDetayListe(res);
              setMasrafKalemLoading(false);
            } else if (response.data.responseCode === 401) {
              console.log(response);
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const { screenwidth, screenheight } = useWindowResize();
  var totalwidth = Math.max(screenwidth, 1366) - 6;
  const [innerwidth] = useState(totalwidth - 86);
  function handleBMKChange(bmk) {
    bmk.gercekTutar4 =
      parseFloat(bmk.gercekTutar1) +
      parseFloat(bmk.gercekTutar2) +
      parseFloat(bmk.gercekTutar3);
    bmk.butceTutar4 =
      parseFloat(bmk.butceTutar1) +
      parseFloat(bmk.butceTutar2) +
      parseFloat(bmk.butceTutar3);
    console.log(bmk.bolumMasrafKalemId);
    console.log(bmk);
    var bmkNew = bmk;
    var f = bolumMasrafKalem.find(
      item => item.bolumMasrafKalemId === bmk.bolumMasrafKalemId
    );
    console.log(f);
    if (f == null) bolumMasrafKalem.push(bmkNew);
    else {
      f.butceAdet = bmk.butceAdet;
      f.gercekAdet = bmk.gercekAdet;
      f.butceTutar1 = bmk.butceTutar1;
      f.butceTutar2 = bmk.butceTutar2;
      f.butceTutar3 = bmk.butceTutar3;
      f.butceTutar4 = bmk.butceTutar4;
      f.gercekTutar1 = bmk.gercekTutar1;
      f.gercekTutar2 = bmk.gercekTutar2;
      f.gercekTutar3 = bmk.gercekTutar3;
      f.gercekTutar4 = bmk.gercekTutar4;
    }
    console.log(bolumMasrafKalem);
  }

  // select  'function handle'+Name+'Change(evt, bmk) {    bmk.'+lower(substring(name,1,1))+substring(name,2,20)+' = evt.target.value; handleBMKChange(bmk); }' from syscolumns where id = object_id('vw_BolumMasrafKalem')
   
function handleButceAdetChange(evt, bmk) {    bmk.butceAdet = evt.target.value; handleBMKChange(bmk); }
function handleButceTutar1Change(evt, bmk) {    bmk.butceTutar1 = evt.target.value; handleBMKChange(bmk); }
function handleButceTutar2Change(evt, bmk) {    bmk.butceTutar2 = evt.target.value; handleBMKChange(bmk); }
function handleButceTutar3Change(evt, bmk) {    bmk.butceTutar3 = evt.target.value; handleBMKChange(bmk); }
function handleButceTutar4Change(evt, bmk) {    bmk.butceTutar4 = evt.target.value; handleBMKChange(bmk); } 
function handleGercekAdetChange(evt, bmk) {    bmk.gercekAdet = evt.target.value; handleBMKChange(bmk); }
function handleGercekTutar1Change(evt, bmk) {    bmk.gercekTutar1 = evt.target.value; handleBMKChange(bmk); }
function handleGercekTutar2Change(evt, bmk) {    bmk.gercekTutar2 = evt.target.value; handleBMKChange(bmk); }
function handleGercekTutar3Change(evt, bmk) {    bmk.gercekTutar3 = evt.target.value; handleBMKChange(bmk); }
function handleGercekTutar4Change(evt, bmk) {    bmk.gercekTutar4 = evt.target.value; handleBMKChange(bmk); }
 
  // Auto Genarated Bitti
  var lineIndex = 0;
  // console.log((innerwidth / 40) * 1);
  return (
    <Fragment>
      <div
        id="divBaslik"
        style={{
          width: totalwidth,
          height: 80,
          padding: 0,
          margin: 0
        }}
      >
        <div
          style={{
            height: 20,
            padding: 0,
            margin: 0,
            width: 500
          }}
          className="boxLeft"
        >
          <div
            className="sahneDokumuKaydet boxLeft"
            onClick={ev => handleKaydetClick(ev)}
          >
            Kaydet
          </div>

          <div
            className="sahneDokumuBolumSec boxLeft"
            onClick={ev => handleBolumSecClick(ev)}
          >
            Bölüm Seç
          </div>
          <div
            className="sahneDokumuBolumSec boxLeft"
            onClick={ev => handleMenuSecClick(ev)}
          >
            Menü Seç
          </div>
          <div
            className="sahneDokumuBolumSec boxLeft"
            onClick={ev => handleYenileClick(ev)}
          >
            Yenile
          </div>
        </div>
        <div className="boxRight">
          <button onClick={ev => printDocument(ev)}>PDF</button>
        </div>
      </div>

      <div
        id="divToPrint"
        style={{
          width: totalwidth,
          //height: screenheight - 440,
          overflowX: "hidden",
          overflowY: "auto",
          padding: 0,
          margin: 0
        }}
      >
        {masrafKalemLoading && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <div
              style={{
                  textAlign: "center",
                width: screenwidth / 2 - (screenheight - 240) / 2,
                float: "left"
              }}
            >
              {" "}
              &nbsp;
            </div>
            <div
              style={{
                textAlign: "center",
                width: screenheight - 240,
                float: "left"
              }}
            >
              <DotLoader
                style={{
                  display: "block",
                  margin: "0 auto",
                  borderColor: "red"
                }}
                sizeUnit={"px"}
                size={screenheight - 240}
                color={"#123abc"}
                loading={masrafKalemLoading}
              />
            </div>
          </div>
        )}
        <table
          id="divBolumMasrafKalemTablo"
          style={{
            width: innerwidth,
            marginLeft:40
          }}
        >
          <thead />
          <tbody
            style={{
              width: innerwidth
            }}
          >
            {bolumDetayListe &&
              bolumDetayListe.map &&
              bolumDetayListe.map((parent1, mapIndex1) => {
                return (
                  <Fragment key={"fragment" + mapIndex1}>
                    <tr>
                      <th
                        colSpan="14"
                        style={{
                          backgroundColor: "#305496",
                          color: "white",
                          fontSize: 24,
                          fontStyle: "italic",
                          fontWeight: "bold"
                        }}
                      >
                        {parent1.grup.masrafKalemAd}
                      </th>
                    </tr>
                    {parent1 &&
                      parent1.gruplar.map((parent2, mapIndex2) => {
                        console.log("sutunlar");
                        

                        return (
                          <Fragment
                            key={"fragment" + parent2.grup.masrafKalemId}
                          >
                            <tr>
                              <th
                                colSpan="3"
                                style={{
                                  fontSize: 20,
                                  textAlign: "left",
                                  fontStyle: "italic",
                                  fontWeight: "bold",
                                  height:30,
                                  borderRightWidth:0, 
                                }}
                              >&nbsp;&nbsp;
                                {parent2.grup.masrafKalemAd}
                              </th>
                              <th colSpan="2" style={{ borderLeftWidth:0,  borderRightWidth:0, }} >&nbsp;</th>
                              <th colSpan="2" style={{ borderLeftWidth:0,  borderRightWidth:0, }} >&nbsp;</th>
                              <th colSpan="1" style={{ borderLeftWidth:0,  borderRightWidth:0, }} >&nbsp;</th>
                              <th colSpan="1" style={{ borderLeftWidth:0,  borderRightWidth:0, }} >&nbsp;</th>
                              <th colSpan="5" style={{borderLeftWidth:0,  }} >&nbsp;</th>
                            </tr>
                            <tr>
                            
                              <th
                                style={{
                                  padding: 0,
                                  width: (innerwidth / 40) * 5,
                                  fontSize: 16,
                                  fontStyle: "italic",
                                  fontWeight: "bold",
                                  backgroundColor: "white",
                                  color: "black"
                                }}
                              >
                                {parent2.grup.masrafKalemAciklama1}
                              </th>
                              <th
                                style={{
                                  padding: 0,
                                  width: (innerwidth / 40) * 5,
                                  fontSize: 16,
                                  fontStyle: "italic",
                                  fontWeight: "bold",
                                  backgroundColor: "white",
                                  color: "black"
                                }}
                              >
                                {parent2.grup.masrafKalemAciklama2}
                              </th>
                              <th
                                style={{
                                  padding: 0,
                                  width: (innerwidth / 40) * 3,
                                  fontSize: 16,
                                  fontStyle: "italic",
                                  fontWeight: "bold",
                                  backgroundColor: "white",
                                  color: "black"
                                }}
                              >
                                {parent2.grup.masrafKalemAciklama3}
                              </th>
                              {sutunlar.ongorulen === true && (
                                <th
                                  style={{
                                    padding: 0,
                                    width: (innerwidth / 40) * 1,
                                    backgroundColor: "#ED7D31",
                                    color: "white",
                                    fontSize: 16,
                                    fontStyle: "italic",
                                    fontWeight: "bold"
                                  }}
                                  colSpan={colcolSpan}
                                >
                                  AD.
                                </th>
                              )}
                              {sutunlar.gerceklesen === true && (
                                <th
                                  style={{
                                    padding: 0,
                                    width: (innerwidth / 40) * 1,
                                    backgroundColor: "#A9D08E",
                                    fontSize: 16,
                                    fontStyle: "italic",
                                    fontWeight: "bold"
                                  }}
                                >
                                  AD.
                                </th>
                              )}
                              {sutunlar.ongorulen === true && (
                                <th
                                  style={{
                                    padding: 0,
                                    width: (innerwidth / 40) * 3,
                                    backgroundColor: "#ED7D31",
                                    color: "white",
                                    fontSize: 16,
                                    fontStyle: "italic",
                                    fontWeight: "bold"
                                  }}
                                  colSpan={colcolSpan}
                                >
                                  {parent2.grup.tutarAciklama1}
                                </th>
                              )}
                              {sutunlar.gerceklesen === true && (
                                <th
                                  style={{
                                    padding: 0,
                                    width: (innerwidth / 40) * 3,
                                    backgroundColor: "#A9D08E",
                                    fontSize: 16,
                                    fontStyle: "italic",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {parent2.grup.tutarAciklama1}
                                </th>
                              )}
                              {sutunlar.ongorulen === true && (
                                <th
                                  style={{
                                    padding: 0,
                                    width: (innerwidth / 40) * 3,
                                    backgroundColor: "#ED7D31",
                                    color: "white",
                                    fontSize: 16,
                                    fontStyle: "italic",
                                    fontWeight: "bold"
                                  }}
                                  colSpan={colcolSpan}
                                >
                                  {parent2.grup.tutarAciklama2}
                                </th>
                              )}
                              {sutunlar.gerceklesen === true && (
                                <th
                                  style={{
                                    padding: 0,
                                    width: (innerwidth / 40) * 3,
                                    backgroundColor: "#A9D08E",
                                    fontSize: 16,
                                    fontStyle: "italic",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {parent2.grup.tutarAciklama2}
                                </th>
                              )}
                              {sutunlar.ongorulen === true && (
                                <th
                                  style={{
                                    padding: 0,
                                    width: (innerwidth / 40) * 3,
                                    backgroundColor: "#ED7D31",
                                    color: "white",
                                    fontSize: 16,
                                    fontStyle: "italic",
                                    fontWeight: "bold"
                                  }}
                                  colSpan={colcolSpan}
                                >
                                  {parent2.grup.tutarAciklama3}
                                </th>
                              )}
                              {sutunlar.gerceklesen === true && (
                                <th
                                  style={{
                                    padding: 0,
                                    width: (innerwidth / 40) * 3,
                                    backgroundColor: "#A9D08E",
                                    fontSize: 16,
                                    fontStyle: "italic",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {parent2.grup.tutarAciklama3}
                                </th>
                              )}
                              {sutunlar.ongorulen === true && (
                                <th
                                  style={{
                                    padding: 0,
                                    width: (innerwidth / 40) * 3,
                                    backgroundColor: "#ED7D31",
                                    color: "white",
                                    fontSize: 16,
                                    fontStyle: "italic",
                                    fontWeight: "bold"
                                  }}
                                  colSpan={colcolSpan}
                                >
                                  {parent2.grup.tutarAciklama4}
                                </th>
                              )}
                              {sutunlar.gerceklesen === true && (
                                <th
                                  style={{
                                    padding: 0,
                                    width: (innerwidth / 40) * 3,
                                    backgroundColor: "#A9D08E",
                                    fontSize: 16,
                                    fontStyle: "italic",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {parent2.grup.tutarAciklama4}
                                </th>
                              )}
                              <th
                                className=""
                                style={{
                                  padding: 0,
                                  width: 43,
                                  fontSize: 16,
                                  fontStyle: "italic",
                                  fontWeight: "bold"
                                }}
                              >
                                DR.
                              </th>
                            </tr>
                            {parent2 &&
                              parent2.gruplar.map((bmk, mapIndex3) => {
                                var sahne = bmk.grup;

                                sahne.lineIndex = lineIndex;
                                lineIndex = lineIndex + 12;
                                return (
                                  <tr
                                    key={"tr" + sahne.bolumMasrafKalemId}
                                    style={{
                                      padding: 0
                                    }}
                                  >
                                   
                                    <td>{sahne.masrafKalemAd}</td>
                                    <td>{sahne.masrafKalemAciklama1}</td>
                                    <td>{sahne.masrafKalemAciklama2}</td>

                                    {sutunlar.ongorulen === true && (
                                      <td
                                        style={{
                                          backgroundColor: "#ED7D31",
                                          color: "white"
                                        }}
                                        colSpan={colcolSpan}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              sahne.lineIndex + 1
                                            ] = input;
                                          }}
                                          id={
                                            "butceAdet" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          key={
                                            "butceAdet" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={sahne.butceAdet}
                                          onChange={ev =>
                                            handleButceAdetChange(ev, sahne)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              sahne.lineIndex + 1
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#ED7D31",
                                            color: "white"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.gerceklesen === true && (
                                      <td
                                        style={{ backgroundColor: "#A9D08E" }}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              sahne.lineIndex + 2
                                            ] = input;
                                          }}
                                          id={
                                            "gercekAdet" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          key={
                                            "gercekAdet" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={sahne.gercekAdet}
                                          onChange={ev =>
                                            handleGercekAdetChange(ev, sahne)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              sahne.lineIndex + 2
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#A9D08E"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.ongorulen === true && (
                                      <td
                                        style={{
                                          backgroundColor: "#ED7D31",
                                          color: "white"
                                        }}
                                        colSpan={colcolSpan}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              sahne.lineIndex + 3
                                            ] = input;
                                          }}
                                          id={
                                            "butceTutar1" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          key={
                                            "butceTutar1" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={sahne.butceTutar1}
                                          onChange={ev =>
                                            handleButceTutar1Change(ev, sahne)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              sahne.lineIndex + 3
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#ED7D31",
                                            color: "white"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.gerceklesen === true && (
                                      <td
                                        style={{ backgroundColor: "#A9D08E" }}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              sahne.lineIndex + 4
                                            ] = input;
                                          }}
                                          id={
                                            "gercekTutar1" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          key={
                                            "gercekTutar1" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={sahne.gercekTutar1}
                                          onChange={ev =>
                                            handleGercekTutar1Change(ev, sahne)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              sahne.lineIndex + 4
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#A9D08E"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.ongorulen === true && (
                                      <td
                                        style={{
                                          backgroundColor: "#ED7D31",
                                          color: "white"
                                        }}
                                        colSpan={colcolSpan}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              sahne.lineIndex + 5
                                            ] = input;
                                          }}
                                          id={
                                            "butceTutar2" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          key={
                                            "butceTutar2" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={sahne.butceTutar2}
                                          onChange={ev =>
                                            handleButceTutar2Change(ev, sahne)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              sahne.lineIndex + 5
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#ED7D31",
                                            color: "white"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.gerceklesen === true && (
                                      <td
                                        style={{ backgroundColor: "#A9D08E" }}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              sahne.lineIndex + 6
                                            ] = input;
                                          }}
                                          id={
                                            "gercekTutar2" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          key={
                                            "gercekTutar2" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={sahne.gercekTutar2}
                                          onChange={ev =>
                                            handleGercekTutar2Change(ev, sahne)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              sahne.lineIndex + 6
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#A9D08E"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.ongorulen === true && (
                                      <td
                                        style={{
                                          backgroundColor: "#ED7D31",
                                          color: "white"
                                        }}
                                        colSpan={colcolSpan}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              sahne.lineIndex + 7
                                            ] = input;
                                          }}
                                          id={
                                            "butceTutar3" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          key={
                                            "butceTutar3" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={sahne.butceTutar3}
                                          onChange={ev =>
                                            handleButceTutar3Change(ev, sahne)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              sahne.lineIndex + 7
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#ED7D31",
                                            color: "white"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.gerceklesen === true && (
                                      <td
                                        style={{ backgroundColor: "#A9D08E" }}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              sahne.lineIndex + 8
                                            ] = input;
                                          }}
                                          id={
                                            "gercekTutar3" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          key={
                                            "gercekTutar3" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={sahne.gercekTutar3}
                                          onChange={ev =>
                                            handleGercekTutar3Change(ev, sahne)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              sahne.lineIndex + 8
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#A9D08E"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.ongorulen && (
                                      <td
                                        style={{
                                          backgroundColor: "#ED7D31",
                                          color: "white"
                                        }}
                                        colSpan={colcolSpan}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              sahne.lineIndex + 9
                                            ] = input;
                                          }}
                                          id={
                                            "butceTutar4" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          key={
                                            "butceTutar4" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={sahne.butceTutar4}
                                          onChange={ev =>
                                            handleButceTutar4Change(ev, sahne)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              sahne.lineIndex + 9
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#ED7D31",
                                            color: "white"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.gerceklesen === true && (
                                      <td
                                        style={{ backgroundColor: "#A9D08E" }}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              sahne.lineIndex + 10
                                            ] = input;
                                          }}
                                          id={
                                            "gercekTutar4" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          key={
                                            "gercekTutar4" +
                                            sahne.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={sahne.gercekTutar4}
                                          onChange={ev =>
                                            handleGercekTutar4Change(ev, sahne)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              sahne.lineIndex + 10
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#A9D08E"
                                          }}
                                        />
                                      </td>
                                    )}
                                    <td></td>
                                  </tr>
                                );
                              })}

{/* ----------------------Toplam-------------------------- */}

<tr
                                    key={"trT" + parent2.grup.bolumMasrafKalemId}
                                    style={{
                                      padding: 0,
                                      height:30,
                                      borderBottomWidth:3, borderBottomStyle:"solid"
                                      ,borderColor:"black"
                                    }}
                                  >
                                   
                                    <td colSpan="3" style={{fontSize:20, textAlign:"right", fontStyle:"italic", fontWeight:"bold", 
                                     }}> Toplam:  &nbsp;&nbsp;</td>

                                    {sutunlar.ongorulen === true && (
                                      <td
                                        style={{
                                          backgroundColor: "#ED7D31",
                                          color: "white"
                                        }}
                                        colSpan={colcolSpan}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              parent2.grup.lineIndex + 1
                                            ] = input;
                                          }}
                                          id={
                                            "butceAdetToplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          key={
                                            "butceAdetToplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={parent2.grup.butceAdet}
                                          onChange={ev =>
                                            handleButceAdetChange(ev, parent2.grup)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              parent2.grup.lineIndex + 1
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#ED7D31",
                                            color: "white"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.gerceklesen === true && (
                                      <td
                                        style={{ backgroundColor: "#A9D08E" }}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              parent2.grup.lineIndex + 2
                                            ] = input;
                                          }}
                                          id={
                                            "gercekAdetToplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          key={
                                            "gercekAdetToplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={parent2.grup.gercekAdet}
                                          onChange={ev =>
                                            handleGercekAdetChange(ev, parent2.grup)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              parent2.grup.lineIndex + 2
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#A9D08E"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.ongorulen === true && (
                                      <td
                                        style={{
                                          backgroundColor: "#ED7D31",
                                          color: "white"
                                        }}
                                        colSpan={colcolSpan}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              parent2.grup.lineIndex + 3
                                            ] = input;
                                          }}
                                          id={
                                            "butceTutar1Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          key={
                                            "butceTutar1Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={parent2.grup.butceTutar1}
                                          onChange={ev =>
                                            handleButceTutar1Change(ev, parent2.grup)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              parent2.grup.lineIndex + 3
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#ED7D31",
                                            color: "white"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.gerceklesen === true && (
                                      <td
                                        style={{ backgroundColor: "#A9D08E" }}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              parent2.grup.lineIndex + 4
                                            ] = input;
                                          }}
                                          id={
                                            "gercekTutar1Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          key={
                                            "gercekTutar1Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={parent2.grup.gercekTutar1}
                                          onChange={ev =>
                                            handleGercekTutar1Change(ev, parent2.grup)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              parent2.grup.lineIndex + 4
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#A9D08E"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.ongorulen === true && (
                                      <td
                                        style={{
                                          backgroundColor: "#ED7D31",
                                          color: "white"
                                        }}
                                        colSpan={colcolSpan}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              parent2.grup.lineIndex + 5
                                            ] = input;
                                          }}
                                          id={
                                            "butceTutar2Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          key={
                                            "butceTutar2Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={parent2.grup.butceTutar2}
                                          onChange={ev =>
                                            handleButceTutar2Change(ev, parent2.grup)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              parent2.grup.lineIndex + 5
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#ED7D31",
                                            color: "white"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.gerceklesen === true && (
                                      <td
                                        style={{ backgroundColor: "#A9D08E" }}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              parent2.grup.lineIndex + 6
                                            ] = input;
                                          }}
                                          id={
                                            "gercekTutar2Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          key={
                                            "gercekTutar2Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={parent2.grup.gercekTutar2}
                                          onChange={ev =>
                                            handleGercekTutar2Change(ev, parent2.grup)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              parent2.grup.lineIndex + 6
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#A9D08E"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.ongorulen === true && (
                                      <td
                                        style={{
                                          backgroundColor: "#ED7D31",
                                          color: "white"
                                        }}
                                        colSpan={colcolSpan}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              parent2.grup.lineIndex + 7
                                            ] = input;
                                          }}
                                          id={
                                            "butceTutar3Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          key={
                                            "butceTutar3Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={parent2.grup.butceTutar3}
                                          onChange={ev =>
                                            handleButceTutar3Change(ev, parent2.grup)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              parent2.grup.lineIndex + 7
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#ED7D31",
                                            color: "white"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.gerceklesen === true && (
                                      <td
                                        style={{ backgroundColor: "#A9D08E" }}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              parent2.grup.lineIndex + 8
                                            ] = input;
                                          }}
                                          id={
                                            "gercekTutar3Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          key={
                                            "gercekTutar3Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={parent2.grup.gercekTutar3}
                                          onChange={ev =>
                                            handleGercekTutar3Change(ev, parent2.grup)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              parent2.grup.lineIndex + 8
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#A9D08E"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.ongorulen && (
                                      <td
                                        style={{
                                          backgroundColor: "#ED7D31",
                                          color: "white"
                                        }}
                                        colSpan={colcolSpan}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              parent2.grup.lineIndex + 9
                                            ] = input;
                                          }}
                                          id={
                                            "butceTutar4Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          key={
                                            "butceTutar4Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={parent2.grup.butceTutar4}
                                          onChange={ev =>
                                            handleButceTutar4Change(ev, parent2.grup)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              parent2.grup.lineIndex + 9
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#ED7D31",
                                            color: "white"
                                          }}
                                        />
                                      </td>
                                    )}
                                    {sutunlar.gerceklesen === true && (
                                      <td
                                        style={{ backgroundColor: "#A9D08E" }}
                                      >
                                        <input
                                          ref={input => {
                                            inputEl.current[
                                              parent2.grup.lineIndex + 10
                                            ] = input;
                                          }}
                                          id={
                                            "gercekTutar4Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          key={
                                            "gercekTutar4Toplam" +
                                            parent2.grup.bolumMasrafKalemId
                                          }
                                          type="text"
                                          defaultValue={parent2.grup.gercekTutar4}
                                          onChange={ev =>
                                            handleGercekTutar4Change(ev, parent2.grup)
                                          }
                                          onKeyDown={ev =>
                                            handleOnKeyDown(
                                              ev,
                                              parent2.grup.lineIndex + 10
                                            )
                                          }
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#A9D08E"
                                          }}
                                        />
                                      </td>

                                    )}
                                    <td></td>
                                  </tr>

{/* ---------------------Toplam Bitti--------------------- */}
                            <tr>
                              <td colSpan="14" style={{ borderLeftWidth:0,  borderRightWidth:0, }}>
                                <br></br>
                                <br></br>
                                <br></br>
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}

                    <tr>
                      <td colSpan="15">&nbsp;</td>
                    </tr>
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default BolumMasrafKalem;
