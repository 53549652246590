import React, { useState, useEffect } from "react";
import "../App.css";
import { useWindowResize } from "../Custom-Hooks/useWindowResize";
import axios from "axios";
import SayfaBaslik_3_IsProgramiListe from "../img/SayfaBaslik_3_IsProgramiListe.png";
import Baslik from "../img/IsProgrami/Baslik.png";
import IsProgrami_BolumNo from "../img/IsProgrami_BolumNo.png";
import Yuvarlak_Kirmizi from "../img/Yuvarlak_Kirmizi.png";
import Yuvarlak_Sari from "../img/Yuvarlak_Sari.png";
import Yuvarlak_Yesil from "../img/Yuvarlak_Yesil.png";
import Logo from "../img/Logo.png";
import SolOk from "../img/Ok_Geri.png";
import SagOk from "../img/Ileri.png";
import MenuWrapper from "../CircleMenu/FullScreenMenu";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";

import CekimGunBuyuk from "./CekimGunBuyuk";

const IsProgramiListe = () => {
  const { url } = useApiURL();
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [sezonKod] = useState(localStorage.getItem("SezonKod"));
  const [bolumId] = useState(localStorage.getItem("BolumId"));
  const [isProgramiBaslikListeOzet, setIsProgramiBaslikListeOzet] = useState([
    {}
  ]);
  const [bolumKod] = useState(localStorage.getItem("BolumKod"));
  const [selectedDate, setSelectedDate] = React.useState(new Date(Date.now()));
  useEffect(() => {
    loadIsProgramiBaslikListeOzet(bolumId, token);
  }, [bolumId, token]);
  function logOut() {
    setToken("");
    localStorage.removeItem("BolumId");
    localStorage.removeItem("MenuId");
    localStorage.removeItem("Token");
    window.location.reload();
  }

  const loadIsProgramiBaslikListeOzet = (bolumId, token) => {
    var requestModel = {
      bolumId: bolumId,
      Token: token
    };
    axios
      .post(url + "/api/SahneListe/IsProgramiBaslikListeOzet", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);
              setIsProgramiBaslikListeOzet(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const onCekimGunSecim = dateItem => {
    console.log(dateItem);
    localStorage.setItem("IsProgramiBaslikId", dateItem.isProgramiBaslikId);
    localStorage.setItem("EkipNo", dateItem.ekip);
    window.location.reload();
  };
  const { screenwidth, screenheight } = useWindowResize();
  var totalwidth = Math.max(screenwidth, 1366);
  const [innerwidth] = useState(totalwidth);
  return (
    <div
      id="divEkran"
      style={{
        height: screenheight,
        width: innerwidth,
        overflow: "none"
      }}
    >
      <div
        style={{
          position: "absolute",
          left: totalwidth - 260 - 10,
          top: screenheight - 125 - 10
        }}
      >
        <img className="boxRigth" src={Logo} alt="logo" />
      </div>

      <div  style={{
          position: "absolute",
          left: 0 ,
          top: 84,
        }}>
    <MenuWrapper>

    </MenuWrapper>
      </div>
      <div
        id="divGorsel"
        className="boxLeft"
        style={{ width: 550, height: screenheight }}
      >
        <div
          id="divBaslik"
          className="boxRigth"
          style={{
            width: "99%",
            height: 200,
            textAlign: "right"
          }}
        >
          <img
            className="boxRigth"
            src={Baslik}
            alt="Baslik"
          
          />
        </div>
        <div
          id="divBolumNoGorsel"
          style={{
            width: "99%",
            height: 300,
            textAlign: "right"
          }}
        >
          <img
            src={IsProgrami_BolumNo}
            alt="IsProgrami_BolumNo"
            style={{
              height: 350,
              textAlign: "right"
            }}
          />
          <div
            id="divBolumNo"
            style={{
              fontSize: 36,
              fontWeight: "bold",
              position: "absolute",
              top: 300,
              left: 340,
              textAlign: "left"
            }}
          >
            {sezonKod}. Sezon
            <br />
            {bolumKod}. Bölüm
            <br />
          </div>
        </div>
        <img
          id="imgToplamRevizyonYesil"
          src={Yuvarlak_Yesil}
          style={{
            position: "absolute",
            left: 25,
            top: 535,
            color: "white",
            fontSize: 80,
            width: 90,
            textAlign: "center"
          }}
        />
        <img
          id="imgToplamRevizyonSari"
          src={Yuvarlak_Sari}
          style={{
            position: "absolute",
            left: 145,
            top: 535,
            color: "white",
            fontSize: 80,
            width: 90,
            textAlign: "center"
          }}
        />
        <img
          id="imgToplamRevizyonKirmizi"
          src={Yuvarlak_Kirmizi}
          style={{
            position: "absolute",
            left: 265,
            top: 535,
            color: "white",
            fontSize: 80,
            width: 90,
            textAlign: "center"
          }}
        />
        <img
          id="imgToplamRevizyonSol"
          src={SolOk}
          style={{
            position: "absolute",
            left: 23,
            top: 460,
            fontSize: 80,
            width: 35,
            textAlign: "center"
          }}
        ></img>
        <div
          id="divToplamRevizyon"
          style={{
            position: "absolute",
            left: 40,
            top: 460,
            color: "#707070",
            fontSize: 40,
            width: 300,
            textAlign: "center",
            fontWeight: "bolder"
          }}
        >
          Sahne Durum
        </div>
        <img
          id="imgToplamRevizyonSag"
          src={SagOk}
          style={{
            position: "absolute",
            left: 323,
            top: 460,

            fontSize: 80,
            width: 35,
            textAlign: "center"
          }}
        ></img>
        <div
          id="divToplamRevizyon"
          style={{
            position: "absolute",
            left: 25,
            top: 540,
            color: "white",
            fontSize: 60,
            width: 90,
            textAlign: "center"
          }}
        >
          {isProgramiBaslikListeOzet &&
            isProgramiBaslikListeOzet[0] &&
            isProgramiBaslikListeOzet[0].baslamadi &&
            isProgramiBaslikListeOzet[0].baslamadi}
        </div>
        <div
          id="divToplamRevizyon"
          style={{
            position: "absolute",
            left: 140,
            top: 540,
            color: "white",
            fontSize: 60,
            width: 90,
            textAlign: "center"
          }}
        >
          {isProgramiBaslikListeOzet &&
            isProgramiBaslikListeOzet[0] &&
            isProgramiBaslikListeOzet[0].basladi &&
            isProgramiBaslikListeOzet[0].basladi}
        </div>
        <div
          id="divToplamRevizyon"
          style={{
            position: "absolute",
            left: 265,
            top: 540,
            color: "white",
            fontSize: 60,
            width: 90,
            textAlign: "center"
          }}
        >
          {isProgramiBaslikListeOzet &&
            isProgramiBaslikListeOzet[0] &&
            isProgramiBaslikListeOzet[0].bitti &&
            isProgramiBaslikListeOzet[0].bitti}
        </div>
      </div>

      <div
        className="boxLeft"
        style={{
          width: innerwidth - 550 - 10,
          height: screenheight,
          textAlign: "center"
        }}
      >
        <div
          className="boxLeft"
          style={{
            width: (innerwidth - 550 - 10) / 2 - 5,
            height: screenheight
          }}
        >
          <div
            className="boxRight"
            style={{
              width: (innerwidth - 550 - 10) / 2 - 10,
              height: screenheight
            }}
          >
            <CekimGunBuyuk
              onSelectDate={onCekimGunSecim}
              tarih={selectedDate}
              ekip={1}
              cekimGunWidth={(innerwidth - 550 - 10) / 2 - 10}
              bolumId={bolumId}
            />
          </div>
        </div>
        <div
          className="boxLeft"
          style={{
            width: (innerwidth - 550 - 10) / 2 - 5,
            height: screenheight
          }}
        >
          <div
            className="boxLeft"
            style={{
              width: (innerwidth - 550 - 10) / 2 - 10,
              height: screenheight
            }}
          >
            <CekimGunBuyuk
              onSelectDate={onCekimGunSecim}
              tarih={selectedDate}
              ekip={2}
              cekimGunWidth={(innerwidth - 550 - 10) / 2 - 10}
              bolumId={bolumId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsProgramiListe;
