import Yenile from "../img/OnGoru/Yenile.png";
import Baslik from "../img/OnGoru/Baslik.png";
import Ekle from "../img/OnGoru/Ekle.png";
import Sil from "../img/OnGoru/Sil.png";
import MenuAc from "../img/OnGoru/MenuAc.png";
import OnGorulen from "../img/OnGoru/OnGorulen.png";
import Gerceklesen from "../img/OnGoru/Gerceklesen.png";
import BolumAdi from "../img/OnGoru/BolumAdi.png";

import Bolum_Sec from "../img/OnGoru/Bolum_Sec.png";
import Butce_Kopyala from "../img/OnGoru/Butce_Kopyala.png";
import Proje_Sec_Kurulus_Osman from "../img/OnGoru/Proje_Sec_Kurulus_Osman.png";
import Sezon_Sec from "../img/OnGoru/Sezon_Sec.png";

import AcilirMenuAsagi from "../AcilirMenu/AcilirMenuAsagi";
import MenuWrapper from "../CircleMenu/FullScreenMenu";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";

import React, { useState, useEffect, Fragment } from "react";
import "../App.css";
import axios from "axios";
import { Center } from "devextreme-react/map";
import { Button } from "devextreme-react/drop-down-box";
import BolumMasrafKalem from "./BolumMasrafKalem";

const Ongoru = () => {
  const { url } = useApiURL();
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [projeId] = useState(localStorage.getItem("ProjeId"));
  const [bolumId, setBolumId] = useState(localStorage.getItem("BolumId"));

  const [bolumMasrafOngoruOzet, setBolumMasrafOngoruOzet] = useState();
  useEffect(() => {
    loadBolumMasrafOngoruOzet();
  }, [projeId]);

  const handleBolumClick = bolumId => {
    setBolumId(null);
    setTimeout(() => {
      setBolumId(bolumId);
    }, 500);
  };
  const loadBolumMasrafOngoruOzet = () => {
    var requestModel = {
      Id: projeId,
      Token: token
    };
    axios
      .post(url + "/api/Ongoru/BolumMasrafOngoruOzet", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setBolumMasrafOngoruOzet(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              alert(response.data.responseMessage);
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  /**************************************/
  const [screenwidth, setWidth] = useState(window.innerWidth);
  const [screenheight, setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1366.0;
    var oh = 635.0;
    var oo = ow / oh;
    if (oo > o) {
      setBoyutOran(w / ow);
    } else if (oo < o) {
      setBoyutOran(h / oh);
    }
  };

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  /**************************************/

  return (
    <Fragment>
      <div
        style={{
          position: "absolute",
          right: 80 * boyutOran,
          top: 0 * boyutOran
        }}
      >
        <MenuWrapper></MenuWrapper>
      </div>
      <img
        src={Butce_Kopyala}
        style={{
          position: "absolute",
          top: 5 * boyutOran,
          left: 500 * boyutOran,
          width: 65 * boyutOran
        }}
      />
      <img
        src={Proje_Sec_Kurulus_Osman}
        style={{
          position: "absolute",
          top: 5 * boyutOran,
          left: 1040 * boyutOran,
          width: 70 * boyutOran
        }}
      />
      <img
        src={Sezon_Sec}
        style={{
          position: "absolute",
          top: 5 * boyutOran,
          left: 1130 * boyutOran,
          width: 70 * boyutOran
        }}
      />
      <img
        src={Bolum_Sec}
        style={{
          position: "absolute",
          top: 5 * boyutOran,
          left: 1200 * boyutOran,
          width: 70 * boyutOran
        }}
      />
      <img
        id="Baslik"
        src={Baslik}
        alt="Baslik"
        style={{
          position: "absolute",
          left: solBosluk + 0 * boyutOran,
          top: 0 * boyutOran,
          width: 400 * boyutOran
        }}
      />
      <div
        style={{
          position: "absolute",
          left: solBosluk + 0 * boyutOran,
          top: 80 * boyutOran,
          width: 1366 * boyutOran,
          borderTopColor: "black",
          borderTopStyle: "solid",
          borderTopWidth: 3 * boyutOran
        }}
      />
      <div
        style={{
          position: "absolute",
          left: solBosluk + 0 * boyutOran,
          top: 80 * boyutOran,
          height: (635 - 80) * boyutOran,
          width: 345 * boyutOran,
          borderRightColor: "black",
          borderRightStyle: "solid",
          borderRightWidth: 3 * boyutOran
        }}
      >
        <table
          style={{
            width: "100%",
            fontSize: 15 * boyutOran,
            fontWeight: "bold"
          }}
        >
          <thead style={{ textAlign: "center" }}>
            <tr>
              <td></td>
              <td>Öngörülen</td>
              <td>Gerçekleşen</td>
            </tr>
          </thead>
          <tbody>
            {bolumMasrafOngoruOzet &&
              bolumMasrafOngoruOzet.map &&
              bolumMasrafOngoruOzet.map(item => {
                return (
                  <tr key={"tr" + item.bolumId} id={"tr" + item.bolumId}>
                    <td
                      style={{
                        borderRadius: 10 * boyutOran,
                        backgroundColor:
                          item.bolumId == bolumId ? "skyblue" : "white",
                        cursor: "default"
                      }}
                      onClick={() => handleBolumClick(item.bolumId)}
                    >
                      {item.sezonKod}
                      {".Sezon >> "}
                      {item.bolumKod}
                      {".Bölum "}
                    </td>
                    <td
                      style={{
                        borderRadius: 10 * boyutOran,
                        backgroundColor: "#F7931E",
                        textAlign: "center"
                      }}
                    >
                      {item.ongoruToplamTutar}
                    </td>
                    <td
                      style={{
                        borderRadius: 10 * boyutOran,
                        color: "white",
                        backgroundColor: "#006837",
                        textAlign: "center"
                      }}
                    >
                      {item.ongoruToplamTutar}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 350 * boyutOran,
          top: 85 * boyutOran,
          height: 550 * boyutOran,
          width: 1002 * boyutOran,
          overflowX: "hidden",
          overflowY: "auto"
        }}
      >
        {bolumId && (
          <BolumMasrafKalem
            key="BolumMasrafKalem"
            ongorulen={true}
            bolumId={bolumId}
            boyutOran={boyutOran}
            loadBolumMasrafOngoruOzet={loadBolumMasrafOngoruOzet}
          ></BolumMasrafKalem>
        )}
      </div>
    </Fragment>
  );
};

export default Ongoru;
