import React, { Fragment, memo } from "react";
import clsx from "clsx";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";

const ProjectList = memo(({ projeData, selectedProjeId, onProjectClick }) => {
  const { url } = useApiURL();
  return (
    <Fragment>
      {projeData &&
        projeData.map(tile => {
          const projeClassNames = clsx("boxLeft", "boxRadius", {
            boxCircleLime: tile.projeId === selectedProjeId
          });
          let src = url + "/api/ProjeImage?Id=" + tile.projeId;
          return (
            <div
              className={projeClassNames}
              key={tile.projeId}
              style={{
                marginTop: 10,
                marginLeft: "auto",
                marginRight: "auto",
                width: 210,
                padding: 20
              }}
              onClick={() => onProjectClick(tile.projeId, tile.projeAd)}
            >
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "181"
                }}
              >
                <img alt={tile.projeAd} src={src} />
                <p style={{ textAlign: "center" }}>{tile.projeAd}</p>
              </div>
            </div>
          );
        })}
    </Fragment>
  );
});

export default ProjectList;
