import React, { useState, useEffect } from "react";
import config from "devextreme/core/config";
import DataGrid, { Column, Editing, Lookup } from "devextreme-react/data-grid";
import { SpeedDialAction } from "devextreme-react/speed-dial-action";
//import { employees, states } from "./data.js";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import Axios from "axios";

config({
  floatingActionButtonConfig: {
    icon: "rowfield",
    position: {
      of: "#grid",
      my: "right bottom",
      at: "right bottom",
      offset: "-16 -16"
    }
  }
});

const MasrafKalem = props => {
  const { url } = useApiURL();
  console.log(props);
  const [masrafkalemListe, setMasrafkalemListe] = useState([]);
  const [kapatmaTipListe, setKapatmaTipListe] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [hesapPlanId] = useState(props.HesapPlanId);

  useEffect(() => {
    loadKalemListe();
    loadKapatmaTipListe();
  }, [hesapPlanId]);

  const loadKapatmaTipListe = () => {
    var requestModel = {
      Token: token
    };
    Axios.post(url + "/api/MasrafKalemListe/KapatmaTipListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);
              setKapatmaTipListe(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              alert(response.data.responseMessage);
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadKalemListe = () => {
    var requestModel = {
      Id: hesapPlanId,
      Field: "HesapPlanId",
      Token: token
    };
    if (hesapPlanId !== 0)
      Axios.post(
        url + "/api/MasrafKalemListe/MasrafKalemListeAyrintiBy",
        requestModel
      )
        .then(function(response) {
          try {
            if (response.status === 200) {
              if (response.data.responseCode === 200) {
                console.log(response.data.responseObject);
                setMasrafkalemListe(response.data.responseObject);
              } else if (response.data.responseCode === 401) {
                alert(response.data.responseMessage);
              } else {
                alert(response.data.responseMessage);
                console.log(response.data.responseMessage);
              }
            } else {
              alert(response);
              console.log(response);
            }
          } catch (error) {
            alert(error);
            console.log(error);
          }
        })
        .catch(function(error) {
          alert(error);
          console.log(error);
        });
  };

  const saveKalemListe = () => {
    var text = JSON.stringify(masrafkalemListe);
    var requestModel = {
      Id: hesapPlanId,
      Field: "HesapPlanId",
      Value: text,
      Token: token
    };

    Axios.post(url + "/api/MasrafKalemListe/MasrafKalemKaydet", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);
              setMasrafkalemListe(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              alert(response.data.responseMessage);
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  var grid = null;

  function selectionChanged(e) {
    setSelectedRowIndex(e.component.getRowIndexByKey(e.selectedRowKeys[0]));
  }
  function editRow() {
    grid.instance.editRow(selectedRowIndex);
    grid.instance.deselectAll();
  }
  function deleteRow() {
    grid.instance.deleteRow(selectedRowIndex);
    grid.instance.deselectAll();
  }

  function addRow() {
    grid.instance.addRow();
  }
  return (
    <div>
      <DataGrid
        id={"grid"}
        dataSource={masrafkalemListe}
        keyExpr={"masrafKalemId"}
        ref={ref => (grid = ref)}
        selection={{ mode: "single" }}
        showBorders={true}
        onSelectionChanged={selectionChanged}
      >
        <Column dataField={"masrafKalemKod"} caption={"Masraf Kalem Kod"} />
        <Column dataField={"masrafKalemAd"} />
        <Column dataField={"kapatmaTipId"} caption={"Kapatma Tipi"} width={125}>
          <Lookup
            dataSource={kapatmaTipListe}
            valueExpr={"kapatmaTipId"}
            displayExpr={"kapatmaTipAd"}
          />
        </Column>
        <Column dataField={"masrafFisindeGoster"} dataType={"boolean"} />
        <Column dataField={"masrafFisindeGoster"} dataType={"boolean"} />
        <Column dataField={"grupDetayiAcikGelsin"} dataType={"boolean"} />
        <Column dataField={"toplamGoster"} dataType={"boolean"} />
        <Column dataField={"gosterimSirasi"} dataType={"int"} />
        <Editing mode={"popup"}></Editing>
      </DataGrid>
      <SpeedDialAction icon={"add"} hint={"Add row"} onClick={addRow} />
      {selectedRowIndex !== undefined && selectedRowIndex !== -1 && (
        <div>
          <SpeedDialAction
            icon={"trash"}
            hint={"Delete row"}
            onClick={deleteRow}
          />
          <SpeedDialAction icon={"edit"} hint={"Edit row"} onClick={editRow} />
        </div>
      )}
    </div>
  );
};

export default MasrafKalem;
