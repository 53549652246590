import React, { Fragment, useState, useEffect } from "react";

import "../App.css";
import Menu_1_Senaryo from "../img/AnaMenu/Senaryo.png";
import Menu_2_SenarjoSahneDokumuReji from "../img/AnaMenu/SahneDokumu.png";
import Menu_3_IsProgrami from "../img/AnaMenu/IsProgrami.png";
import Menu_4_OngoruButcesi from "../img/AnaMenu/OnGoru.png";
import Menu_5_MasrafFisleri from "../img/AnaMenu/MasrafFisleri.png";
import Menu_6_Butceler from "../img/AnaMenu/Butceler.png";
import Menu_7_Demirbas from "../img/AnaMenu/Demirbas.png";
import Menu_8_IsGuvenligi from "../img/AnaMenu/IsGuvenligi.png";
import Ekran from "../img/AnaMenu/Ekran.png";
import CineCost from "../img/AnaMenu/CineCost.png";
import Ayarlar from "../img/AnaMenu/Ayarlar.png";
import Menu from "../img/AnaMenu/Menu.png";
import AcilirMenuSaga from "../AcilirMenu/AcilirMenuSaga";
import MenuWrapper from "../CircleMenu/FullScreenMenu";

const AkisSecim = () => {
  const handleClick = MenuId => {
    localStorage.setItem("MenuId", MenuId);
    localStorage.removeItem("IsProgramiBaslikId");
    window.location.reload();
  };
  /**************************************/
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1704.0;
    var oh = 959.0;
    var oo = ow / oh;
    console.log(o);
    console.log(oo);
    if (oo > o) {
      console.log(h);
      console.log(o / oo);
      h = h * (o / oo);
      o = w / h;
      console.log("oo < o");
      console.log(o);
    } else if (oo < o) {
    }
    setBoyutOran(h / oh);
    //setSolBosluk((screenwidth - 1700 * (h / oh)) / 2);
    setSolBosluk(10);
  };

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  /**************************************/
  return (
    <Fragment>
      <img
        src={Ekran}
        alt="Ekran"
        style={{
          position: "absolute",
          left: solBosluk + 0 * boyutOran,
          top: 40 * boyutOran,
          height: 850 * boyutOran
        }}
      ></img>
      <img
        src={CineCost}
        alt="CineCost"
        style={{
          position: "absolute",
          left: solBosluk + 0 * boyutOran,
          top: 760 * boyutOran,
          height: 180 * boyutOran
        }}
      ></img>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 10 * boyutOran,
          top: 10 * boyutOran,
          height: 100 * boyutOran
        }}
       >
        {/* <AcilirMenuSaga></AcilirMenuSaga> */}
      </div>
      <img
        src={Ayarlar}
        alt="Ayarlar"
        style={{
          position: "absolute",
          left: solBosluk + 390 * boyutOran,
          top: 800 * boyutOran,
          height: 120 * boyutOran
        }}
        onClick={() => handleClick(0)}
      ></img>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 1020 * boyutOran,
          top: 70 * boyutOran,
          height: 95 * boyutOran,
          overflow: "hidden"
        }}
      >
        <img
          src={Menu_1_Senaryo}
          alt="Menu_1_Senaryo"
          style={{
            height: 120 * boyutOran
          }}
          onClick={() => handleClick(1)}
        ></img>
      </div>
      <MenuWrapper></MenuWrapper>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 860 * boyutOran,
          top: 159 * boyutOran,
          height: 95 * boyutOran,
          overflow: "hidden"
        }}
      >
        <img
          src={Menu_2_SenarjoSahneDokumuReji}
          alt="Menu_2_SenarjoSahneDokumuReji"
          style={{
            height: 120 * boyutOran
          }}
          onClick={() => handleClick(2)}
        ></img>
      </div>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 1020 * boyutOran,
          top: 248 * boyutOran,
          height: 95 * boyutOran,
          overflow: "hidden"
        }}
      >
        <img
          src={Menu_3_IsProgrami}
          alt="Menu_3_IsProgrami"
          style={{
            height: 120 * boyutOran
          }}
          onClick={() => handleClick(3)}
        ></img>
      </div>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 860 * boyutOran,
          top: 337 * boyutOran,
          height: 95 * boyutOran,
          overflow: "hidden"
        }}
      >
        <img
          src={Menu_4_OngoruButcesi}
          alt="Menu_4_OngoruButcesi"
          style={{
            height: 120 * boyutOran
          }}
          onClick={() => handleClick(4)}
        ></img>
      </div>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 1020 * boyutOran,
          top: 425 * boyutOran,
          height: 95 * boyutOran,
          overflow: "hidden"
        }}
      >
        <img
          src={Menu_5_MasrafFisleri}
          alt="Menu_5_MasrafFisleri"
          style={{
            height: 120 * boyutOran
          }}
          onClick={() => handleClick(5)}
        ></img>
      </div>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 860 * boyutOran,
          top: 515 * boyutOran,
          height: 95 * boyutOran,
          overflow: "hidden"
        }}
      >
        <img
          src={Menu_6_Butceler}
          alt="Menu_6_Butceler"
          style={{
            height: 120 * boyutOran
          }}
          onClick={() => handleClick(6)}
        ></img>
      </div>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 1020 * boyutOran,
          top: 602 * boyutOran,
          height: 95 * boyutOran,
          overflow: "hidden"
        }}
      >
        <img
          src={Menu_7_Demirbas}
          alt="Menu_7_Demirbas"
          style={{
            height: 120 * boyutOran
          }}
          onClick={() => handleClick(7)}
        ></img>
      </div>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 860 * boyutOran,
          top: 690 * boyutOran,
          height: 95 * boyutOran,
          overflow: "hidden"
        }}
      >
        <img
          src={Menu_8_IsGuvenligi}
          alt="Menu_8_IsGuvenligi"
          style={{
            height: 120 * boyutOran
          }}
          onClick={() => handleClick(8)}
        ></img>
      </div>
    </Fragment>
  );
};
export default AkisSecim;
