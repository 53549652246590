import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import React, { useState, useEffect, Fragment } from "react";
import "../App.css";
import "./tab.css";

// import DataSource from "devextreme/data/data_source";
import axios from "axios";
import { TextBox, Lookup, TextArea, CheckBox } from "devextreme-react";
import { Switch } from "devextreme-react/switch";
import { NumberBox } from "devextreme-react/number-box";
import { Button } from "devextreme-react/button";
import Ara from "../img/Ayarlar/Ara.png";
import Ekle from "../img/Ayarlar/Ekle.png";
import AltEkle from "../img/Ayarlar/AltEkle.png";
import Kaydet from "../img/Ayarlar/Kaydet.png";
import Sil from "../img/Ayarlar/Sil.png";
import Yenile from "../img/Ayarlar/Yenile.png";
import MasrafKalemSozlesme from "./MasrafKalemSozlesme.js";
import MasrafMerkeziBaslik from "./MasrafMerkeziBaslik.js";
import MasrafKalemSozlesmeArac from "./MasrafKalemSozlesmeArac.js";
import MasrafKalemSozlesmeYemek from "./MasrafKalemSozlesmeYemek.js";

// import Field from "./Field.js";
// import Item from "./Item.js";

const openTab = (evt, tabName) => {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(tabName).style.display = "block";
  evt.currentTarget.className += " active";
};

const MasrafKalemDuzenle = props => {
  const { url } = useApiURL();
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [parentMasrafKalemId, setParentMasrafKalemId] = useState(null);
  const [masrafKalemTipListe, setMasrafKalemTipListe] = useState([
    { id: 0, text: "---" }
  ]);
  const [kapatmaTipListe, setKapatmaTipListe] = useState([
    { id: 0, text: "---" }
  ]);

  const [kaydetAktif, setKaydetAktif] = useState(null);
  const [ajansListe, setAjansListe] = useState([{ id: 0, text: "---" }]);
  const [menajerListe, setMenajerListe] = useState([{ id: 0, text: "---" }]);
  //const [mekanListe, setMekanListe] = useState([{ id: 0, text: "---" }]);
  const [aracGrupListe] = useState([
    { id: 1, text: "Servis Araçları" },
    { id: 2, text: "Set Araçları" }
  ]);
  const [tedarikciListe, setTedarikciListe] = useState([
    { id: 0, text: "---" }
  ]);
  const [ekipListe, setEkipListe] = useState([{ id: 0, text: "---" }]);
  const [gorevListe, setGorevListe] = useState([{ id: 0, text: "---" }]);

  const [hesapPlanListe, setHesapPlanListe] = useState([
    { id: 0, text: "---" }
  ]);

  const [masrafKalemData, setMasrafKalemData] = useState(null);
  const [masrafKalemDataId] = useState(props.MasrafKalemId);
  const [simdikiMasrafKalemTipId, setSimdikiMasrafKalemTipId] = useState();

  const [projeId] = useState(props.projeId);
  const [kurumId] = useState(props.kurumId);

  useEffect(() => {
    loadMasrafKalemTipListe();
    loadKapatmaTipListe();
    loadHesapPlanListe();
    loadAjansListe();
    loadMenajerListe();
    loadTedarikciListe();
    loadEkipListe();
    loadGorevListe();
  }, [token, masrafKalemDataId]);
  function logOut() {
    // setToken("");
    // localStorage.removeItem("BolumId");
    // localStorage.removeItem("MenuId");
    // localStorage.removeItem("Token");
    // window.location.reload();
  }
  const refreshNode = () => {
    props.refreshTree();
  };
  const loadMasrafKalem = () => {
    var requestModel = {
      Id: masrafKalemDataId,
      Field: "MasrafKalemId",
      Token: token
    };

    if (masrafKalemDataId !== 0)
      axios
        .post(
          url + "/api/MasrafKalemListe/MasrafKalemListeAyrintiBy",
          requestModel
        )
        .then(function(response) {
          try {
            if (response.status === 200) {
              if (response.data.responseCode === 200) {
                setMasrafKalemData(null);
                setTimeout(() => {
                  setParentMasrafKalemId(
                    response.data.responseObject[0].parentMasrafKalemId
                  );
                  setMasrafKalemData(response.data.responseObject[0]);
                  setSimdikiMasrafKalemTipId(
                    response.data.responseObject[0].masrafKalemTipId
                  );
                }, 500);
              } else if (response.data.responseCode === 401) {
                logOut();
              } else {
                alert(response.data.responseMessage);
                console.log(response.data.responseMessage);
              }
            } else {
              alert(response);
              console.log(response);
            }
          } catch (error) {
            alert(error);
            console.log(error);
          }
        })
        .catch(function(error) {
          alert(error);
          console.log(error);
        });
  };

  const loadMasrafKalemTipListe = () => {
    var requestModel = {
      Token: token
    };

    axios
      .post(url + "/api/MasrafKalemListe/MasrafKalemTipListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setTimeout(() => {
                setMasrafKalemTipListe(response.data.responseObject);
                loadMasrafKalem();
              }, 10);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadKapatmaTipListe = () => {
    var requestModel = {
      Token: token
    };

    axios
      .post(url + "/api/MasrafKalemListe/KapatmaTipListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setTimeout(() => {
                setKapatmaTipListe(response.data.responseObject);
              }, 500);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadAjansListe = () => {
    var requestModel = {
      Token: token,
      Id: projeId
    };

    axios
      .post(url + "/api/MasrafKalemListe/AjansListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setTimeout(() => {
                setAjansListe(response.data.responseObject);
              }, 500);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadMenajerListe = () => {
    var requestModel = {
      Token: token,
      Id: projeId
    };

    axios
      .post(url + "/api/MasrafKalemListe/MenajerListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setTimeout(() => {
                setMenajerListe(response.data.responseObject);
              }, 500);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  // const loadMekanListe = () => {
  //   var requestModel = {
  //     Token: token,
  //     Id: projeId
  //   };

  //   axios
  //     .post(url + "/api/MasrafKalemListe/MekanListe", requestModel)
  //     .then(function(response) {
  //       try {
  //         if (response.status === 200) {
  //           if (response.data.responseCode === 200) {
  //             setTimeout(() => {
  //               setMekanListe(response.data.responseObject);
  //             }, 500);
  //           } else if (response.data.responseCode === 401) {
  //             logOut();
  //           } else {
  //             alert(response.data.responseMessage);
  //             console.log(response.data.responseMessage);
  //           }
  //         } else {
  //           alert(response);
  //           console.log(response);
  //         }
  //       } catch (error) {
  //         alert(error);
  //         console.log(error);
  //       }
  //     })
  //     .catch(function(error) {
  //       alert(error);
  //       console.log(error);
  //     });
  // };

  const loadTedarikciListe = () => {
    var requestModel = {
      Token: token,
      Id: kurumId
    };

    axios
      .post(url + "/api/MasrafKalemListe/TedarikciListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setTimeout(() => {
                setTedarikciListe(response.data.responseObject);
              }, 500);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadEkipListe = () => {
    var requestModel = {
      Token: token,
      Id: kurumId
    };

    axios
      .post(url + "/api/MasrafKalemListe/EkipListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setTimeout(() => {
                setEkipListe(response.data.responseObject);
              }, 500);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadGorevListe = () => {
    var requestModel = {
      Token: token,
      Id: kurumId
    };

    axios
      .post(url + "/api/MasrafKalemListe/GorevListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setTimeout(() => {
                setGorevListe(response.data.responseObject);
              }, 500);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const loadHesapPlanListe = () => {
    var requestModel = {
      Token: token
    };

    axios
      .post(url + "/api/MasrafKalemListe/HesapPlanListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setTimeout(() => {
                setHesapPlanListe(response.data.responseObject);
              }, 500);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  /***********METODLAR************/

  function handleMasrafKalemDataBeforeChange(data) {
    if (masrafKalemData === null)
      setMasrafKalemData({ projeId: projeId, kurumId: kurumId });
  }
  function handleMasrafKalemDataChange(data) {
    setKaydetAktif(1);
  }
  /*select  'function handleMasrafKalem'+Name+'Change(data) {    masrafKalemData.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value; handleMasrafKalemDataChange(masrafKalemData); }' from syscolumns where id = object_id('MasrafKalem') and name not like '%Siline%'*/

  function handleMasrafKalemMasrafKalemTipIdChange(data) {
    handleMasrafKalemDataBeforeChange();
    masrafKalemData.masrafKalemTipId = data.value.id;
    setSimdikiMasrafKalemTipId(data.value.id);
    handleMasrafKalemDataChange(masrafKalemData);
  }
  function handleMasrafKalemHesapPlanIdChange(data) {
    handleMasrafKalemDataBeforeChange();
    masrafKalemData.hesapPlanId = data.value.id;
    handleMasrafKalemDataChange(masrafKalemData);
  }

  function handleMasrafKalemMasrafKalemKodChange(data) {
    handleMasrafKalemDataBeforeChange();
    masrafKalemData.masrafKalemKod = data.value;
    handleMasrafKalemDataChange(masrafKalemData);
  }
  function handleMasrafKalemMasrafKalemAdChange(data) {
    handleMasrafKalemDataBeforeChange();
    masrafKalemData.masrafKalemAd = data.value;
    handleMasrafKalemDataChange(masrafKalemData);
  }
  function handleMasrafKalemKapatmaTipIdChange(data) {
    masrafKalemData.kapatmaTipId = data.value.id;
    handleMasrafKalemDataChange(masrafKalemData);
  }
  function handleTedarikciIdChange(data) {
    handleMasrafKalemDataBeforeChange();
    masrafKalemData.tedarikciId = data.value.id;
    handleMasrafKalemDataChange(masrafKalemData);
  }
  function handleAjansIdChange(data) {
    handleMasrafKalemDataBeforeChange();
    masrafKalemData.ajansId = data.value.id;
    handleMasrafKalemDataChange(masrafKalemData);
  }

  function handleMasrafKalemMasrafFisindeGosterChange(data) {
    handleMasrafKalemDataBeforeChange();
    masrafKalemData.masrafFisindeGoster = data.value;
    handleMasrafKalemDataChange(masrafKalemData);
  }
  function handleMasrafKalemGrupDetayiAcikGelsinChange(data) {
    handleMasrafKalemDataBeforeChange();
    masrafKalemData.grupDetayiAcikGelsin = data.value;
    handleMasrafKalemDataChange(masrafKalemData);
  }
  function handleMasrafKalemAvansVerilebilirChange(data) {
    handleMasrafKalemDataBeforeChange();
    masrafKalemData.avansVerilebilir = data.value;
    handleMasrafKalemDataChange(masrafKalemData);
  }

  function handleMasrafKalemToplamGosterChange(data) {
    handleMasrafKalemDataBeforeChange();
    masrafKalemData.toplamGoster = data.value;
    handleMasrafKalemDataChange(masrafKalemData);
  }
  function handleMasrafKalemGosterimSirasiChange(data) {
    handleMasrafKalemDataBeforeChange();
    masrafKalemData.gosterimSirasi = data.value;
    handleMasrafKalemDataChange(masrafKalemData);
  }

  /*select  'function handle'+Name+'Change(data) {    masrafKalemData.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value; handleMasrafKalemDataChange(masrafKalemData); }' from syscolumns where id = object_id('MasrafKalem')*/

  function handleOyuncuBeforeChange() {
    if (masrafKalemData.oyuncu === null) masrafKalemData.oyuncu = {};
  }
  function handleOyuncuChange(data) {
    console.log(data);
  }
  /*select  'function handleOyuncu'+Name+'Change(data) {    masrafKalemData.oyuncu.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleOyuncuChange(masrafKalemData.oyuncu); }' from syscolumns where id = object_id('Oyuncu')  */
  function handleOyuncuAdSoyadChange(data) {
    handleOyuncuBeforeChange();
    masrafKalemData.oyuncu.adSoyad = data.value;
    handleOyuncuChange(masrafKalemData.oyuncu);
  }
  function handleOyuncuAjansIdChange(data) {
    handleOyuncuBeforeChange();
    masrafKalemData.oyuncu.ajansId = data.value.id;
    handleOyuncuChange(masrafKalemData.oyuncu);
  }

  function handleOyuncuEmailChange(data) {
    handleOyuncuBeforeChange();
    masrafKalemData.oyuncu.email = data.value;
    handleOyuncuChange(masrafKalemData.oyuncu);
  }
  function handleOyuncuKarakterIdChange(data) {
    handleOyuncuBeforeChange();
    masrafKalemData.oyuncu.karakterId = data.value.id;
    handleOyuncuChange(masrafKalemData.oyuncu);
  }

  function handleOyuncuMenajerIdChange(data) {
    handleOyuncuBeforeChange();
    masrafKalemData.oyuncu.menajerId = data.value.id;
    handleOyuncuChange(masrafKalemData.oyuncu);
  }
  function handleOyuncuNotlarChange(data) {
    handleOyuncuBeforeChange();
    masrafKalemData.oyuncu.notlar = data.value;
    handleOyuncuChange(masrafKalemData.oyuncu);
  }

  function handleOyuncuResimChange(data) {
    handleOyuncuBeforeChange();
    masrafKalemData.oyuncu.resim = data.value;
    handleOyuncuChange(masrafKalemData.oyuncu);
  }
  function handleOyuncuTelefonChange(data) {
    handleOyuncuBeforeChange();
    masrafKalemData.oyuncu.telefon = data.value;
    handleOyuncuChange(masrafKalemData.oyuncu);
  }
  /*select  'function handleOyuncu'+Name+'Change(data) {    masrafKalemData.oyuncu.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleOyuncuChange(masrafKalemData.oyuncu); }' from syscolumns where id = object_id('Oyuncu')  */

  function handleMekanBeforeChange() {
    if (masrafKalemData.mekan === null) masrafKalemData.mekan = {};
  }
  function handleMekanChange(data) {
    console.log(data);
  }
  /**select  'function handleMekan'+Name+'Change(data) {    masrafKalemData.mekan.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleMekanChange(masrafKalemData.mekan); }' from syscolumns where id = object_id('Mekan')  */

  function handleMekanMekanAdChange(data) {
    handleMekanBeforeChange();
    masrafKalemData.mekan.mekanAd = data.value;
    handleMekanChange(masrafKalemData.mekan);
  }
  function handleMekanMekanSahipChange(data) {
    handleMekanBeforeChange();
    masrafKalemData.mekan.mekanSahip = data.value;
    handleMekanChange(masrafKalemData.mekan);
  }
  /**select  'function handleMekan'+Name+'Change(data) {    masrafKalemData.mekan.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleMekanChange(masrafKalemData.mekan); }' from syscolumns where id = object_id('Mekan')  */

  function handleAracBeforeChange() {
    if (masrafKalemData.arac === null) masrafKalemData.arac = {};
  }
  function handleAracChange(data) {}
  /*select  'function handleArac'+Name+'Change(data) {    masrafKalemData.arac.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleAracChange(masrafKalemData.arac); }' from syscolumns where id = object_id('Arac') */
  function handleAracAracAdChange(data) {
    handleAracBeforeChange();
    masrafKalemData.arac.aracAd = data.value;
    handleAracChange(masrafKalemData.arac);
  }
  function handleAracAracGrupChange(data) {
    handleAracBeforeChange();
    masrafKalemData.arac.aracGrup = data.value.id;
    handleAracChange(masrafKalemData.arac);
  }
  function handleAracNotlarChange(data) {
    handleAracBeforeChange();
    masrafKalemData.arac.notlar = data.value;
    handleAracChange(masrafKalemData.arac);
  }
  function handleAracTedarikciIdChange(data) {
    handleAracBeforeChange();
    masrafKalemData.arac.tedarikciId = data.value.id;
    handleAracChange(masrafKalemData.arac);
  }
  /*select  'function handleArac'+Name+'Change(data) {    masrafKalemData.arac.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleAracChange(masrafKalemData.arac); }' from syscolumns where id = object_id('Arac') */

  function handleEkipBeforeChange() {
    console.log(masrafKalemData);
    if (masrafKalemData.ekip === null) {
      console.log(masrafKalemData);
      masrafKalemData.ekip = {};
    }
  }
  function handleEkipChange(data) {}
  /*select  'function handleEkip'+Name+'Change(data) {    masrafKalemData.ekip.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleEkipChange(masrafKalemData.ekip); }' from syscolumns where id = object_id('Ekip') */

  function handleEkipEkipAdChange(data) {
    handleEkipBeforeChange();
    masrafKalemData.ekip.ekipAd = data.value;
    handleEkipChange(masrafKalemData.ekip);
  }

  function handleEkipGorevIdChange(data) {
    handleEkipBeforeChange();
    masrafKalemData.ekip.gorevId = data.value.id;
    handleEkipChange(masrafKalemData.ekip);
  }

  /*select  'function handleEkip'+Name+'Change(data) {    masrafKalemData.ekip.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleEkipChange(masrafKalemData.ekip); }' from syscolumns where id = object_id('Ekip') */

  function handleKarakterBeforeChange(masrafKalemData) {
    if (
      masrafKalemData.oyuncu === null ||
      masrafKalemData.oyuncu === "undefined"
    ) {
      masrafKalemData.oyuncu = {};
      console.log("Oyuncu Set");
    }
    if (
      masrafKalemData.oyuncu.karakter === null ||
      typeof masrafKalemData.oyuncu.karakter === "undefined"
    ) {
      masrafKalemData.oyuncu.karakter = {};
      console.log("Karakter Set");
    }
  }
  function handleKarakterChange(data) {}
  /*select  'function handleKarakter'+Name+'Change(data) {    masrafKalemData.oyuncu.karakter.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleKarakterChange(masrafKalemData.oyuncu.karakter); }' from syscolumns where id = object_id('Karakter') */

  function handleKarakterKarakterAdChange(data) {
    handleKarakterBeforeChange(masrafKalemData);
    masrafKalemData.oyuncu.karakter.karakterAd = data.value;
    handleKarakterChange(masrafKalemData.oyuncu.karakter);
  }
  /*select  'function handleKarakter'+Name+'Change(data) {    masrafKalemData.oyuncu.karakter.'+lower(substring(name,1,1))+substring(name,2,20)+' = data.value'+ case when name like '%Id' then '.id' else '' end +'; handleKarakterChange(masrafKalemData.oyuncu.karakter); }' from syscolumns where id = object_id('Karakter') */

  /*select  'mk.'+lower(substring(name,1,1))+substring(name,2,40)+' = null;' from syscolumns where id = object_id('MasrafKalem') and name not like '%Siline%'*/
  const addMasrafKalem = () => {
    setMasrafKalemData(null);
    loadMasrafKalemYeni(parentMasrafKalemId);
  };
  const addMasrafKalemAltSeviye = () => {
    setMasrafKalemData(null);
    loadMasrafKalemYeni(masrafKalemDataId);
  };
  const loadMasrafKalemYeni = yeniParentMasrafKalemId => {
    setKaydetAktif(null);
    var requestModel = {
      Token: token,
      Id: projeId
    };

    axios
      .post(url + "/api/MasrafKalemListe/MasrafKalemYeni", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              setTimeout(() => {
                response.data.responseObject.parentMasrafKalemId = yeniParentMasrafKalemId;
                setMasrafKalemData(response.data.responseObject);
              }, 500);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  const handleSilMasrafKalem = () => {
    masrafKalemData.silindi = true;
    saveMasrafKalem();
  };
  const saveMasrafKalem = () => {
    setKaydetAktif(null);

    var text = JSON.stringify(masrafKalemData);
    var requestModel = {
      Id: projeId,
      Value: text,
      Token: token
    };
    axios
      .post(url + "/api/MasrafKalemListe/MasrafKalemKaydet", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);
              //props.loadAgacHandle();
              refreshNode();
              alert(response.data.responseMessage);
            } else if (response.data.responseCode === 401) {
              alert(response.data.responseMessage);
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  /***********METODLAR************/

  /**************************************/
  const [screenwidth, setWidth] = useState(window.innerWidth);
  const [screenheight, setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1704.0;
    var oh = 959.0;
    var oo = ow / oh;
    console.log(o);
    console.log(oo);
    if (oo > o) {
      console.log(h);
      console.log(o / oo);
      h = h * (o / oo);
      o = w / h;
      console.log("oo < o");
      console.log(o);
    } else if (oo < o) {
    }
    setWidth(w);
    setHeight(h);
    setBoyutOran(h / oh);
    setSolBosluk(10);
  };

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);
  /**************************************/

  let oyuncuAjansItemId =
    masrafKalemData === null
      ? 0
      : masrafKalemData.oyuncu === null
      ? 0
      : masrafKalemData.oyuncu.ajansId;
  let oyuncuAjansItem = ajansListe.find(item => item.id === oyuncuAjansItemId);

  let menajerItemId =
    masrafKalemData === null
      ? 0
      : masrafKalemData.oyuncu === null
      ? 0
      : masrafKalemData.oyuncu.menajerId;
  let menajerItem = menajerListe.find(item => item.id === menajerItemId);

  let aracGrupItemId =
    masrafKalemData === null
      ? 0
      : masrafKalemData.arac === null
      ? 0
      : masrafKalemData.arac.aracGrup;
  let aracGrupItem = aracGrupListe.find(item => item.id === aracGrupItemId);

  let tedarikciItemId =
    masrafKalemData === null
      ? 0
      : masrafKalemData.tedarikciId === null
      ? 0
      : masrafKalemData.tedarikciId;
  let tedarikciItem = tedarikciListe.find(item => item.id === tedarikciItemId);

  let ajansItemId =
    masrafKalemData === null
      ? 0
      : masrafKalemData.ajansId === null
      ? 0
      : masrafKalemData.ajansId;
  let ajansItem = ajansListe.find(item => item.id === ajansItemId);

  let aracTedarikciItemId =
    masrafKalemData === null
      ? 0
      : masrafKalemData.arac === null
      ? 0
      : masrafKalemData.arac.tedarikciId;
  let aracTedarikciItem = tedarikciListe.find(
    item => item.id === aracTedarikciItemId
  );

  let gorevItemId =
    masrafKalemData === null
      ? 0
      : masrafKalemData.ekip === null
      ? 0
      : masrafKalemData.ekip.gorevId;
  let gorevItem = gorevListe.find(item => item.id === gorevItemId);

  let kapatmaTipItemId =
    masrafKalemData === null ? 0 : masrafKalemData.kapatmaTipId;
  let kapatmaTipItem = kapatmaTipListe.find(
    item => item.id === kapatmaTipItemId
  );

  let masrafkalemtipItemId =
    masrafKalemData === null ? 0 : masrafKalemData.masrafKalemTipId;
  let masrafkalemtipItem = masrafKalemTipListe.find(
    item => item.id === masrafkalemtipItemId
  );

  let hesapplanItemId =
    masrafKalemData === null ? 0 : masrafKalemData.hesapPlanId;
  let hesapplanItem = hesapPlanListe.find(item => item.id === hesapplanItemId);

  const yenileClick = () => {
    window.location.reload();
  };

  return (
    <div id={"product-details"} style={{ width: 900 * boyutOran, padding: 5 }}>
      <div style={{ padding: 10 }}>
        <div
          style={{
            position: "absolute",
            top: -130 * boyutOran,
            left: 300 * boyutOran,
            width: 600 * boyutOran,
            height: 90 * boyutOran,
            padding: 10 * boyutOran
          }}
        >
          <div
            className="boxLeft"
            style={{
              width: 85 * boyutOran
            }}
          >
            <img
              style={{
                width: 75 * boyutOran
              }}
              src={Ara}
            />
            &nbsp;&nbsp;{" "}
          </div>

          <div
            className="boxLeft"
            style={{
              width: 85 * boyutOran
            }}
          >
            <img
              style={{
                width: 75 * boyutOran
              }}
              title="Aynı Seviye Olarak Ekle"
              src={AltEkle}
              onClick={addMasrafKalem}
            />
            &nbsp;&nbsp;{" "}
          </div>

          <div
            className="boxLeft"
            style={{
              width: 85 * boyutOran
            }}
          >
            <img
              style={{
                width: 75 * boyutOran
              }}
              title="Alt Seviye Olarak Ekle"
              src={Ekle}
              onClick={addMasrafKalemAltSeviye}
            />
            &nbsp;&nbsp;
          </div>
          <div
            className="boxLeft"
            style={{
              width: 85 * boyutOran
            }}
          >
            <img
              style={{
                width: 75 * boyutOran
              }}
              onClick={saveMasrafKalem}
              title="Değiştirilmiş Olan Masrafa Kalamini Kaydeder"
              src={Kaydet}
            />
            &nbsp;&nbsp;
          </div>

          <div
            className="boxLeft"
            style={{
              width: 85 * boyutOran
            }}
          >
            <img
              style={{
                width: 75 * boyutOran
              }}
              src={Yenile}
              onClick={yenileClick}
            />
            &nbsp;&nbsp;
          </div>
          <div
            className="boxLeft"
            style={{
              width: 85 * boyutOran
            }}
          >
            {masrafKalemData && masrafKalemData.silindi === false && (
              <img
                style={{
                  width: 75 * boyutOran
                }}
                src={Sil}
                onClick={handleSilMasrafKalem}
              />
            )}
            &nbsp;&nbsp;
          </div>
        </div>
      </div>
      <div className={"form"}>
        <div className={"dx-fieldset"}>
          <div className={"dx-field"}>
            <div className={"dx-field-label"}>Id</div>
            <div className={"dx-field-value"}>
              {masrafKalemData && <span>{masrafKalemData.masrafKalemId}</span>}
            </div>
          </div>
          <div className={"dx-field"}>
            <div className={"dx-field-label"}>Masraf Kalem Kod</div>
            <div className={"dx-field-value"}>
              {masrafKalemData && (
                <TextBox
                  placeholder={"Hesap Kodunu Giriniz"}
                  defaultValue={masrafKalemData.masrafKalemKod}
                  onValueChanged={handleMasrafKalemMasrafKalemKodChange}
                />
              )}
            </div>
          </div>
          <div className={"dx-field"}>
            <div className={"dx-field-label"}>Masraf Kalem Ad</div>
            <div className={"dx-field-value"}>
              {masrafKalemData && (
                <TextBox
                  placeholder={"Masraf Kalem Adını Giriniz"}
                  defaultValue={masrafKalemData.masrafKalemAd}
                  showClearButton={true}
                  onValueChanged={handleMasrafKalemMasrafKalemAdChange}
                />
              )}
            </div>
          </div>
          <div className={"dx-field"} style={{ float: "right" }}>
            <div
              className={"dx-field-label"}
              style={{
                width: 400 * boyutOran
              }}
            >
              {masrafKalemData && (
                <CheckBox
                  placeholder={"Masraf Fişinde Gösterilmeli Mi"}
                  defaultValue={masrafKalemData.masrafFisindeGoster}
                  onValueChanged={handleMasrafKalemMasrafFisindeGosterChange}
                  text="Masraf Fisinde Goster"
                  style={{ width: 220 * boyutOran }}
                />
              )}

              {masrafKalemData && (
                <CheckBox
                  text={"Grup Detayı Açık Gelsin"}
                  defaultValue={masrafKalemData.grupDetayiAcikGelsin}
                  onValueChanged={handleMasrafKalemGrupDetayiAcikGelsinChange}
                  style={{ width: 220 * boyutOran }}
                />
              )}
            </div>
            <div
              className={"dx-field-label"}
              style={{
                float: "left",
                marginLeft: 70 * boyutOran,
                width: 380 * boyutOran
              }}
            >
              {masrafKalemData && (
                <CheckBox
                  defaultValue={masrafKalemData.avansVerilebilir}
                  onValueChanged={handleMasrafKalemAvansVerilebilirChange}
                  text="Avans Verilebilir"
                  style={{ width: 220 * boyutOran }}
                />
              )}

              {masrafKalemData && (
                <CheckBox
                  defaultValue={masrafKalemData.toplamGoster}
                  onValueChanged={handleMasrafKalemToplamGosterChange}
                  text="Toplamda Göster"
                  style={{ width: 220 * boyutOran }}
                />
              )}
            </div>
          </div>

          <div className={"dx-field"}>
            <div className={"dx-field-label"}>Gösterim Sırası</div>
            <div className={"dx-field-value"}>
              {masrafKalemData && (
                <NumberBox
                  defaultValue={masrafKalemData.gosterimSirasi}
                  showSpinButtons={true}
                  onValueChanged={handleMasrafKalemGosterimSirasiChange}
                />
              )}
            </div>
          </div>
          <div className={"dx-field"}>
            <div className={"dx-field-label"}>Masraf Kalem Tip</div>
            <div className={"dx-field-value"}>
              {masrafKalemData && (
                <Lookup
                  defaultValue={masrafkalemtipItem}
                  items={masrafKalemTipListe}
                  dataSource={masrafKalemTipListe}
                  showPopupTitle={false}
                  displayExpr={"text"}
                  onValueChanged={handleMasrafKalemMasrafKalemTipIdChange}
                  //fieldRender={Field}
                />
              )}
            </div>
          </div>
          <div className={"dx-field"}>
            <div className={"dx-field-label"}>Kapatma Tip</div>
            <div className={"dx-field-value"}>
              {masrafKalemData && (
                <Lookup
                  defaultValue={kapatmaTipItem}
                  items={kapatmaTipListe}
                  dataSource={kapatmaTipListe}
                  showPopupTitle={false}
                  displayExpr={"text"}
                  onValueChanged={handleMasrafKalemKapatmaTipIdChange}
                  //fieldRender={Field}
                />
              )}
            </div>
          </div>
          <div className={"dx-field"}>
            <div className={"dx-field-label"}>Hesap Plan</div>
            <div className={"dx-field-value"}>
              {masrafKalemData && (
                <Lookup
                  defaultValue={hesapplanItem}
                  items={hesapPlanListe}
                  dataSource={hesapPlanListe}
                  showPopupTitle={false}
                  displayExpr={"text"}
                  onValueChanged={handleMasrafKalemHesapPlanIdChange}
                  //fieldRender={Field}
                />
              )}
            </div>
          </div>
          {/* <div id="divMasrafKalemTedarikciId" className={"dx-field"}>
            <div className={"dx-field-label"}>Tedarikçi</div>
            <div className={"dx-field-value"}>
              <Lookup
                defaultValue={tedarikciItem}
                items={tedarikciListe}
                dataSource={tedarikciListe}
                showPopupTitle={false}
                displayExpr={"text"}
                onValueChanged={handleTedarikciIdChange}
              />
            </div>
          </div>
         */}
          <div id="divMasrafKalemAjansId" className={"dx-field"}>
            <div className={"dx-field-label"}>Ajans</div>
            <div className={"dx-field-value"}>
              <Lookup
                defaultValue={ajansItem}
                items={ajansListe}
                dataSource={ajansListe}
                showPopupTitle={false}
                displayExpr={"text"}
                onValueChanged={handleAjansIdChange}
              />
            </div>
          </div>
        </div>
      </div>

      <Fragment>
        <div className="tab">
          <button
            className="tablinks"
            onClick={event => openTab(event, "tabMasrafTip")}
          >
            Masraf Tip
          </button>
          <button
            className="tablinks"
            onClick={event => openTab(event, "tabSozlesme")}
          >
            Sözleşme
          </button>
          <button
            className="tablinks"
            onClick={event => openTab(event, "tabMasrafMerkeziBaslik")}
          >
            Başlık
          </button>
        </div>

        <div
          id="tabMasrafTip"
          className="tabcontent"
          style={{ display: "block" }}
        >
          {masrafKalemData &&
            (simdikiMasrafKalemTipId === 1 ||
              simdikiMasrafKalemTipId === 2 ||
              simdikiMasrafKalemTipId === 3 ||
              simdikiMasrafKalemTipId === 7 ||
              simdikiMasrafKalemTipId === 11) && (
              <Fragment>
                {simdikiMasrafKalemTipId === 0 && <h3>Ayrıntı</h3>}
                {simdikiMasrafKalemTipId === 1 && (
                  <div id="Mekan" className={"form"}>
                    <div className={"dx-fieldset"}>
                      <div className={"dx-fieldset-header"}>Mekan</div>
                      <div id="MekanAd" className={"dx-field"}>
                        <div className={"dx-field-label"}>Mekan Ad</div>
                        <div className={"dx-field-value"}>
                          <TextBox
                            placeholder={"Mekan Adı Giriniz"}
                            defaultValue={
                              masrafKalemData.mekan
                                ? masrafKalemData.mekan.mekanAd
                                : ""
                            }
                            showClearButton={true}
                            onValueChanged={handleMekanMekanAdChange}
                          />
                        </div>
                      </div>
                      <div id="MekanSahip" className={"dx-field"}>
                        <div className={"dx-field-label"}>Mekan Sahibi</div>
                        <div className={"dx-field-value"}>
                          <TextBox
                            placeholder={"Mekan Sahibinin Adını Giriniz"}
                            defaultValue={
                              masrafKalemData.mekan
                                ? masrafKalemData.mekan.mekanSahip
                                : ""
                            }
                            showClearButton={true}
                            onValueChanged={handleMekanMekanSahipChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {(simdikiMasrafKalemTipId === 2 ||
                  simdikiMasrafKalemTipId === 3) && (
                  <div id="Oyuncu" className={"form"}>
                    <div className={"dx-fieldset"}>
                      <div className={"dx-fieldset-header"}>Oyuncu</div>
                      <div id="AdSoyad" className={"dx-field"}>
                        <div className={"dx-field-label"}>AdSoyad</div>
                        <div className={"dx-field-value"}>
                          <TextBox
                            placeholder={"Oyuncunun Adı ve Soyadını Giriniz"}
                            defaultValue={
                              masrafKalemData.oyuncu
                                ? masrafKalemData.oyuncu.adSoyad
                                : ""
                            }
                            showClearButton={true}
                            onValueChanged={handleOyuncuAdSoyadChange}
                          />
                        </div>
                      </div>
                      <div id="Notlar" className={"dx-field"}>
                        <div className={"dx-field-label"}>Notlar</div>
                        <div className={"dx-field-value"}>
                          <TextArea
                            placeholder={"Oyuncuyla ilgili Notlar"}
                            defaultValue={
                              masrafKalemData.oyuncu
                                ? masrafKalemData.oyuncu.notlar
                                : ""
                            }
                            showClearButton={true}
                            onValueChanged={handleOyuncuNotlarChange}
                          />
                        </div>
                      </div>
                      <div id="Mail" className={"dx-field"}>
                        <div className={"dx-field-label"}>E-Mail</div>
                        <div className={"dx-field-value"}>
                          <TextBox
                            placeholder={"E-Mail"}
                            defaultValue={
                              masrafKalemData.oyuncu
                                ? masrafKalemData.oyuncu.email
                                : ""
                            }
                            showClearButton={true}
                            onValueChanged={handleOyuncuEmailChange}
                          />
                        </div>
                      </div>
                      <div id="Telefon" className={"dx-field"}>
                        <div className={"dx-field-label"}>Telefon</div>
                        <div className={"dx-field-value"}>
                          <TextBox
                            placeholder={"Telefon"}
                            defaultValue={
                              masrafKalemData.oyuncu
                                ? masrafKalemData.oyuncu.telefon
                                : ""
                            }
                            showClearButton={true}
                            onValueChanged={handleOyuncuTelefonChange}
                          />
                        </div>
                      </div>
                      <div id="Ajans" className={"dx-field"}>
                        <div className={"dx-field-label"}>Ajans</div>
                        <div className={"dx-field-value"}>
                          <Lookup
                            defaultValue={oyuncuAjansItem}
                            items={ajansListe}
                            dataSource={ajansListe}
                            showPopupTitle={false}
                            displayExpr={"text"}
                            onValueChanged={handleOyuncuAjansIdChange}
                          />
                        </div>
                      </div>
                      <div id="Menajer" className={"dx-field"}>
                        <div className={"dx-field-label"}>Menajer</div>
                        <div className={"dx-field-value"}>
                          <Lookup
                            defaultValue={menajerItem}
                            items={menajerListe}
                            dataSource={menajerListe}
                            showPopupTitle={false}
                            displayExpr={"text"}
                            onValueChanged={handleOyuncuMenajerIdChange}
                          />
                        </div>
                      </div>
                      <div id="Karakter" className={"dx-field"}>
                        <div className={"dx-field-label"}>Karakter Ad</div>
                        <div className={"dx-field-value"}>
                          <TextBox
                            placeholder={"Karakter"}
                            defaultValue={
                              masrafKalemData.oyuncu
                                ? masrafKalemData.oyuncu.karakter
                                  ? masrafKalemData.oyuncu.karakter.karakterAd
                                  : ""
                                : ""
                            }
                            showClearButton={true}
                            onValueChanged={handleKarakterKarakterAdChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {simdikiMasrafKalemTipId === 7 && (
                  <div id="Ekip" className={"form"}>
                    <div className={"dx-fieldset"}>
                      <div className={"dx-fieldset-header"}>Ekip</div>
                      <div id="EkipAd" className={"dx-field"}>
                        <div className={"dx-field-label"}>Ekip Ad</div>
                        <div className={"dx-field-value"}>
                          <TextBox
                            placeholder={"Ekip Adı Giriniz"}
                            defaultValue={
                              masrafKalemData.ekip
                                ? masrafKalemData.ekip.ekipAd
                                : ""
                            }
                            showClearButton={true}
                            onValueChanged={handleEkipEkipAdChange}
                          />
                        </div>
                      </div>
                      <div id="GorevId" className={"dx-field"}>
                        <div className={"dx-field-label"}>Görev</div>
                        <div className={"dx-field-value"}>
                          <Lookup
                            defaultValue={gorevItem}
                            items={gorevListe}
                            dataSource={gorevListe}
                            showPopupTitle={false}
                            displayExpr={"text"}
                            onValueChanged={handleEkipGorevIdChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {simdikiMasrafKalemTipId === 11 && (
                  <div id="Arac" className={"form"}>
                    <div className={"dx-fieldset"}>
                      <div className={"dx-fieldset-header"}>Araç</div>
                      <div id="AracAd" className={"dx-field"}>
                        <div className={"dx-field-label"}>Araç Ad</div>
                        <div className={"dx-field-value"}>
                          <TextBox
                            placeholder={"Araç Adı Giriniz"}
                            defaultValue={
                              masrafKalemData.arac
                                ? masrafKalemData.arac.aracAd
                                : ""
                            }
                            showClearButton={true}
                            onValueChanged={handleAracAracAdChange}
                          />
                        </div>
                      </div>
                      {/* <div id="AracNotlar" className={"dx-field"}>
                        <div className={"dx-field-label"}>Notlar</div>
                        <div className={"dx-field-value"}>
                          <TextBox
                            placeholder={"Not Giriniz"}
                            defaultValue={
                              masrafKalemData.arac
                                ? masrafKalemData.arac.notlar
                                : ""
                            }
                            showClearButton={true}
                            onValueChanged={handleAracNotlarChange}
                          />
                        </div>
                      </div> */}
                      <div id="aracGrupId" className={"dx-field"}>
                        <div className={"dx-field-label"}>Araç Tip</div>
                        <div className={"dx-field-value"}>
                          <Lookup
                            defaultValue={aracGrupItem}
                            items={aracGrupListe}
                            dataSource={aracGrupListe}
                            showPopupTitle={false}
                            displayExpr={"text"}
                            onValueChanged={handleAracAracGrupChange}
                          />
                        </div>
                      </div>
                      {/* <div id="TedarikciId" className={"dx-field"}>
                        <div className={"dx-field-label"}>Tedarikçi</div>
                        <div className={"dx-field-value"}>
                          <Lookup
                            defaultValue={aracTedarikciItem}
                            items={tedarikciListe}
                            dataSource={tedarikciListe}
                            showPopupTitle={false}
                            displayExpr={"text"}
                            onValueChanged={handleAracTedarikciIdChange}
                          />
                        </div>
                      </div>
                     */}
                    </div>
                  </div>
                )}
              </Fragment>
            )}
        </div>

        <div
          id="tabSozlesme"
          className="tabcontent"
          style={{ display: "none" }}
        >
          <div className={"dx-fieldset-header"}>Sözleşme</div>
          <div id="divMasrafKalemSozlesme" className={"form"}>
            {masrafKalemData &&
              masrafKalemData.masrafKalemTipId != 8 &&
              masrafKalemData.masrafKalemTipId != 11 && (
                <MasrafKalemSozlesme
                  masrafkalemId={masrafKalemDataId}
                  masrafKalemSozlesmeListe={
                    masrafKalemData.masrafKalemSozlesmeListe
                  }
                ></MasrafKalemSozlesme>
              )}
            {masrafKalemData && masrafKalemData.masrafKalemTipId == 11 && (
              <MasrafKalemSozlesmeArac
                masrafkalemId={masrafKalemDataId}
                tedarikciListe={tedarikciListe}
                masrafKalemSozlesmeAracListe={
                  masrafKalemData.masrafKalemSozlesmeAracListe
                }
              ></MasrafKalemSozlesmeArac>
            )}
            {masrafKalemData && masrafKalemData.masrafKalemTipId == 8 && (
              <MasrafKalemSozlesmeYemek
                masrafkalemId={masrafKalemDataId}
                tedarikciListe={tedarikciListe}
                masrafKalemSozlesmeYemekListe={
                  masrafKalemData.masrafKalemSozlesmeYemekListe
                }
              ></MasrafKalemSozlesmeYemek>
            )}
          </div>
        </div>
        <div
          id="tabMasrafMerkeziBaslik"
          className="tabcontent"
          style={{ display: "none" }}
        >
          <div className={"dx-fieldset-header"}>Başlıklar</div>
          <div id="divMasrafMerkeziBaslik" className={"form"}>
            {masrafKalemData && (
              <MasrafMerkeziBaslik
                masrafkalemId={masrafKalemDataId}
                masrafMerkeziBaslikListe={
                  masrafKalemData.masrafMerkeziBaslikListe
                }
              ></MasrafMerkeziBaslik>
            )}
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default MasrafKalemDuzenle;
