import React, { useState, memo, useEffect } from "react";
import axios from "axios";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";

import "../App.css";

import K_1 from "../img/IsProgrami/1_K.png";
import S_1 from "../img/IsProgrami/1_S.png";
import Y_1 from "../img/IsProgrami/1_Y.png";
import K_2 from "../img/IsProgrami/2_K.png";
import S_2 from "../img/IsProgrami/2_S.png";
import Y_2 from "../img/IsProgrami/2_Y.png";
import K_3 from "../img/IsProgrami/3_K.png";
import S_3 from "../img/IsProgrami/3_S.png";
import Y_3 from "../img/IsProgrami/3_Y.png";
import K_4 from "../img/IsProgrami/4_K.png";
import S_4 from "../img/IsProgrami/4_S.png";
import Y_4 from "../img/IsProgrami/4_Y.png";
import K_5 from "../img/IsProgrami/5_K.png";
import S_5 from "../img/IsProgrami/5_S.png";
import Y_5 from "../img/IsProgrami/5_Y.png";
import K_6 from "../img/IsProgrami/6_K.png";
import S_6 from "../img/IsProgrami/6_S.png";
import Y_6 from "../img/IsProgrami/6_Y.png";

const CekimGunBuyuk = memo(({ cekimGunWidth, ekip, bolumId }) => {
  const { url } = useApiURL();
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [ekipNo] = useState(ekip);
  const [dateList, setDateList] = useState([{}]);

  function handleDateClick(event, dateItem) {
    localStorage.setItem("IsProgramiBaslikId", dateItem.isProgramiBaslikId);
    localStorage.setItem("MenuId", 3);
    window.location.reload();
  }

  // const [startDate, setStartDate] = useState([
  //   { tarih: new Date(tarih), sira: 1 }
  // ]);
  function logOut() {
    setToken("");
    localStorage.removeItem("BolumId");
    localStorage.removeItem("MenuId");
    localStorage.removeItem("Token");
    window.location.reload();
  }
  useEffect(() => {
    console.log(dateList);
  }, [dateList]);

  useEffect(() => {
    loadIsProgramiCekimGun(bolumId, ekipNo, null, token);
  }, [bolumId, ekipNo, token]);
  const loadIsProgramiCekimGun = (
    bolumId,
    ekipNo,
    isProgramiBaslikId,
    token
  ) => {
    var requestModel = {
      bolumId: bolumId,
      ekipNo: ekipNo,
      isProgramiBaslikId: isProgramiBaslikId,
      Token: token
    };
    axios
      .post(url + "/api/SahneListe/IsProgramiCekimGun", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);
              setDateList(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  // const d = new Date(
  //   tarih.getFullYear(),
  //   tarih.getMonth(),
  //   tarih.getDate(),
  //   0,
  //   0,
  //   0,
  //   0
  // );
  // const currentdatetime = new Date();
  // const currentdate = new Date(
  //   currentdatetime.getFullYear(),
  //   currentdatetime.getMonth(),
  //   currentdatetime.getDate(),
  //   0,
  //   0,
  //   0,
  //   0
  // );
  //for (var i = 0; i < 6; i++) {
  //  var date = new Date(d);
  //  date.setDate(date.getDate() + i);
  //  let renk = "S";
  //  if (date < currentdate) renk = "K";
  //  if (date > currentdate) renk = "Y";
  //  dateList.push({ tarih: date, sira: i + 1, renk });
  //}

  return (
    <div
      key={"CekimGun" + ekipNo}
      style={{
        width: cekimGunWidth,
        height: 35
      }}
    >
      {dateList &&
        dateList.map(dateItem => {
          console.log(dateItem);
          var imgURL = null;

          if (dateItem.sira === 1) {
            if (dateItem.renk === "S") imgURL = S_1;
            if (dateItem.renk === "K") imgURL = K_1;
            if (dateItem.renk === "Y") imgURL = Y_1;
          }
          if (dateItem.sira === 2) {
            if (dateItem.renk === "S") imgURL = S_2;
            if (dateItem.renk === "K") imgURL = K_2;
            if (dateItem.renk === "Y") imgURL = Y_2;
          }
          if (dateItem.sira === 3) {
            if (dateItem.renk === "S") imgURL = S_3;
            if (dateItem.renk === "K") imgURL = K_3;
            if (dateItem.renk === "Y") imgURL = Y_3;
          }
          if (dateItem.sira === 4) {
            if (dateItem.renk === "S") imgURL = S_4;
            if (dateItem.renk === "K") imgURL = K_4;
            if (dateItem.renk === "Y") imgURL = Y_4;
          }
          if (dateItem.sira === 5) {
            if (dateItem.renk === "S") imgURL = S_5;
            if (dateItem.renk === "K") imgURL = K_5;
            if (dateItem.renk === "Y") imgURL = Y_5;
          }
          if (dateItem.sira === 6) {
            if (dateItem.renk === "S") imgURL = S_6;
            if (dateItem.renk === "K") imgURL = K_6;
            if (dateItem.renk === "Y") imgURL = Y_6;
          }
          console.log(imgURL);
          return (
            <div
              key={"CekimGun" + ekipNo + dateItem.sira}
              id={"CekimGun" + ekipNo + dateItem.sira}
            >
              <div
                key={"CekimGunBuyuk" + ekipNo + dateItem.sira}
                id={"CekimGunBuyuk" + ekipNo + dateItem.sira}
                style={{
                  height: 30,
                  width: 90,
                  marginTop: 3,
                  marginLeft: 0,
                  backgroundImage: `url(${imgURL})`,
                  backgroundSize: "cover",float:"left"
                }}
                onClick={ev => handleDateClick(ev, dateItem)}
              >
                <div
                  key={"CekimGunBosluk" + ekipNo + dateItem.sira}
                  id={"CekimGunBosluk" + ekipNo + dateItem.sira}
                  className="boxLeft"
                  style={{
                    height: 25,
                    width: 25
                  }}
                />

                <div
                  key={"CekimGunTarih" + ekipNo + dateItem.sira}
                  id={"CekimGunTarih" + ekipNo + dateItem.sira}
                  className="boxLeft"
                  style={{
                    width: 64,
                    paddingTop: 1,
                    fontSize: 11
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: 1,
                      marginLeft: 0,
                      fontSize: 11,
                      lineHeight: 1,
                      color: "white"
                    }}
                  >
                    {dateItem.gun}
                    <br />
                    {dateItem.tarih}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
});

export default CekimGunBuyuk;
