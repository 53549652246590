import React, { useState, memo } from "react";
import "../App.css";
import clsx from "clsx";

const TarihSecim = memo(({ tarih, onSelectDate }) => {
  const monthNames = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık"
  ];
  var dayNames = [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi"
  ];

  function handleDateClick(event, dateItem) {
    setStartDate(dateItem);
    onSelectDate(dateItem);
  }

  function handleDateShiftClick(event, changeInt) {
    var dateToAdd = new Date(startDate);
    dateToAdd.setDate(dateToAdd.getDate() + selectionStart + changeInt);
    setMontName(monthNames[dateToAdd.getMonth()]);
    setSelectionStart(selectionStart + changeInt);
  }
  const [startDate, setStartDate] = useState(new Date(tarih));
  const [selectionStart, setSelectionStart] = useState(-4);
  const [monthName, setMontName] = useState(
    monthNames[new Date(startDate).getMonth()]
  );

  var dateList = [];

  for (var i = 0; i < 9; i++) {
    var dateToAdd = new Date(startDate);
    dateToAdd.setDate(dateToAdd.getDate() + i + selectionStart);
    dateList.push(dateToAdd);
  }

  return (
    <div
      style={{
        width: 300,
        height: 300,
        backgroundColor: "black",
        color: "white"
      }}
    >
      <div
        style={{
          width: 300,
          height: 40,
          backgroundColor: "black",
          color: "white"
        }}
      >
        <div
          style={{
            width: 40,
            height: 40,
            backgroundColor: "black",
            color: "white",
            fontSize: 25,
            cursor: "pointer"
          }}
          className="boxLeft"
          onClick={ev => handleDateShiftClick(ev, -9)}
        >
          &#9664;
        </div>
        <div
          className="boxLeft  boxTextAlignCenter boxTextAlignCenterInner"
          style={{
            width: 200,
            height: 40,
            backgroundColor: "black",
            color: "white"
          }}
        >
          <p style={{ textAlign: "center", margin: 0, fontSize: 30 }}>
            {monthName}
          </p>
        </div>

        <div
          style={{
            width: 40,
            height: 40,
            backgroundColor: "black",
            color: "white",
            fontSize: 25,
            textAlign: "right",
            cursor: "pointer"
          }}
          className="boxRight"
          onClick={ev => handleDateShiftClick(ev, 9)}
        >
          &#9654;
        </div>
      </div>
      <div>
        {dateList &&
          dateList.map(dateItem => {
            const tarihClassNames = clsx(
              "boxLeft",
              {
                boxDarkRed: tarih - dateItem === 0
              },
              {
                boxBlack: tarih - dateItem !== 0
              }
            );
            return (
              <div
                id={dateItem}
                key={dateItem}
                style={{
                  height: 80,
                  width: 90,
                  margin: 3,
                  borderWidth: 1
                }}
                className={tarihClassNames}
                onClick={ev => handleDateClick(ev, dateItem)}
              >
                <p style={{ textAlign: "center", margin: 0, fontSize: 40 }}>
                  {dateItem.getUTCDate()}
                </p>
                <p style={{ textAlign: "center", margin: 0, fontSize: 10 }}>
                  {dayNames[dateItem.getDay()]}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
});

export default TarihSecim;
