import React, { useState, useEffect, Fragment } from "react";
import "../App.css";
import axios from "axios";
import SenaryoBG from "../img/SenaryoBG.jpg";
import SenaryoBGPng from "../img/SenaryoBG.png";
import Baslik from "../img/MasrafFisleriImg/Baslik.png";
import Ekle from "../img/MasrafFisleriImg/MasrafFisi.png";
import Sil from "../img/MasrafFisleriImg/Sil.png";
import Sag from "../img/MasrafFisleriImg/sag.png";
import Sol from "../img/MasrafFisleriImg/sol.png";
import Yenile from "../img/MasrafFisleriImg/Yenile.png";
import YeniAvans from "../img/MasrafFisleriImg/YeniAvansBg.png";
import AcikAvans from "../img/MasrafFisleriImg/AcikAvansBg.png";
import KapaliAvans from "../img/MasrafFisleriImg/KapaliAvansBg.png";
import MenuWrapper from "../CircleMenu/FullScreenMenu";


import Liste from "../img/MasrafFisleriImg/Liste.png";
import MenuAc from "../img/MasrafFisleriImg/MenuAc.png";
import Yuvarlak_Kirmizi from "../img/Yuvarlak_Kirmizi.png";
import Yuvarlak_Sari from "../img/Yuvarlak_Sari.png";
import Yuvarlak_Yesil from "../img/Yuvarlak_Yesil.png";

import Popover from "@material-ui/core/Popover";
import SolOk from "../img/Ok_Geri.png";
import SagOk from "../img/Ileri.png";
import { useApiURL } from "../Custom-Hooks/useApiURL.js";
import { BackgroundImage } from "devextreme-react/range-selector";

const MasrafFisleri = () => {
  const { url } = useApiURL();
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [sezonKod] = useState(localStorage.getItem("SezonKod"));
  const [bolumId] = useState(localStorage.getItem("BolumId"));
  const [senaryoListe, setSenaryoListe] = useState([{}]);
  const [bolumKod] = useState(localStorage.getItem("BolumKod"));
  const [selectedDate, setSelectedDate] = React.useState(new Date(Date.now()));
  /**********Popover*************/
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  function handleEkleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleEkleClose(event) {
    window.location.reload();
  }
  /**********Popover*************/

  useEffect(() => {
    loadSenaryoListe(bolumId, token);
  }, [bolumId, token]);

  function logOut() {
    setToken("");
    localStorage.removeItem("BolumId");
    localStorage.removeItem("MenuId");
    localStorage.removeItem("Token");
    window.location.reload();
  }
  const loadSenaryoListe = (bolumId, token) => {
    var requestModel = {
      bolumId: bolumId,
      Token: token
    };
    axios
      .post(url + "/api/SahneListe/SenaryoListe", requestModel)
      .then(function(response) {
        try {
          if (response.status === 200) {
            if (response.data.responseCode === 200) {
              console.log(response.data.responseObject);
              setSenaryoListe(response.data.responseObject);
            } else if (response.data.responseCode === 401) {
              logOut();
            } else {
              alert(response.data.responseMessage);
              console.log(response.data.responseMessage);
            }
          } else {
            alert(response);
            console.log(response);
          }
        } catch (error) {
          alert(error);
          console.log(error);
        }
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };

  /**************************************/
  const [screenwidth, setWidth] = useState(window.innerWidth);
  const [screenheight, setHeight] = useState(window.innerHeight);
  const [boyutOran, setBoyutOran] = useState(1.0);
  const [solBosluk, setSolBosluk] = useState(0);

  const listener = () => {
    var w = window.innerWidth * 1.0;
    var h = window.innerHeight * 1.0;
    var o = w / h;
    var ow = 1704.0;
    var oh = 959.0;
    var oo = ow / oh;
    console.log(o);
    console.log(oo);
    if (oo > o) {
      console.log(h);
      console.log(o / oo);
      h = h * (o / oo);
      o = w / h;
      console.log("oo < o");
      console.log(o);
    } else if (oo < o) {
    }
    setWidth(w);
    setHeight(h);
    setBoyutOran(h / oh);
    //setSolBosluk((screenwidth - 1700 * (h / oh)) / 2);
    setSolBosluk(0);
  };

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  /**************************************/

  return (
    <div
      id="divEkran"
      style={{
        height: window.innerHeight - 2,
        width: window.innerWidth,
        overflow: "none",
        backgroundColor: "whitesmoke"
      }}
    >
      <img
        src={Baslik}
        alt="Baslik"
        style={{
          position: "absolute",
          left: solBosluk + 0 * boyutOran,
          top: 10 * boyutOran,
          height: 170 * boyutOran
        }}
      ></img>
      <img
        src={Ekle}
        alt="Ekle"
        style={{
          position: "absolute",
          left: solBosluk + 800 * boyutOran,
          top: 10 * boyutOran,
          height: 90 * boyutOran
        }}
        onClick={handleEkleClick}
      ></img>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleEkleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
      
      </Popover>
      <img
        src={Yenile}
        alt="Yenile"
        style={{
          position: "absolute",
          left: solBosluk + 1010 * boyutOran,
          top: 24 * boyutOran,
          height: 90 * boyutOran
        }}
      ></img>
      <img
        src={Sil}
        alt="Sil"
        style={{
          position: "absolute",
          left: solBosluk + 1130 * boyutOran,
          top: 24 * boyutOran,
          height: 90 * boyutOran
        }}
      ></img>
  
  <div  style={{
          position: "absolute",
          left: -22 ,
          top: 60,
        }}>
    <MenuWrapper>

    </MenuWrapper>
      </div>

      <img
        src={Liste}
        alt="Liste"
        style={{
          position: "absolute",
          left: solBosluk + 200 * boyutOran,
          top: 250 * boyutOran,
          height: 650 * boyutOran
        }}
      ></img>

<img
        src={Sol}
        alt="Sol"
        style={{
          position: "absolute",
          left: solBosluk + 245 * boyutOran,
          top: 480 * boyutOran,
          height: 40 * boyutOran
        }}
      ></img>
      <img
        src={Sag}
        alt="Sag"
        style={{
          position: "absolute",
          left: solBosluk + 545 * boyutOran,
          top: 480 * boyutOran,
          height: 40 * boyutOran
        }}
      ></img>
      <div
        id="divCizgi"
        style={{
          position: "absolute",
          left: solBosluk + 0 * boyutOran,
          top: 130 * boyutOran,
          backgroundColor: "#707070",
          width: 1700 * boyutOran,
          height: 5
        }}
      >
        &nbsp;
      </div>
      <div
        id="divTarihArkasi"
        style={{
          position: "absolute",
          left: solBosluk + 1375 * boyutOran,
          top: 25 * boyutOran,
          color: "white",
          backgroundColor: "black",
          fontSize: 70 * boyutOran,
          width: 320 * boyutOran
        }}
      >
        &nbsp;
      </div>

      <img
        id="imgToplamRevizyonSari"
        src={Yuvarlak_Sari}
        style={{
          position: "absolute",
          left: solBosluk + 25 * boyutOran,
          top: 835 * boyutOran,
          color: "white",
          fontSize: 80 * boyutOran,
          width: 90 * boyutOran,
          textAlign: "center"
        }}
      />
      <img
        id="imgToplamRevizyonKirmizi"
        src={Yuvarlak_Kirmizi}
        style={{
          position: "absolute",
          left: solBosluk + 145 * boyutOran,
          top: 835 * boyutOran,
          color: "white",
          fontSize: 80 * boyutOran,
          width: 90 * boyutOran,
          textAlign: "center"
        }}
      /><img
      id="imgToplamRevizyonYesil"
      src={Yuvarlak_Yesil}
      style={{
        position: "absolute",
        left: solBosluk + 265 * boyutOran,
        top: 835 * boyutOran,
        color: "white",
        fontSize: 80 * boyutOran,
        width: 90 * boyutOran,
        textAlign: "center"
      }}
    />
      <img
        id="imgToplamRevizyonSol"
        src={SolOk}
        style={{
          position: "absolute",
          left: solBosluk + 23 * boyutOran,
          top: 750 * boyutOran,
          fontSize: 80 * boyutOran,
          width: 40 * boyutOran,
          textAlign: "center"
        }}
      ></img>
      <div
        id="divToplamRevizyon"
        style={{
          position: "absolute",
          left: solBosluk + 40 * boyutOran,
          top: 760 * boyutOran,
          color: "#707070",
          fontSize: 40 * boyutOran,
          width: 300 * boyutOran,
          textAlign: "center"
        }}
      >
      Yeni/Açık/Kapalı
      </div>
      <img
        id="imgToplamRevizyonSag"
        src={SagOk}
        style={{
          position: "absolute",
          left: solBosluk + 323 * boyutOran,
          top: 750 * boyutOran,

          fontSize: 80 * boyutOran,
          width: 40 * boyutOran,
          textAlign: "center"
        }}
      ></img>
      <div
        id="divToplamRevizyon"
        style={{
          position: "absolute",
          left: solBosluk + 25 * boyutOran,
          top: 830 * boyutOran,
          color: "white",
          fontSize: 80 * boyutOran,
          width: 90 * boyutOran,
          textAlign: "center"
        }}
      >
       3
      </div>
      <div
        id="divToplamRevizyon"
        style={{
          position: "absolute",
          left: solBosluk + 140 * boyutOran,
          top: 830 * boyutOran,
          color: "white",
          fontSize: 80 * boyutOran,
          width: 90 * boyutOran,
          textAlign: "center"
        }}
      >
        6
      </div>
      <div
        id="divToplamRevizyon"
        style={{
          position: "absolute",
          left: solBosluk + 265 * boyutOran,
          top: 830 * boyutOran,
          color: "white",
          fontSize: 80 * boyutOran,
          width: 90 * boyutOran,
          textAlign: "center"
        }}
      >
       7
      </div>
   
      <div
        style={{
          position: "absolute",
          left: solBosluk + 1380 * boyutOran,
          top: 25 * boyutOran,
          color: "white",

          fontSize: 70 * boyutOran
        }}
      >
        {senaryoListe[0].GunNo}
      </div>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 1450 * boyutOran,
          top: 28 * boyutOran,
          color: "white",
          fontSize: 40 * boyutOran,
          width: 200 * boyutOran,
          textAlign: "center"
        }}
      >
        {senaryoListe[0].Gun}
      </div>
      <div
        style={{
          position: "absolute",
          left: solBosluk + 1450 * boyutOran,
          top: 72 * boyutOran,
          color: "white",
          fontSize: 20 * boyutOran,
          width: 200 * boyutOran,
          textAlign: "center"
        }}
      >
        {senaryoListe[0].Ay} - {senaryoListe[0].Yil}
      </div>
      <div
        id="divRevizyonlar"
        style={{
          position: "absolute",
          left: solBosluk + 655 * boyutOran,
          top: 145 * boyutOran,
          color: "#f7931e",
          fontWeight:"bolder",
          fontSize: 30 * boyutOran
        }}
      >

        Yeni Avans Talepleri:

<div style={{
    backgroundImage:`url(${YeniAvans})`, 
    backgroundSize:1040*boyutOran,
    height: 60 * boyutOran,
    width:1040 * boyutOran,
    paddingTop:6 * boyutOran,
    marginTop:5*boyutOran
}}>
 <div
            style={{
            
              color: "white",
              fontSize: 39 * boyutOran,
              float:"left",
              paddingLeft:80 * boyutOran
            }}
          >
            1.Sezon >>> 1.Bölüm\
          </div>
          <div
            style={{
   
              left: solBosluk + 1058 * boyutOran,
              top: 190 * boyutOran,
              color: "black",
              float:"left"
            }}
          >
            <span style={{ fontSize: 39 * boyutOran }}>
              Form No: 0009 
            </span>
            
          </div>
          <div
            style={{
           
              left: solBosluk + 1310 * boyutOran,
              top: 190 * boyutOran,
              color: "white",
              fontSize: 39 * boyutOran,
              textAlign: "center",
              float:"left"
            }}
          >
           \ Mutlu ALEMDAR
          </div>

</div>
<div style={{
    backgroundImage:`url(${YeniAvans})`, 
    backgroundSize:1040*boyutOran,
    height: 60 * boyutOran,
    width:1040 * boyutOran,
    paddingTop:6 * boyutOran,
    marginTop:5*boyutOran
}}>
 <div
            style={{
            
              color: "white",
              fontSize: 39 * boyutOran,
              float:"left",
              paddingLeft:80 * boyutOran
            }}
          >
            1.Sezon >>> 1.Bölüm\
          </div>
          <div
            style={{
   
              left: solBosluk + 1058 * boyutOran,
              top: 190 * boyutOran,
              color: "black",
              float:"left"
            }}
          >
            <span style={{ fontSize: 39 * boyutOran }}>
              Form No: 0009 
            </span>
            
          </div>
          <div
            style={{
           
              left: solBosluk + 1310 * boyutOran,
              top: 190 * boyutOran,
              color: "white",
              fontSize: 39 * boyutOran,
              textAlign: "center",
              float:"left"
            }}
          >
           \ Mutlu ALEMDAR
          </div>

</div>
<div style={{height:25*boyutOran}}></div>
<span style={{color:"#c1272d"}}>
  Açık Avanslar:</span>
  <div style={{
    backgroundImage:`url(${AcikAvans})`, 
    backgroundSize:1040*boyutOran,
    height: 60 * boyutOran,
    width:1040 * boyutOran,
    paddingTop:6 * boyutOran,
    marginTop:5* boyutOran
}}>
 <div
            style={{
            
              color: "white",
              fontSize: 39 * boyutOran,
              float:"left",
              paddingLeft:80 * boyutOran
            }}
          >
            1.Sezon >>> 1.Bölüm\
          </div>
          <div
            style={{
   
              left: solBosluk + 1058 * boyutOran,
              top: 190 * boyutOran,
              color: "black",
              float:"left"
            }}
          >
            <span style={{ fontSize: 39 * boyutOran }}>
              Form No: 0009 
            </span>
            
          </div>
          <div
            style={{
           
              left: solBosluk + 1310 * boyutOran,
              top: 190 * boyutOran,
              color: "white",
              fontSize: 39 * boyutOran,
              textAlign: "center",
              float:"left"
            }}
          >
           \ Mutlu ALEMDAR
          </div>

</div>
<div style={{height:25*boyutOran}}></div>
<span style={{color:"#006837"}}>
  Kapanmış Avanslar:</span>
  <div style={{
    backgroundImage:`url(${KapaliAvans})`, 
    backgroundSize:1040*boyutOran,
    height: 60 * boyutOran,
    width:1040 * boyutOran,
    paddingTop:6 * boyutOran,
    marginTop:5*boyutOran
}}>
 <div
            style={{
            
              color: "white",
              fontSize: 39 * boyutOran,
              float:"left",
              paddingLeft:80 * boyutOran
            }}
          >
            1.Sezon >>> 1.Bölüm\
          </div>
          <div
            style={{
   
              left: solBosluk + 1058 * boyutOran,
              top: 190 * boyutOran,
              color: "black",
              float:"left"
            }}
          >
            <span style={{ fontSize: 39 * boyutOran }}>
              Form No: 0009 
            </span>
            
          </div>
          <div
            style={{
           
              left: solBosluk + 1310 * boyutOran,
              top: 190 * boyutOran,
              color: "white",
              fontSize: 39 * boyutOran,
              textAlign: "center",
              float:"left"
            }}
          >
           \ Mutlu ALEMDAR
          </div>

</div>
      </div>
    
     
    
    </div>
  );
};

export default MasrafFisleri;
