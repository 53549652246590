import React, { Fragment, memo } from "react";
import clsx from "clsx";

const BolumListe = memo(({ bolumData, selectedBolumId, onBolumClick }) => {
  return (
    <Fragment>
      {bolumData &&
        bolumData.map(bolum => {
          const bolumClassNames = clsx(
            "boxLeft",
            "boxCircle",
            "boxCircle100",
            {
              boxCircleLime: bolum.bolumId === selectedBolumId
            },
            {
              boxCircleSilver: !(bolum.bolumId === selectedBolumId)
            }
          );
          return (
            <div
              className={bolumClassNames}
              key={bolum.bolumId}
              style={{ margin: 10 }}
              onClick={() =>
                onBolumClick(bolum.bolumId, bolum.bolumKod, bolum.bolumAd)
              }
            >
              <div className="boxCircleInner50">
                {bolum.bolumKod}
                <br />
                {bolum.bolumAd}
              </div>
            </div>
          );
        })}
    </Fragment>
  );
});

export default BolumListe;
